import jQuery from 'jquery';
// import Slick from 'vue-slick';

jQuery(function($) {

	$(document).ready(function(){

        // main menu toggle
        // $('.burger-button').click(function(e) {
        //     e.preventDefault();
        //     // if ($('body').hasClass('side-menu-active')) {
        //     //     $('html').css('overflow-y', 'auto');
        //     // } else {
        //     //     $('html').css('overflow-y', 'hidden');
        //     // }
        //     $('.animated-icon').toggleClass('open');
        //     // $('body').toggleClass('side-menu-active');
        //     // $('.header-menu').toggleClass('active');
        //     // $('header').toggleClass('display-menu');
        //     // $('html').css('overflow-y', 'hidden');
        // });
        //
        // $('.menu-overlay').click(function() {
        //     $('.animated-icon').removeClass('open');
        //     $('body').removeClass('side-menu-active');
        //     $('.header-menu').removeClass('active');
        //     $('header').removeClass('display-menu');
        //     $('html').css('overflow-y', 'auto');
        // });

        // body on scrolled
        $(window).on('load', function() {
            if ($(document).scrollTop() > 100) {
                $('body').addClass('scrolled');
            }
            else {
                $('body').removeClass('scrolled');
            }
        });
        $(window).scroll(function() {
            if ($(document).scrollTop() > 100) {
                $('body').addClass('scrolled');
            }
            else {
                $('body').removeClass('scrolled');
            }
        });

        // banner scroll to next section
        $('.banner-scroll').on('click', function(e) {
            $('html, body').animate({
                scrollTop: $(".banner + div").offset().top
            }, 200);
        });

        // scroll to end
        // $(document).scroll(function() {
        //     var value=$(window).scrollTop() + $(window).height()
        //     if ( value >= $(document).height() )
        //         setTimeout(function(){
        //             $('footer').addClass('in')
        //         }, 20)
        //     else{
        //         $('footer').removeClass('in')
        //     }
        // }).trigger('scroll');

        // progress bar
        $(document).on("scroll", function(){
            var pixels = $(document).scrollTop();
            var pageHeight = $(document).height() - $(window).height();
            var progress = 100 * pixels / pageHeight;
            if ($('.page.page-home').length) {
                $('.progress div:nth-child(1) span').css("width", progress + "%");
                $('.progress div:nth-child(2) span').css("width", "0");
                $('.progress div:nth-child(3) span').css("width", "0");
                $('.progress div:nth-child(4) span').css("width", "0");
                $('.progress div:nth-child(5) span').css("width", "0");
            } else if ($('.page.page-ideation').length) {
                $('.progress div:nth-child(1) span').css("width", "100%");
                $('.progress div:nth-child(2) span').css("width", progress + "%");
                $('.progress div:nth-child(3) span').css("width", "0");
                $('.progress div:nth-child(4) span').css("width", "0");
                $('.progress div:nth-child(5) span').css("width", "0");
            } else if ($('.page.page-commercialisation').length) {
                $('.progress div:nth-child(1) span').css("width", "100%");
                $('.progress div:nth-child(2) span').css("width", "100%");
                $('.progress div:nth-child(3) span').css("width", progress + "%");
                $('.progress div:nth-child(4) span').css("width", "0");
                $('.progress div:nth-child(5) span').css("width", "0");
            } else if($('.page.page-cnc').length) {
                $('.progress div:nth-child(1) span').css("width", "100%");
                $('.progress div:nth-child(2) span').css("width", "100%");
                $('.progress div:nth-child(3) span').css("width", "100%");
                $('.progress div:nth-child(4) span').css("width", progress + "%");
                $('.progress div:nth-child(5) span').css("width", "0");
            } else if($('.page.page-ttt').length) {
                $('.progress div:nth-child(1) span').css("width", "100%");
                $('.progress div:nth-child(2) span').css("width", "100%");
                $('.progress div:nth-child(3) span').css("width", "100%");
                $('.progress div:nth-child(4) span').css("width", "100%");
                $('.progress div:nth-child(5) span').css("width", progress + "%");
            }

            // console.log(pageHeight,progress)
            
        });

        $('.content-row').each(function(){
            // var toprange = $(this).offset().top;
            // var topfadeStart = toprange - 100;
            // var topfadeEnd = toprange + 100;
            // var scrolltop = $(window).scrollTop();
            // if (scrolltop > toprange) {
            //     $(this).addClass('pinned');
            // } else {
            //     $(this).removeClass('pinned');
            // }
            var idforclass = $(this).attr('id')
            var heightofid = $(this).outerHeight();
            var thisrow = $(this);

            $('.content-bg-list .' + idforclass + ' > div').css('height', heightofid + 'px');
            // console.log(idforclass, heightofid)
            
            $(window).resize(function(){
                $('.content-bg-list .content-bg .' + idforclass + ' > div').css('height', heightofid + 'px');
            //     console.log(idforclass, heightofid)
            });

            var fadeTarget = $('.content-bg-list .content-bg .' + idforclass);

        });

        $(window).scroll(function() {
            // Setting: Start fading halfway up the page
            var startPos = 0.7;

            // Cache window object
            var $w = $(window);

            // Basically, we go through each element and check its relative position within the viewport
            $('.content-row').each(function() {
                // Get current relative position in viewport, based on the top edge
                var pos = $(this).offset().top - $w.scrollTop();
                var idforclass = $(this).attr('id');
                var fadeTarget = $('.content-bg-list .content-bg.' + idforclass);
                // console.log('.content-bg-list .content-bg.' +idforclass)

                // Get viewport height
                var vh = $w.height();

                if (pos < vh * startPos) {
                  // If element has past the starting threshold, we fade it
                  $(fadeTarget).css('opacity', pos / (vh * startPos) * 1);
                  // $(fadeTarget).css('opacity', 1);
                } else {
                  // $(fadeTarget).css('opacity', pos / (vh * startPos) * 1);
                  $(fadeTarget).css('opacity', 1);
                }
            });
        });
        
        if ($('.gallery-images, .gallery-relo-images')) {
            $(window).resize(function(){
                if ($(window).width() < 767) {
                    $('.gallery-images').slick('refresh');
                    $('.gallery-relo-images').slick('refresh');
                }
            });
        };

	});

});

document.addEventListener('DOMContentLoaded', (event) => {
  jQuery(document).ready(function($){
    var dragging = false,
        scrolling = false,
        resizing = false;
    //cache jQuery objects
    var imageComparisonContainers = $('.cd-image-container');
    //check if the .cd-image-container is in the viewport
    //if yes, animate it
    // checkPosition(imageComparisonContainers);
    // $(window).on('scroll', function(){
    //     if( !scrolling) {
    //         scrolling =  true;
    //         ( !window.requestAnimationFrame )
    //             ? setTimeout(function(){checkPosition(imageComparisonContainers);}, 100)
    //             : requestAnimationFrame(function(){checkPosition(imageComparisonContainers);});
    //     }
    // });
    
    //make the .cd-handle element draggable and modify .cd-resize-img width according to its position
    // imageComparisonContainers.each(function(){
    //     var actual = $(this);
    //     drags(actual.find('.cd-handle'), actual.find('.cd-resize-img'), actual, actual.find('.cd-image-label[data-type="original"]'), actual.find('.cd-image-label[data-type="modified"]'));
    // });

    //upadate images label visibility
    $(window).on('resize', function(){
        if( !resizing) {
            resizing =  true;
            ( !window.requestAnimationFrame )
                ? setTimeout(function(){checkLabel(imageComparisonContainers);}, 100)
                : requestAnimationFrame(function(){checkLabel(imageComparisonContainers);});
        }
    });

    function checkPosition(container) {
        container.each(function(){
            var actualContainer = $(this);
            if( $(window).scrollTop() + $(window).height()*0.5 > actualContainer.offset().top) {
                actualContainer.addClass('is-visible');
            }
        });

        scrolling = false;
    }

    function checkLabel(container) {
        container.each(function(){
            var actual = $(this);
            updateLabel(actual.find('.cd-image-label[data-type="modified"]'), actual.find('.cd-resize-img'), 'left');
            updateLabel(actual.find('.cd-image-label[data-type="original"]'), actual.find('.cd-resize-img'), 'right');
        });

        resizing = false;
    }

    //draggable funtionality - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
    function drags(dragElement, resizeElement, container, labelContainer, labelResizeElement) {
        dragElement.on("mousedown vmousedown", function(e) {
            dragElement.addClass('dlaggable');
            resizeElement.addClass('lesizable');

            var dragWidth = dragElement.outerWidth(),
                xPosition = dragElement.offset().left + dragWidth - e.pageX,
                containerOffset = container.offset().left,
                containerWidth = container.outerWidth(),
                minLeft = containerOffset + 10,
                maxLeft = containerOffset + containerWidth - dragWidth - 10;

            dragElement.parents().on("mousemove vmousemove", function(e) {
                if( !dragging) {
                    dragging =  true;
                    ( !window.requestAnimationFrame )
                        ? setTimeout(function(){animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);}, 100)
                        : requestAnimationFrame(function(){animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);});
                }
            }).on("mouseup vmouseup", function(e){
                dragElement.removeClass('dlaggable');
                resizeElement.removeClass('lesizable');
            });
            e.preventDefault();
        }).on("mouseup vmouseup", function(e) {
            dragElement.removeClass('dlaggable');
            resizeElement.removeClass('lesizable');
        });
    }

    function animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement) {
        var leftValue = e.pageX + xPosition - dragWidth;   
        //constrain the draggable element to move inside his container
        if(leftValue < minLeft ) {
            leftValue = minLeft;
        } else if ( leftValue > maxLeft) {
            leftValue = maxLeft;
        }

        var widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';
        
        $('.dlaggable').css('left', widthValue).on("mouseup vmouseup", function() {
            $(this).removeClass('dlaggable');
            resizeElement.removeClass('lesizable');
        });

        $('.lesizable').css('width', widthValue); 

        updateLabel(labelResizeElement, resizeElement, 'left');
        updateLabel(labelContainer, resizeElement, 'right');
        dragging =  false;
    }

    function updateLabel(label, resizeElement, position) {
        if(position == 'left') {
            ( label.offset().left + label.outerWidth() < resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden') ;
        } else {
            ( label.offset().left > resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden') ;
        }
    }
    
  });
});