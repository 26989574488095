<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">July 2012</h2>
      <div class="text text-center">
        <div class="body">
          <p>Official launch by YAB Prime Minister of Malaysia</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <slick v-if="data_image" ref="slick" :options="slickOptionsP12010"
                class="milestones-year-slide-items">
              <div class="milestones-year-slide-item position-relative"
                  v-for="(item, index) in data_image" :key="index">

                <div class="milestones-year-slide-img slide-img-only">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index"
                      class="img-fluid">
                </div>
                <!-- <div class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                  <div class="text">
                    <h4 :inner-html.prop="item.title"></h4>
                  </div>
                </div> -->

              </div>
            </slick>

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center">SPEECH BY YAB DATO’ SRI MOHD NAJIB BIN TUN HAJI
                ABDUL RAZAK AT THE OFFICIAL LAUNCH OF THE TUN RAZAK EXCHANGE ON 30TH JULY 2012</h3>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p><strong>Bismillahirrahmanirrahim.</strong><br>
                    Assalamualaikum Warahmatullahi Wabarakatuh, Salam Sejahtera dan Salam Satu
                    Malaysia.</p>
                  <p><strong>Yang Berbahagia Tan Sri Dato’ Seri Lodin Wok Kamaruddin;</strong><br>
                    Chairman, 1MDB Board of Directors,</p>
                  <p><strong>Yang Berhormat Tan Sri Nor Mohamed Yakcop;</strong><br>
                    Minister in the Prime Minister’s Department,</p>
                  <p><strong>Yang Berhormat Dato’ Raja Nong Chik Bin Dato’ Raja Zainal
                    Abidin;</strong><br>
                    Minister of Federal Territories and Urban Well-being,</p>
                  <p>Your Excellencies,<br>
                    Distinguished guests,<br>
                    Ladies and Gentlemen,</p>
                  <p>I want to thank you for being here this afternoon in this holy month of
                    Ramadhan. We have here with us today our international partners coming from
                    afar. I am delighted that as Malaysia looks to build deeper, stronger and more
                    profound links to the world economy, I stand among so many who help to shape
                    it.</p>
                  <p>As the Chairman of the Board of Advisors of 1Malaysia Development Berhad
                    (1MDB), I am honoured to welcome you to the future of economic growth. This in
                    the essence is what we are ushering in when I officially launch this strategic
                    development by 1MDB.</p>
                  <p>For the uninitiated, 1MDB is a strategic development company driving new ideas
                    and new sources of growth. It has only one shareholder, the Government of
                    Malaysia. We want the company to fulfill government aspiration with the dynamism
                    of the private sector. The 1MDB team has to be bold in breaking new grounds and
                    setting higher standards to push Malaysia forward in our Economic Transformation
                    Programme. I am delighted to note that 1MDB has delivered as it works towards
                    revitalising Kuala Lumpur as a vibrant city of choice for businesses to locate
                    and a top 20 global liveable city.</p>
                  <p>Ladies and Gentlemen,</p>
                  <p>It is no overstatement to say that as markets still reel from the Great
                    Recession of the early 21st Century, our response has become the defining issue
                    of our time. The choices made by Eurozone leaders, the current or next US
                    President or indeed China’s Politburo Standing Committee all hold tremendous
                    sway over the world’s economic outlook. And rather than news of rate-rigging,
                    double-dips or false dawns, what we are all hoping for are new horizons. But
                    look close enough and amid such a dark sky, a few bright spots are visible –
                    they are sometimes overlooked, but I know that many of you with keen eyes have
                    seen that Malaysia is a country that is continuing to shine, even as others
                    around us fade.</p>
                  <p>This has not come about by accident. Instead, it is a result of steady economic
                    stewardship, focused on long-term stability and success. And as we stand here in
                    this magnificent new development, we can rest assured that Malaysia is
                    continuing to invest in its future. Here I must also give my special thanks to
                    1Malaysia Development Berhad for making this project come to be. They have once
                    again demonstrated that they are leading the way – driving new ideas,
                    identifying new sources of growth and pushing Malaysia forward in our economic
                    transformation.</p>
                  <p>Ladies and Gentlemen,</p>
                  <p>The idea of an Asian century – first predicted back in the 1980s by Deng
                    Xiaoping and Rajiv Gandhi – is now a reality. Asia is the world’s fastest
                    growing region and is expected to be for the next decade. Our share of global
                    Gross Domestic Product, which stood at 35 per cent in 2010, could be as high as
                    51 per cent by 2050. We have the world’s fastest-growing middle-class – and by
                    2050 it is expected that there will be more than three billion middle-class
                    Asians, consuming goods and services produced in the East and West alike. And
                    every year, Asia’s urban population is projected to grow by something in the
                    region of 44 million.</p>
                  <p>This journey towards the Asian century started with East Asian economies like
                    Japan and South Korea, but now all Asian economies, from the East to West and
                    North to South of the continent, are on the up. Oil-rich Arab countries are
                    diversifying successfully into thriving modern economies, ASEAN is becoming
                    increasingly dynamic and competitive on the global stage – and when the ASEAN
                    Economic Community takes off in 2015, a regional market of almost 600 million
                    people would have been created. Needless to say, none of us are standing
                    still.</p>
                  <p>I am confident that as we move forward, Malaysia will play an increasingly
                    active role in Asia. Kuala Lumpur has long been known as a transformative force.
                    From its early days as a tin mining village feeding a world hungry for raw
                    materials, to whetting the global appetite for natural rubber, palm oil and
                    petroleum, it now stands at the heartbeat of one of the Asian countries driving
                    the regional and world economy. This is not surprising, given that Malaysia has
                    consistently posted solid growth rates. Even in 2011, despite the global
                    uncertainty, Malaysia recorded a higher than expected growth rate of 5.1 per
                    cent.</p>
                  <p>Against a challenging global backdrop, no country can expect to easily buck
                    global trends. And despite our successes over the last couple of months,
                    including Felda Global Venture Holdings and IHH Healthcare recording the world’s
                    second and third largest IPOs, and Bursa Malaysia reaching record highs, we know
                    we are not immune to external forces. We do, however, have strengths which act
                    to our advantage.</p>
                  <p>We have demonstrated a nimbleness in reacting to shocks to the system before –
                    in particular, our resilience and our innovation steered us through the economic
                    crisis that swept through Asia at the end of the 20th century. And in spite of
                    our size, we are also blessed with a highly diverse economy, with abundant
                    natural resources, strong manufacturing and services complemented by a highly
                    talented workforce.</p>
                  <p>Ladies and Gentlemen,</p>
                  <p>With our rich historical, cultural and economic links in Asia and a geographic
                    location that places between China, South East Asia, India and the Middle East,
                    Malaysia has the potential to become a major Asian intersection. It is our
                    intention to realise this potential. To position ourselves as a nucleus of
                    global talent; a centre for the development of new and exciting technologies; a
                    focal point for the exchange of ideas and information. And at the heart of this
                    plan is Kuala Lumpur.</p>
                  <p>That is why I take great pride in opening the Tun Razak Exchange here today.
                    This may be a predominantly national initiative to spur new areas of sustainable
                    growth, but it is international in ambition. For alongside embracing global
                    best-practice and innovation, I want it to promote regional as well as global
                    prosperity.</p>
                  <p>This development has combined the best talents from home and abroad. The
                    centerpiece building will be designed by the winner of the international
                    Signature Tower Design Competition organised by the Malaysian Institute of
                    Architects. And alongside this, 1MDB is drawing up a Digital Master Plan to
                    ensure state-of-the-art connectivity in the new development.</p>
                  <p>And what began as an idea for Kuala Lumpur International Financial District has
                    evolved into something larger and more inclusive. The Tun Razak Exchange is a
                    70-acre haven for new investment opportunities, estimated to generate a gross
                    development value of RM26 billion. It will encompass world-leading international
                    design and progressive planning tenets; its buildings and infrastructure will
                    conform to the highest levels of sustainability; pedestrians will be able walk
                    and play in green public areas; and it will have seamless links to public
                    transportation, including the MRT.</p>
                  <p>We also expect more than 250 of the world’s leading companies to locate here,
                    creating 500,000 jobs directly and indirectly by the time it is fully completed.
                    40,000 will be knowledge workers specified to financial services, reflecting our
                    ambition for the Tun Razak Exchange to be home to a strong, vibrant and diverse
                    international business community.</p>
                  <p>Additionally, to ensure that the exchange receives the support of the entire
                    Government machinery every step of the way – from planning and development right
                    until the exchange is fully up and running – we have formed a special task force
                    led by the Economic Planning Unit. I have appointed YB Tan Sri Dato’ Nor Mohamed
                    Yakcop as chair and its members include senior officers from the Ministry of
                    Finance, Bank Negara Malaysia and the Securities Commission as well as other
                    agencies.</p>
                  <p>The Government will go out of its way to ensure that the exchange is a success
                    – and as a first step, I can announce to you today that we will begin a
                    comprehensive review of business regulations. Our logic behind this review is
                    simple: anything that contributes to future progress stays, anything that is
                    outdated goes.</p>
                  <p>I can also announce that further incentives are in the pipeline, building on
                    those I announced last year. These include: an income tax exemption of 100 per
                    cent for 10 years; stamp duty exemption on loan and service agreements;
                    Industrial Building Allowance and Accelerated Capital Allowance; and an income
                    tax exemption of 70 per cent for five years for eligible property developers in
                    the Tun Razak Exchange.</p>
                  <p>Ladies and Gentlemen,</p>
                  <p>We remain steadfast in our commitment to creating a large, vibrant and
                    successful financial district in Kuala Lumpur. With the support of Bank Negara
                    Malaysia, the Tun Razak Exchange will play a major part in turning this great
                    city into an international financial centre.</p>
                  <p>In particular, we hope to see a further development of Islamic finance – a
                    sector of unlimited potential. Worth just $5 billion in 1985, the Islamic
                    finance sector is worth more than $1 trillion today. Malaysia’s Islamic finance
                    sector is already worth more than $400 billion and that is set to triple in
                    value over the coming decade. I am committed to doing everything I can to make
                    it easier for investors to participate in Malaysia’s Islamic finance markets –
                    and further strengthen our position as a global hub for Islamic Finance.</p>
                  <p>As I have set out, there are many other reasons to invest in the Tun Razak
                    Exchange – and I am pleased to announce we have locked in our partner for the
                    entire of Phase 1. It is significant to note that this international partner is
                    the first mover in establishing a strong ecosystem to support the infrastructure
                    for the exchange. There will be a strategic investor that will be bringing in
                    more than RM3.5 billion in FDI. There will be a special ceremony in September
                    this year to mark this during which the identity and details will be
                    announced.</p>
                  <p>Ladies and Gentlemen,</p>
                  <p>A project of this scale requires the efforts and support of far too many people
                    to name individually. I would like to take this opportunity to publicly express
                    my appreciation and thanks to all related ministries and agencies for a job well
                    done. This is precisely what we advocate – a unified effort and policy alignment
                    towards a relentless pursuit of global competitiveness and economic growth. This
                    requires the full commitment of businesses and the public sector. I am pleased
                    to see their strong commitment in the pre-development stage of the Tun Razak
                    Exchange.</p>
                  <p>I can assure you all that as Prime Minister and as the Chairman of Board of
                    Advisors of 1MDB, my own commitment to this cause and to our development is
                    unwavering.</p>
                  <p>With the recital of Bismillahirrahmanirrahim, I now officially launch the Tun
                    Razak Exchange.</p>
                  <p>Thank you.</p>
                  <p>Wabillahitaufik Walhidayah Wassalamualaikum Warahmatullahi Wabarakatuh.</p>
                </div>
                <!--                <a class="pabutton_1 inverse" href="https://trx.my/press-release/speech-yab-dato-sri-mohd-najib-bin-tun-haji-abdul-razak-official-launch-tun-razak" target="_blank">Discover More <img :src="btnNewTab" alt="btn-newtab"></a>-->
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png';
import slickRight from '@/assets/arrow-next-transparent.png';

import styleBgFooter from '@/assets/bg-footer.svg';
import btnNewTab from '@/assets/ico-newtab.png';

import MileClose from '@/assets/ico-milestones-modal-close.png';
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg';

import MileP11201 from '@/assets/ideation/milestone/phase1-2012/phase1-2012-01.jpg';
import MileP11202 from '@/assets/ideation/milestone/phase1-2012/phase1-2012-02.jpg';
import MileP11203 from '@/assets/ideation/milestone/phase1-2012/phase1-2012-03.jpg';
import MileP11204 from '@/assets/ideation/milestone/phase1-2012/phase1-2012-04.jpg';

export default {
  name: 'IdeationTimelinePhase12012',
  components: {
    Slick,
    //   phase1inner1
  },
  // props: {
  // msg: String
  // }
  data: function() {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      data_image: [
        // { items: [
        {
          img: MileP11201,
          title: 'Adrian Smith + Gordon Gill Architecture',
        },
        {
          img: MileP11202,
          title: 'Atkins',
        },
        {
          img: MileP11203,
          title: 'BDP',
        },
        {
          img: MileP11204,
          title: 'Broadway Malyan',
        },
        // {
        //   icon: styleMileIco3,
        //   title: 'Activation',
        //   url: styleMile3,
        //   link: []
        // }
        // }
      ],
      MileClose: MileClose,
      slickOptionsP12010: {
        slidesToShow: 3,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      };
    },
  },
  mounted: function() {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
};
</script>