<template>
  <div class="hello">

    <!-- <div :style="styleThis"> -->

    <HelloWorld class="text-center" msg="Welcome to Your Vue.js App"/>

  </div>
</template>

<script>
import axios from 'axios'
import HelloWorld from '@/components/HelloWorld.vue'
// @ is an alias to /src

// import styleImage from '@/assets/osboot_sample_texture_500x500__01.jpg'

export default {
  name: 'Hello',
  components: {
    HelloWorld,
  },
  metaInfo() {
    return {
      title: 'Hello',
    }
  },
  computed: {
    // custom inline style
    // styleThis() {
    //   return {
    //     'background-image': 'url(' + styleImage + ')',
    //     'background-repeat': 'no-repeat',
    //     'background-size': 'cover'
    //   }
    // }
  },
  data: function () {
    return {
  //     dataToken: []
    };
  },
  // mounted: function () {
  //   this.getData();
  // },
  methods: {
    // getData: function () {
    //   axios.get('https://urlhere').then((datasource) => {
    //     this.dataToken = datasource.data.nameOfFirstArray;
    //   });
    // },
    // myFunction: function () {
    //     this.dataSample.title = this.dataSample.title.replace(" ", "-");    
    // }
  },
  // created: function(){
  //   this.myFunctionOnLoad()
  // }
  // methods: {
  //   debug (event) {
  //     console.log(event.target.name)
  //   }
  // } 
}

</script>