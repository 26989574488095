import { render, staticRenderFns } from "./cosmopolitant-03-archi.vue?vue&type=template&id=0681deb6&scoped=true&"
import script from "./cosmopolitant-03-archi.vue?vue&type=script&lang=js&"
export * from "./cosmopolitant-03-archi.vue?vue&type=script&lang=js&"
import style0 from "./cosmopolitant-03-archi.vue?vue&type=style&index=0&id=0681deb6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0681deb6",
  null
  
)

export default component.exports