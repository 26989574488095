<template>
  <div class="page page-ttt">
    <Banner title="TRX: Today & Tomorrow"
        :style-banner="require('@/assets/05-trx-today-tomorrow/banner.jpg')">
      <template #description>
        <p>As TRX steadily takes shape, keep up to date
          with the latest progress from the recent deliveries, awards and recognitions,
          research studies, and future plans.</p>
      </template>
    </Banner>
    <div class="content-ttt">
      <div class="content-bg-list">
        <div class="cr-07 content-bg pfbgcolor_white"></div>
        <div class="cr-06 content-bg pfbgcolor_magentalight"></div>
        <div class="cr-05 content-bg pfbgcolor_white"></div>
        <div class="cr-04 content-bg pfbgcolor_magenta"></div>
        <div class="cr-03 content-bg pfbgcolor_white"></div>
        <div class="cr-02 content-bg"
            :style="`background-color: white; background-image: url('${require('@/assets/05-trx-today-tomorrow/bg-intro.jpg')}'); background-repeat: no-repeat; background-size: cover;`"></div>
      </div>
      <section id="cr-01" class="content-row">
        <div data-aos="fade-down" class="intro aos-init aos-animate">
          <div class="container">
            <!--            <div class="pwwrap pwstyle_01">-->
            <!--              <div class="text">-->
            <!--                <div class="body">-->
            <!--                  <p><span class="pfstyle_s64">A</span>s TRX steadily takes shape, keep up to date-->
            <!--                    with the latest progress from the recent deliveries, awards and recognitions,-->
            <!--                    research studies, and future plans</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <ImageComparisonSlider
                :before-img-src="require('@/assets/05-trx-today-tomorrow/01-trx-before.jpg')"
                :after-img-src="require('@/assets/05-trx-today-tomorrow/01-trx-after.jpg')"
                handle="pink"/>
            <div class="pwwrap pwstyle_01">
              <p>
                Tun Razak Exchange has transformed the city
                skyline and brought out a new side of Kuala
                Lumpur. It is the destination to experience the
                best the city has to offer. TRX is Kuala
                Lumpur’s premium business address and the
                new heart of the city.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="cr-02" class="content-row pfbgcolor_magentalight">
        <div data-aos="fade-down" class="discover-trx aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center pfcolor_magenta">Discover TRX</h2>
                  <p>From sustainability to connectivity,
                    learn about the distinct features that
                    make TRX in a class of its own.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <Slick :options="slickOptionsGallery"
                class="gallery-images mb-5">
              <div v-for="(video, index) in dataDiscoverTRXVideos"
                  class="gallery-slide-item px-3">
                <div class="gallery-slide-img ratio ratio-16x9">
                  <iframe :src="video.youtube_url"
                      frameborder="0"></iframe>
                </div>
              </div>
            </Slick>
            <div class="text-center"><a
                href="https://www.youtube.com/watch?v=4_uMHiEYodw&list=PLQcyTFs2ri6gy7p5BousAsqtV8IUOvzdy"
                target="_blank" class="pabutton_1">View Playlist</a>
            </div>
          </div>
          <div data-aos="fade-down">
            <div class="container">
              <div class="pwwrap pwstyle_01">
                <div class="text">
                  <div class="body"><p><span class="pfstyle_s64">T</span>hroughout its planning and
                    development,
                    market research studies were regularly
                    conducted to serve as a guide to help the
                    project realise its vision, verify that it is on the
                    right track, and to safeguard its relevance.</p></div>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" class="aos-init aos-animate">
            <div class="container">
              <PopupSwiperCardGallery :gallery-items="dataDiscoverTRXGallery"/>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" class="driven-by-eesg aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <h3 class="content-title text-center pfcolor_magenta">Driven by EESG</h3>
                  <p>TRX is a significant project of national
                    importance, bringing together the best
                    practices aligned to economic, environmental,
                    social, and governance (EESG) principles. The
                    project has attracted leading global and
                    domestic investors on the back of its strong
                    sustainability framework that includes district-
                    wide green infrastructure such as an onsite
                    wastewater treatment and recycling plant and
                    an expansive, lush public realm that makes up
                    almost a quarter of the development.</p>
                  <!--                  <div class="text-center">-->
                  <!--                    <a href="#" target="_blank" class="pabutton_1">Find out more about TRX and-->
                  <!--                      EESG</a>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cr-03" class="content-row text-white">
        <div data-aos="fade-down" class="trx-today aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center">TRX Today</h2>
                  <div class="body"><p><span class="pfstyle_s64">T</span>
                    RX is the workplace of the future. Today, the
                    district is buzzing with activities as workers,
                    travellers, and the community go about their
                    daily lives. Over 20,000 workers are now
                    based in the district, with leading brands like
                    HSBC, Huawei, Prudential, Accenture, Affin
                    Bank, Agoda and many more - all choosing TRX
                    as their home.
                  </p>
                    <p>It has truly become Malaysia’s International
                      Financial Centre with a social heart.
                    </p></div>
                </div>
              </div>
            </div>
          </div>
          <div class="marquee">
            <div class="marquee-to-left">
              <div v-for="(item, index) in dataTrxTodayMarquee">
                <img :src="item.img" alt="TRX Today">
              </div>
            </div>
            <div class="marquee-to-left">
              <div v-for="(item, index) in dataTrxTodayMarquee">
                <img :src="item.img" alt="TRX Today">
              </div>
            </div>
          </div>
          <div class="marquee flex-row-reverse">
            <div class="marquee-to-right">
              <div v-for="(item, index) in dataTrxTodayMarquee">
                <img :src="item.img" alt="TRX Today">
              </div>
            </div>
            <div class="marquee-to-right">
              <div v-for="(item, index) in dataTrxTodayMarquee">
                <img :src="item.img" alt="TRX Today">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cr-04" class="content-row">
        <div data-aos="fade-down" class="trx-public-realm aos-init aos-animate">
          <div class="container trx-today">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center pfcolor_magenta">The TRX Public Realm</h2>
                  <p>TRX is not only designed to be a conducive
                    place for business, but it also prioritises nature
                    and public spaces for the community. The TRX
                    Public Realm is a thematic destination in a
                    park-like setting. It is the city’s heartbeat
                    powered by well-curated placemaking.
                  </p>
                </div>
                <div class="position-relative text-center">
                  <div class="body">
                    <figure>
                      <img src="@/assets/05-trx-today-tomorrow/04-public-realm.jpg"
                          alt="TRX Public Realm" class="img-fluid">
                      <img src="@/assets/05-trx-today-tomorrow/04-public-realm-highlight.jpg"
                          alt="TRX Public Realm"
                          class="position-absolute top-0 start-50 translate-middle-x img-fluid"
                          data-aos="fade-in" data-aos-anchor-placement="top-center">
                      <figcaption>
                        <i><small>
                          An early sketch of the TRX Master Plan by Grant Associates, illustrating
                          the
                          concept of a green heart in TRX. It is inspired by the nutmeg and
                          mangosteen
                          –
                          where the buildings are portrayed as the tough and protective outer skin
                          enclosing
                          a valuable heart, the central park.</small></i>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" class="trx-green-infra aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>
                    <span class="pfstyle_s64">N</span>estled in between skyscrapers is green
                    infrastructure. Open plazas and green spaces
                    are linked by a network of shady pedestrian
                    walkways that ultimately leads to the 10-acre
                    elevated park, located in the heart of the
                    district.
                  </p>
                </div>
              </div>
            </div>
            <div class="pwwrap pwstyle_04 position-relative" data-aos="zoom-in">
              <div class="body">
                <img src="@/assets/05-trx-today-tomorrow/el-kerawang.png" alt="Kerawang"
                    class="position-absolute kerawang-first">
                <img src="@/assets/05-trx-today-tomorrow/04-green-infra.jpg"
                    alt="Green Infrastructure" class="position-relative img-fluid">
              </div>
            </div>
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>The vibrant rooftop park sits above the lifestyle hub, The Exchange TRX, which
                    will feature cafes, restaurants, and premium retail brands from all over the
                    world. The lifestyle centrepiece is KL’s next major destination.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cr-05" class="content-row">
        <div data-aos="fade-down" class="trx-public-realm aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center pfcolor_magenta">A Public Realm for All</h2>
                  <p>The TRX Public Realm is carefully designed with people in mind in terms of
                    space, playscape and landscape. It is where the public will gather to relax,
                    socialise, and experience the spaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" class="aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_04 position-relative exchange-106-video">
              <PopupVideo title="Building TRX"
                  :overlay-image="require('@/assets/05-trx-today-tomorrow/bg-brand-video-overlay.png')"
                  :preview-vid="require('@/assets/05-trx-today-tomorrow/building-trx.mp4')"
                  full-yt-vid="https://www.youtube.com/embed/Q5guZYV0MTs?si=57HmeQumJ4A3vAB2"
              />
            </div>
          </div>
          <div class="gallery-public-realm">
            <div class="container">
              <div class="pwwrap pwstyle_01">
                <div class="text">
                  <div class="body">
                    <p>The TRX Public Realm is carefully designed
                      with people in mind in terms of space,
                      playscape and landscape. It is where the
                      public will gather to relax, socialise, and
                      experience the spaces.</p>
                  </div>
                </div>
              </div>
              <div class="gallery-former" data-aos="fade-down">
                <slick v-if="dataToken.length" ref="slick" :options="slickOptionsGalleryPublicRealm"
                    class="gallery-images">
                  <div v-for="(item, index, id) in dataToken" :key="index"
                      v-if="item.section_id == 21" class="gallery-slide-item">
                    <div class="gallery-slide-img">
                      <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                          :alt="'gallery-image-' + index" class="img-fluid">
                    </div>
                  </div>
                </slick>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" class="trx-public-realm aos-init aos-animate">
            <div class="container">
              <div class="pwwrap pwstyle_01">
                <div class="text">
                  <div class="body">
                    <h2 class="content-title text-center pfcolor_magenta">An Active Public
                      Space</h2>
                    <p>The TRX Public Realm is made up of spaces
                      that can be adapted for different functions; as
                      a place to wind down and watch the world go
                      by, or transformed to host art, cultural,
                      fitness, and lifestyle events. The public space
                      is vibrant and exciting with activities planned
                      all year-round.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="events-gallery" data-aos="fade-up">
            <div class="container-fluid-right">
              <EventGalleries :galleries="dataEvents"/>
            </div>
          </div>
        </div>
      </section>
      <section id="cr-06" class="content-row">
        <div data-aos="fade-down" class="trx-public-realm map aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center pfcolor_magenta">Future Planning</h2>
                  <p>
                    The next focus of TRX is South Site. Located in
                    the southern part of Tun Razak Exchange,
                    South Site is an extension of TRX, with
                    facilities and amenities that will complement
                    TRX North.
                  </p>
                </div>
              </div>
            </div>
            <MapFuturePlan/>
          </div>
        </div>
      </section>
      <section id="cr-07" class="content-row">
        <div data-aos="fade-down" class="trx-public-realm aos-init aos-animate">
          <div class="container">
            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center pfcolor_magenta">Awards & Recognition</h2>
                  <p>
                    TRX and its partners have been
                    recognised for its high quality and
                    standards by renowned organisations.
                  </p>
                </div>
              </div>
            </div>
            <!--            <div class="swiper-awards">-->
            <!--              <Swiper class="overflow-visible" :options="SwiperOptionsAwards">-->
            <!--                <SwiperSlide v-for="(item, index) in dataAwards" :key="index">-->
            <!--                  <div class="award-card">-->
            <!--                    <div>-->
            <!--                      <div v-for="(award, index) in item.awards">-->
            <!--                        <div class="mb-5">-->
            <!--                          <h3 class="mb-3">"{{ award.title }}"</h3>-->
            <!--                          <div v-html="award.desc"></div>-->
            <!--                          <img :src="award.image" :alt="award.title"-->
            <!--                              style="max-width: 100%; max-height: 200px">-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                    <div class="d-lg-flex align-items-lg-center">-->
            <!--                      <img :src="item.logo" :alt="item.title" class="me-3">-->
            <!--                      <h4 class="mb-0">{{ item.title }}</h4>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </SwiperSlide>-->
            <!--              </Swiper>-->
            <!--              <button class="swiper-prev swiper-arrow"><img :src="slickLeft">-->
            <!--              </button>-->
            <!--              <button class="swiper-next swiper-arrow"><img :src="slickRight">-->
            <!--              </button>-->
            <!--            </div>-->
            <div class="row">
              <div class="col-12 col-lg-10 offset-lg-1">
                <Accordion id="accordion_awards" :accordion="dataAwards"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import slickRight from '@/assets/05-trx-today-tomorrow/arrow-next-pink.png';
import slickLeft from '@/assets/05-trx-today-tomorrow/arrow-prev-pink.png';
import Accordion from '@/components/Accordion.vue';
import Banner from '@/components/Banner.vue';
import ImageComparisonSlider from '@/components/ImageComparisonSlider.vue';
import PopupVideo from '@/components/PopupVideo.vue';
import EventGalleries from '@/components/trx-today-tomorrow/EventGalleries.vue';
import MapFuturePlan from '@/components/trx-today-tomorrow/MapFuturePlan.vue';
import PopupSwiperCardGallery from '@/components/trx-today-tomorrow/PopupSwiperCardGallery.vue';
import AccordionAwards from '@/views/AccordionAwards.vue';
import axios from 'axios';
import {SwiperSlide} from 'vue-awesome-swiper';
import Slick from 'vue-slick';

export default {
  name: 'TrxTodayTomorrow',
  components: {
    Accordion,
    SwiperSlide,
    AccordionAwards,
    MapFuturePlan,
    EventGalleries,
    PopupSwiperCardGallery, PopupVideo, ImageComparisonSlider, Banner, Slick,
  },
  metaInfo() {
    return {
      title: 'TRX: Today & Tomorrow',
    };
  },
  data: function() {
    return {
      slickLeft,
      slickRight,
      apiBasePath: 'https://trx-api.okie.my/',
      dataEvents: [],
      dataTokenParaInner: [],
      dataToken: [],
      slickOptionsGallery: {
        slidesToShow: 3,
        centerPadding: 0,
        arrows: true,
        infinite: false,
        centerMode: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsGalleryPublicRealm: {
        slidesToShow: 2,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      SwiperOptionsAwards: {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          prevEl: '.swiper-awards .swiper-prev',
          nextEl: '.swiper-awards .swiper-next',
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        },
      },
      dataDiscoverTRXVideos: [
        {
          youtube_url: 'https://www.youtube.com/embed/4_uMHiEYodw?si=ro_dcUgq0KXa__ie',
        },
        {
          youtube_url: 'https://www.youtube.com/embed/erOchedW0AE?si=fjuJl3Gs8MbCsYx9',
        },
        {
          youtube_url: 'https://www.youtube.com/embed/FnC08PQxgDY?si=YBWshA0VXN07Xeh-',
        },
      ],
      dataDiscoverTRXGallery: [
        {
          img: require('@/assets/05-trx-today-tomorrow/WOTF_1.png'),
          alt: `<p>What Workers Want – Malaysia by Savills, November 2021</p>`,
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/WOTF_2.png'),
          alt: `<p>What Workers Want – Malaysia by Savills, November 2021</p>`,

        },
        {
          img: require('@/assets/05-trx-today-tomorrow/BCG_2011_2.png'),
          alt: `<p>Demand Study by The Boston Consulting Group, May 2011</p>`,

        },
        {
          img: require('@/assets/05-trx-today-tomorrow/CH_Williams_2010.png'),
          alt: `<p>Global & Regional Study – Peer City Review by CH Williams Talhar & Wong, October 2010</p>`,

        },
        {
          img: require('@/assets/05-trx-today-tomorrow/BCG_2010.png'),
          alt: `<p>Concept Study by The Boston Consulting Group, August 2010</p>`,

        },
      ],
      dataTrxTodayMarquee: [
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-01.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-02.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-03.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-04.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-05.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-06.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-07.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-08.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-09.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-10.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-11.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-12.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-13.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-14.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-15.jpg'),
        },
        {
          img: require('@/assets/05-trx-today-tomorrow/Marquee-16.jpg'),
        },
      ],
      dataAwards: [
        {
          title: 'ASEAN Federation of Engineering Organisations (AFEO) 2022',
          logo: require('@/assets/05-trx-today-tomorrow/awards/01/logo.jpg'),
          bodies: [
            {
              title: 'Award Winner',
              desc: '<p>ASEAN Outstanding Engineering Achievement Award for Sewerage Treatment and Plant Design by TRX City Sdn Bhd</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/01/image.jpg'),
            },
          ],
        },
        {
          title: 'World Green Building Council Asia Pacific Leadership in Green Building Awards 2022',
          logo: require('@/assets/05-trx-today-tomorrow/awards/02/logo.jpg'),
          bodies: [
            {
              title: 'Award Winner',
              desc: '<p>Leadership in Sustainable Design and Performance Award for Commercial Category for Menara IQ by HSBC Malaysia</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/02/image.jpg'),
            },
          ],
        },
        {
          title: 'The Edge Malaysia Best Managed & Sustainable Property Awards',
          logo: require('@/assets/05-trx-today-tomorrow/awards/03/logo.jpg'),
          bodies: [
            {
              title: 'Gold Winner',
              desc: '<p>Below 10 Years Non-strata Office Category for Menara Prudential</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/03/image.jpg'),
            },
          ],
        },
        {
          title: 'Malaysia Green Building Council Leadership in Sustainability Awards 2022',
          logo: require('@/assets/05-trx-today-tomorrow/awards/04/logo.jpg'),
          bodies: [
            {
              title: 'Award Winner',
              desc: '<p>The Best New Green & Sustainable Commercial Building Award for Menara IQ by HSBC Malaysia</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/04/0/image.jpg'),
            },
            {
              title: 'Honorary Mention',
              desc: '<p>The Best New Green & Sustainable Insert corresponding logo/images Commercial Building Award for Menara Affin by Affin Bank</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/04/1/image.jpg'),
            },
          ],
        },
        {
          title: 'Council on Tall Buildings & Urban Habitat 2022',
          logo: require('@/assets/05-trx-today-tomorrow/awards/05/logo.png'),
          bodies: [
            {
              title: 'Tallest Building in Malaysia',
              desc: '<p>Fourteenth Tallest Building in the World for Exchange 106</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/05/0/image.jpg'),
            },
            {
              title: 'Award of Excellence',
              desc: '<p>Best Tall Building 400m and Above for Exchange 106</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/05/1/image.jpg'),
            },
          ],
        },
        {
          title: 'Institute of Engineers Malaysia Awards 2021',
          logo: require('@/assets/05-trx-today-tomorrow/awards/06/logo.jpg'),
          bodies: [
            {
              title: 'Award Winner',
              desc: '<p>IEM Outstanding Engineering Achievement Award for Sewerage Treatment Plan Design by TRX City Sdn Bhd</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/06/image.jpg'),
            },
          ],
        },
        {
          title: 'Asia Pacific Property Awards 2020-2021',
          logo: require('@/assets/05-trx-today-tomorrow/awards/07/logo.jpg'),
          bodies: [
            {
              title: '5-Star Award',
              desc: '<p>Best Mixed-Use Development Malaysia for Tun Razak Exchange by TRX City Sdn Bhd</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/07/0/image.jpg'),
            },
            {
              title: 'Award Winner',
              desc: '<p>Development Marketing Malaysia for Tun Razak Exchange by TRX City Sdn Bhd</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/07/1/image.jpg'),
            },
          ],
        },
        {
          title: '12th Malaysia Landscape Architecture Awards 2019',
          logo: require('@/assets/05-trx-today-tomorrow/awards/08/0/logo.jpg'),
          bodies: [
            {
              title: 'Honour Award',
              desc: '<p>Landscape Analysis & Study Awards for TRX Public Realm & Streetscape Enhancement by Landart design Sdn Bhd</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/08/0/image.jpg'),
            },
          ],
        },
        {
          title: '11th Malaysia Landscape Architecture Award 2018',
          logo: require('@/assets/05-trx-today-tomorrow/awards/08/1/logo.jpg'),
          bodies: [
            {
              title: 'Excellence Award',
              desc: '<p>Landscape Master Plan Award for TRX Public Realm and Master Plan by TRX City Sdn Bhd</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/08/1/image.jpg'),
            },
          ],
        },
        {
          title: 'Green Building Index Accreditation Panel',
          logo: require('@/assets/05-trx-today-tomorrow/awards/09/logo.jpg'),
          bodies: [
            {
              title: 'Platinum Provisional Certificate',
              desc: '<p>First district in Malaysia to achieve GBI Township Platinum Provisional Certification</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/09/image.jpg'),
            },
          ],
        },
        {
          title: 'LEED (US) Green Building Council',
          logo: require('@/assets/05-trx-today-tomorrow/awards/10/logo.jpg'),
          bodies: [
            {
              title: 'Gold Pre-certified Plan',
              desc: '<p>LEED Neighbourhood Development Gold Pre-certified Plan</p>',
              image: require('@/assets/05-trx-today-tomorrow/awards/10/image.jpg'),
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData: function() {
      axios.get('https://trx-api.okie.my/api/general/chapter/5/section/all')
          .then(response => response.data)
          .then(datasource => {
            this.dataToken = datasource;
            // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
            for (let i = 0; i < datasource.length; i++) {
              const content = datasource[i].paragraph_timeline.replace(/\\/g, '');
              if (content && content !== '') {
                this.dataTokenParaInner.push(JSON.parse(content));
              }
            }
          });
      axios.get('https://trx-api.okie.my/api/general/chapter/5/section/20')
          .then(response => response.data)
          .then(datasource => {
            this.dataEvents = datasource;
          });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/chap5.scss';
</style>

<style scoped>
.award-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.swiper-awards {
  position: relative;
}

.swiper-awards .swiper-slide {
  height: auto;
}

.swiper-awards .swiper-slide h3 {
  color: #E84B86;
}

.swiper-awards .swiper-slide:nth-child(3n+1) {
  background-color: #FFF6FA;
}

.swiper-awards .swiper-slide:nth-child(3n+2) {
  background-color: #FFE6EF;
}

.swiper-awards .swiper-slide:nth-child(3n+3) {
  background-color: #F580AC;
}

.swiper-awards .swiper-slide:nth-child(3n+3),
.swiper-awards .swiper-slide:nth-child(3n+3) h3 {
  color: #fff;
}

.swiper-awards .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.swiper-awards .swiper-prev {
  left: -30px;
}

.swiper-awards .swiper-next {
  right: -30px;
}
</style>