<template>
  <div class="accordion accordion-flush" :id="id">
    <div v-for="(item, key) in accordion" class="accordion-item">
      <h2 class="accordion-header" :id="`heading_${id}_${key}`">
        <button :class="{'collapsed' : key !== 0}" class="accordion-button" type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#item_${id}_${key}`" aria-expanded="false"
            :aria-controls="`item_${id}_${key}`">
          <div class="wrapper">
            <div class="image"><img :src="item.logo" :alt="item.title"/></div>
            <h4>{{ item.title }}</h4>
          </div>
        </button>
      </h2>
      <div :id="`item_${id}_${key}`" :class="{'show' : key === 0}"
          class="accordion-collapse collapse"
          :aria-labelledby="`heading_${id}_${key}`" :data-bs-parent="`#${id}`">
        <div class="accordion-body">
          <div v-for="(body, key) in item.bodies" class="body-item">
            <div class="wrapper">
              <div class="image">
                <img :src="body.image" :alt="body.title">
              </div>
              <div class="text">
                <h3>"{{ body.title }}"</h3>
                <div v-html="body.desc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    id: String,
    accordion: [],
  },
};
</script>

<style scoped>
.accordion-item {
  margin-bottom: 15px;
  border-bottom: 0;
}

.accordion-button {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  background-color: #FFE6EF !important;
  color: #000 !important;
  box-shadow: none;
}

.accordion-button .image {
  width: 100px;
  height: 100px;
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.accordion-button .image img {
  max-width: 100%;
  height: auto;
}

.accordion-body {
  padding: 30px 40px;
  background-color: #FFF6FA;
}

.accordion-body .body-item {
  display: flex;
  padding: 30px 0;
}

.accordion-body .body-item:not(:last-child) {
  border-bottom: 1px solid #E84B86;
}

.accordion-body .body-item h3 {
  color: #E84B86;
  margin-bottom: 30px;
}

.accordion-body .image {
  margin-right: 30px;
  margin-bottom: 30px;
}

.accordion-body .image img {
  width: 170px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
}

.accordion-button::after {
  background-image: url("~@/assets/05-trx-today-tomorrow/i-expand.png");
  width: 50px;
  height: 50px;
  background-size: contain;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("~@/assets/05-trx-today-tomorrow/i-collapse.png");
  transform: rotate(0) !important;
}

@media (max-width: 767px) {
  .accordion-body .body-item {
    flex-direction: column;
  }

  .accordion-button .image {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    margin-right: 15px;
  }

  .accordion-button,
  .accordion-body {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .accordion-header .wrapper {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .accordion-body .body-item .wrapper {
    display: flex;
    width: 60%;
  }
}
</style>