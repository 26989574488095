<template>
  <div class="row">
    <div ref="stats" v-for="stat in dataTrxStats" class="stat" data-aos="fade-up"
        data-aos-mirror="true"
        data-aos-anchor-placement="top-center">
      <div class="row">
        <div class="stick offset-lg-1 col-lg-3 text-center">
          <img :src="stat.image_url" :alt="stat.image_alt"/>
        </div>
        <div class="stat-text col-12 col-lg-7">
          <div class="row">
            <div class="col-lg-6">
              <div
                  class="d-flex align-items-center justify-content-center justify-content-lg-end text-white text-center">
                    <span v-if="stat.number_prefix"
                        class="stat-prefix me-3">
                      {{ stat.number_prefix }}
                    </span>
                <div>
                  <span v-if="stat.number" :value="stat.number"
                      class="stat-number">{{ 0 }}</span>
                  <div v-if="stat.number_unit" class="stat-unit">
                    {{ stat.number_unit }}
                  </div>
                </div>
                <span v-if="stat.number_suffix" class="stat-suffix ms-3">{{
                    stat.number_suffix
                  }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                  class="d-flex align-items-center justify-content-center justify-content-lg-end text-white">
                <div v-if="stat.text"
                    class="stat-text text-uppercase text-center text-lg-left">
                  {{ stat.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {startNumberFlipping} from '@/helpers.js';

export default {
  name: 'TrxInNumbers',
  props: {
    dataTrxStats: {},
  },
  mounted() {
    this.$refs.stats.forEach(stat => {
      window.addEventListener('scroll', () => {
        if (!stat.classList.contains('aos-animate')) return;

        let numberEl = stat.querySelector('.stat-number');

        startNumberFlipping(numberEl, numberEl.getAttribute('value'));
      });
    });
  },
};
</script>

<style scoped>
.stat {
  height: 100vh;
}

.stat > .row {
  height: auto;
  display: flex;
}

.stat-prefix,
.stat-number,
.stat-suffix,
.stat-unit,
.stat-text {
  font-family: 'Playfair Display', serif;
}

.stat-prefix,
.stat-number,
.stat-suffix {
  font-size: 60px;
  line-height: 1;
}

.stat-unit {
  font-size: 20px;
}

.stat-text {
  font-size: 36px;
}

@media (min-width: 992px) {
  .stat {
    height: 150vh;
  }

  .stick {
    position: sticky;
    top: calc(50% - 64px);
    align-self: flex-start;
  }

  .stat-text {
    align-self: center;
  }

  .stat > .row {
    height: 100%;
    display: flex;
  }

  .stat-text > .row {
    align-items: center;
  }

  .stat-prefix,
  .stat-number,
  .stat-suffix {
    font-size: 120px;
  }

  .stat-unit {
    font-size: 28px;
  }

  .stat-text {
    font-size: 48px;
  }
}

</style>