<template>
  <div class="page page-cnc">

    <!-- <div :style="styleThis"> -->

    <!-- <HelloWorld class="text-center" msg="Welcome to Your Vue.js App"/> -->
    <div class="banner position-relative">
      <div class="banner-image position-relative d-block" data-aos="zoom-out">
        <img :src="styleBanner" alt="banner-construction-collaboration" class="img-fluid"
            width="100%">
      </div>
      <div
          class="banner-content position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
        <div class="pwwrap pwstyle_01">
          <div class="text text-center text-white">
            <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back"
                data-aos-duration="800">Construction & Collaboration</h1>
            <img :src="styleUDFooter" alt="underline-footer" class="img-fluid" width="100%"
                data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">
            <div class="body" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"
                data-aos-delay="400" data-aos-duration="800">
              <p>TRX believes in the spirit of collaboration by working alongside the Government,
                state agencies, and GLCs to elevate the city.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-next position-absolute text-center bottom-0 start-50 translate-middle-x"
          data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
        <a class="banner-scroll">
          <p>Explore TRX</p>
          <div class="mouse"></div>
        </a>
      </div>
    </div>
    <!-- content -->
    <div class="cnc content-cnc">

      <div class="content-bg-list">
        <!-- <div class="cr-09 content-bg pfbgcolor_white"><div></div></div> -->
        <div class="cr-04 content-bg pfbgcolor_orangelight">
          <div></div>
        </div>
        <div class="cr-03 content-bg" :style="styleTrxNumber">
          <div></div>
        </div>
        <div class="cr-02 content-bg" :style="styleIntro"></div>
      </div>

      <section class="content-row" id="cr-01">
        <div class="intro" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p><span class="pfstyle_s64">I</span>n 2012, the groundbreaking ceremony was held
                    during the official launch of TRX, and massive earthworks took place over the
                    next two years.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="construction-location">
            <div class="container">
              <div class="gallery-former">
                <slick v-if="dataToken.length" ref="slick" :options="slickOptionsGallery"
                    class="gallery-images">
                  <div v-for="(item, index, id) in dataToken" :key="index"
                      v-if="item.section_id == 18" class="gallery-slide-item">
                    <div class="gallery-slide-img">
                      <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                          :alt="'gallery-image-' + index" class="img-fluid">
                    </div>
                  </div>
                </slick>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content-row" id="cr-02">
        <div class="trx-numbers" data-aos="fade-up">
          <div class="container">
            <div class="pwwrap">
              <div class="text" data-aos="fade-down">
                <div class="body">
                  <h2 class="content-title text-white text-center">TRX in Numbers</h2>
                  <TrxInNumbers :data-trx-stats="dataTrxStats"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content-row" id="cr-03">
        <div class="trx-construction" data-aos="fade-up">
          <div class="container">
            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <h2 class="content-title text-center pfcolor_orange">Building a
                    District of Global Standards</h2>
                  <p>The first building to come up was Menara Prudential, which broke ground in 2015
                    and was initially developed by TRX, before being sold to IJM. </p>
                  <br>
                  <div class="d-flex justify-content-center">
                    <ImageComparisonSlider :before-img-src="compare01" :after-img-src="compare02"
                        handle="red"/>
                  </div>
                  <br><br>
                  <h3 class="content-title text-center pfcolor_orange">
                    Construction of Exchange 106
                  </h3>
                  <p>In 2016, the construction of one of Asia’s tallest buildings, Exchange 106
                    began taking shape, with one of the world’s largest concrete pour that took 48
                    hours and involved 120 cement mixers carrying 8.5 million kg of cement, 16
                    million kg of sand, 19 million kg of construction aggregates and about two
                    million kg of ice to overcome the extreme heat generated during the process.
                  </p>
                  <p>Over the same period, TRX started rolling out a large-scale, world-class
                    infrastructure programme with an estimated RM3 billion invested to construct
                    roads, tunnels, bridges, parks, and plazas, to create a district with an
                    unrivalled built environment and public realm, with the best connectivity in the
                    city. </p>
                </div>
              </div>
            </div>
            <div class="pwwrap pwstyle_04 position-relative exchange-106-video">
              <img :src="require('@/assets/04-cnc/cnc-04-kerawang.png')" alt="Kerawang"
                  class="position-absolute kerawang-first">
              <PopupVideo title="Exchange 106"
                  :overlay-image="require('@/assets/04-cnc/cnc-04-bg-brand-video-overlay.png')"
                  :preview-vid="require('@/assets/04-cnc/cnc-04-exchange-106.mov')"
                  :full-local-vid="require('@/assets/04-cnc/cnc-04-exchange-106.mov')"/>
              <img :src="require('@/assets/04-cnc/cnc-04-kerawang.png')" alt="Kerawang"
                  class="position-absolute kerawang-last">
            </div>
            <div class="pwwrap overflow-x-hidden">
              <h3 class="content-title text-center pfcolor_orange"> Explore The Construction
                Journey </h3>
              <slick ref="slick-1" :options="slickOptionsGallery"
                  class="gallery-images mb-5">
                <div v-for="(video, index) in dataConstructionJourneyVideos"
                    class="gallery-slide-item px-3">
                  <div class="fw-bold pfcolor_orange date">{{ video.title }}</div>
                  <div class="gallery-slide-img ratio ratio-16x9">
                    <iframe :src="video.youtube_url"
                        frameborder="0"></iframe>
                  </div>
                </div>
              </slick>
              <div class="text-center">
                <a href="https://www.youtube.com/watch?v=kg5bWnWNBGU&list=PLQcyTFs2ri6gSoCWrhOkA1p-NdktBthmh"
                    target="_blank" class="pabutton_1">
                  Check out the full TRX Site Progress Playlist
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content-row" id="cr-04">
        <div class="collaboration" data-aos="fade-down">
          <div class="container">
            <h2 class="content-title pfcolor_orange text-center">Collaboration with Partners </h2>
            <div class="pwwrap pwstyle_01">
              <div class="body">
                <div class="text">
                  <p>When it comes to working with different agencies, things are done differently
                    at TRX. Each partnership looks for the best outcome by finding new ways of
                    cooperation that will bring benefits to both sides.
                  </p>
                  <p>For instance, the relocation of Pasar Baru Bukit Bintang involved the
                    engagement with traders alongside DBKL, from the design stage to move-in day.
                    TRX also facilitated the relocation by providing logistics and manpower. This
                    led to the building of the Integrated Commercial Complex, a modern market with
                    better facilities to support traders upon their relocation. </p>
                  <p>The same level of collaborative spirit can be seen when teaming up with
                    authorities and partners for a mutually beneficial outcome in the process of
                    developing TRX. </p>
                </div>
              </div>
            </div>
            <br><br>
            <h3 class="content-title pfcolor_orange text-center">Explore the Partnerships</h3>
            <div class="pwwrap pwstyle_04 position-relative mb-5">
              <img :src="require('@/assets/04-cnc/cnc-05-partnership.png')" alt="Partnership"
                  class="img-fluid" data-aos="zoom-out" data-aos-anchor-placement="top-center">
              <div v-for="(item, index) in dataTokenArea" :key="index"
                  class="tooltip-icon"
                  :id="`tooltip_${item.nid}`" v-b-modal="item.nid" data-aos="fade-down">
                <div class="tooltip-box">
                  {{ item.title }}
                </div>
                <ModalGallery :item="item" theme="orange"/>
              </div>
            </div>
            <div class="pwwrap pwstyle_01">
              <p>
                With the first phase of TRX project coming to completion, the district is on track
                to
                become a thriving hub for workers and residents alike, an International Financial
                Centre like no other.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>


    <!--    <b-modal id="brandVideoModal" centered hide-footer no-stacking size="xl">-->
    <!--      <template #modal-header="{ close }">-->
    <!--        <b-button size="default" class="btn-close" @click="close()"></b-button>-->
    <!--      </template>-->
    <!--      <template>-->
    <!--        <modalBrandVideo/>-->
    <!--      </template>-->
    <!--    </b-modal>-->
  </div>
</template>

<script>
import styleBanner from '@/assets/04-cnc/cnc-01-banner.jpg';
// @ is an alias to /src
import styleBgIntro from '@/assets/04-cnc/cnc-02-bg-intro.jpg';
import styleBgTrxNumber from '@/assets/04-cnc/cnc-03-bg-trx-number.jpg';
import styleBgVidBrandOverlay from '@/assets/04-cnc/cnc-04-bg-brand-video-overlay.png';

import compare01 from '@/assets/04-cnc/cnc-04-compare-01.jpg';
import compare02 from '@/assets/04-cnc/cnc-04-compare-02.jpg';
import styleBgVidBrandVid from '@/assets/04-cnc/cnc-04-exchange-106.mov';

// import styleImage from '@/assets/osboot_sample_texture_500x500__01.jpg'
import styleBgFooter from '@/assets/04-cnc/malaya_batik.svg';
import slickRight from '@/assets/arrow-next-transparent.png';
import slickLeft from '@/assets/arrow-prev-transparent.png';

import MileClose from '@/assets/ico-milestones-modal-close.png';
import btnNewTabBlack from '@/assets/ico-newtab-black.png';
import btnNewTab from '@/assets/ico-newtab.png';
import styleBannerNext from '@/assets/ideation/ideation-01-banner-arrow.png';
import styleBgIntro2 from '@/assets/ideation/ideation-02-bg-intro-transparent.png';

import styleBgBrandVid from '@/assets/ideation/ideation-05-bg-brandvideo.jpg';
import styleUDFooter from '@/assets/underline-footer.png';
import styleIcoBrandVid from '@/assets/video-play-ico-transparent.png';
// import style from '@/assets/ideation/asdasd.jpg'
import ModalGallery from '@/components/construction-collaboration/ModalGallery.vue';
import ImageComparisonSlider from '@/components/ImageComparisonSlider.vue';
import PopupVideo from '@/components/PopupVideo.vue';
import {startNumberFlipping} from '@/helpers.js';
import TrxInNumbers from '@/views/TrxInNumbers.vue';
import axios from 'axios';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import SwiperCore, {Autoplay, Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
// import HelloWorld from '@/components/HelloWorld.vue'
import Slick from 'vue-slick';

SwiperCore.use([Autoplay, Navigation]);

export default {
  name: 'ConstructionCollaboration',
  components: {
    TrxInNumbers,
    ModalGallery,
    PopupVideo,
    ImageComparisonSlider,
    // HelloWorld
    Slick,

  },
  metaInfo() {
    return {
      title: 'Construction & Collaboration',
    };
  },
  data: function() {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      compare01: compare01,
      compare02: compare02,
      dataToken: [],
      dataTokenParaInner: [],
      dataTokenArea: [],
      dataTrxStats: [
        {
          image_url: require('@/assets/04-cnc/cnc-03-land-area.svg'),
          image_alt: '70 Acres of Land Area',
          number: 70,
          number_prefix: '',
          number_suffix: '',
          number_unit: '',
          text: 'Acres of Land Area',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-building.svg'),
          image_alt: '30 Buildings',
          number: 30,
          number_prefix: '',
          number_suffix: '',
          number_unit: '',
          text: 'Buildings',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-residences.svg'),
          image_alt: '3800 Units of Residences',
          number: 3800,
          number_prefix: '',
          number_suffix: '',
          number_unit: '',
          text: 'Units of Residences',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-offices.svg'),
          image_alt: '10 Million SQ FT Office',
          number: 10,
          number_prefix: '',
          number_suffix: '',
          number_unit: 'Million',
          text: 'SQ FT of Office Space',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-retail.svg'),
          image_alt: '2 Million SQ FT Retail',
          number: 2,
          number_prefix: '',
          number_suffix: '',
          number_unit: 'Million',
          text: 'SQ FT of Retail Space',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-greenery-open-space.svg'),
          image_alt: '23% Greenery & Open Space',
          number: 23,
          number_prefix: '',
          number_suffix: '',
          number_unit: 'Percent',
          text: 'Greenery & Open Space',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-plant-species.svg'),
          image_alt: '150 Plant Species',
          number: 150,
          number_prefix: '',
          number_suffix: '',
          number_unit: '',
          text: 'Plant Species',
        },
        {
          image_url: require('@/assets/04-cnc/cnc-03-mrt.svg'),
          image_alt: '45 Minutes duration to reach TRX from any MRT station',
          number: 45,
          number_prefix: '≤',
          number_suffix: '',
          number_unit: 'Minutes',
          text: 'duration to reach TRX from any MRT station',
        },
      ],
      dataConstructionJourneyVideos: [
        {
          title: 'April 2015',
          youtube_url: 'https://www.youtube.com/embed/5O2hrozkdbo',
        },
        {
          title: 'Building Local Talent',
          youtube_url: 'https://www.youtube.com/embed/_2AYDo7HLeM',
        },
        {
          title: 'June 2017',
          youtube_url: 'https://www.youtube.com/embed/WP10rc4y9ms',
        },
        {
          title: 'Building TRX',
          youtube_url: 'https://www.youtube.com/embed/nTTBL3aG8wU',
        },
        {
          title: 'August 2019',
          youtube_url: 'https://www.youtube.com/embed/SO4QVSaRPZE',
        },
        {
          title: 'Lifting Operation',
          youtube_url: 'https://www.youtube.com/embed/0BGlBPoLj9Y',
        },
        {
          title: 'September 2021',
          youtube_url: 'https://www.youtube.com/embed/SuL9rfoOXDw',
        },
        {
          title: 'February 2022',
          youtube_url: 'https://www.youtube.com/embed/-cId1ppE14w?start=40',
        },
        {
          title: 'Public Spaces',
          youtube_url: 'https://www.youtube.com/embed/Q5guZYV0MTs',
        },
        {
          title: 'Exchange 106',
          youtube_url: 'https://www.youtube.com/embed/6ERxAzme5h8',
        },
      ],
      btnNewTab: btnNewTab,
      btnNewTabBlack: btnNewTabBlack,
      styleBgFooter: styleBgFooter,
      styleUDFooter: styleUDFooter,
      styleBanner: styleBanner,
      styleBannerNext: styleBannerNext,
      // styleBgIntro: styleBgIntro,
      styleBgIntro2: styleBgIntro2,
      styleBgBrandVid: styleBgBrandVid,
      styleBgVidBrandOverlay: styleBgVidBrandOverlay,
      styleBgVidBrandVid: styleBgVidBrandVid,
      styleIcoBrandVid: styleIcoBrandVid,
      MileClose: MileClose,
      slickRight: slickRight,
      slickLeft: slickLeft,
      slickOptions: {
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        // autoplay: true,
        // autoplaySpeed: 0,
        // speed: 4000,
        // cssEase:'linear',
        infinite: true,
        // slidesToScroll: 0.1,
        draggable: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },

          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsGallery: {
        slidesToShow: 2,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    styleIntro() {
      return {
        'background-image': 'url(' + styleBgIntro + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
    styleTrxNumber() {
      return {
        'background-image': 'url(' + styleBgTrxNumber + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
  },
  mounted: function() {
    this.getData();
    // $(window).on('load',function(){AOS.refresh();});
    this.$root.$on('bv::modal::shown', function() {
      $('html').css('overflow', 'hidden');
      // AOS.refresh();
    }).$on('bv::modal::hidden', function() {
      // AOS.init({disable:false});
      $('html').css('overflow', 'auto');
    });
    $('.swiper-auto').mouseenter(function() {
      console.log('stop autoplay');
      this.swiper.autoplay.stop();
    });
    $('.swiper-auto').mouseleave(function() {
      console.log('start autoplay');
      this.swiper.autoplay.start();
    });
  },
  methods: {
    getData: function() {
      axios.get('https://trx-api.okie.my/api/general/chapter/4/section/all')
          .then(response => response.data)
          .then(datasource => {
            this.dataToken = datasource;
            // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
            for (let i = 0; i < datasource.length; i++) {
              const content = datasource[i].paragraph_timeline.replace(/\\/g, '');
              if (content && content !== '') {
                this.dataTokenParaInner.push(JSON.parse(content));
              }
            }
          });
      axios.get('https://trx-api.okie.my/api/general/chapter/4/section/19')
          .then(response => response.data)
          .then(datasource => {
            this.dataTokenArea = datasource;
          });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/cnc.scss';
</style>