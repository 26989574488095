<template>
  <div class="inquirynow">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
          <div class="inquiry">
            <form oninput='confirmpassword.setCustomValidity(confirmpassword.value != password.value ? "Passwords do not match." : "")'>

              <div class="mb-4">
                <h2>{{ msg }}</h2>
              </div>

              <div class="mb-4">
                <label for="companynameInquiryNow" class="form-label">Company Name</label>
                <input id="companynameInquiryNow" class="form-control" type=text required name=companyname placeholder=Company NAME>
              </div>

              <div class="mb-4">
                <label for="nameInquiryNow" class="form-label">Name</label>
                <input id="nameInquiryNow" class="form-control" type=text required name=name placeholder=Name>
              </div>

              <div class="mb-4">
                <label for="usernameInquiryNow" class="form-label">E-mail address:</label>
                <input id="usernameInquiryNow" class="form-control" type=email required name=email placeholder=example@gmail.com>
              </div>

              <div class="mb-4">
                <label for="passwordInquiryNow1">Password:</label>
                <input id="passwordInquiryNow1" class="form-control" type=password required name=password>
              </div>
              
              <div class="mb-4">
                <label for="passwordInquiryNow2">Confirm password:</label>
                <input id="passwordInquiryNow2" class="form-control" type=password name=confirmpassword>
              </div>

              <div class="mb-4">
                <label for="messageInquiryNow" class="form-label">Message</label>
                <textarea class="form-control" id="messageInquiryNow" rows="3" placeholder=Message></textarea>
              </div>

              <div class="form-check mb-4">
                <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required>
                <label class="form-check-label" for="invalidCheck">
                  Agree to terms and conditions & Terms and conditions
                </label>
                <div class="invalid-feedback">
                  You must agree before submitting.
                </div>
              </div>

              <input type=submit value="Enquiry Now">

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InquiryNow',
  props: {
    msg: String
  }
}
</script>