<template>
  <div class="pwwrap d-block" ref="slider">
    <figure class="cd-image-container" data-aos>
      <div class="cd-ori-img">
        <img :src="afterImgSrc" alt="Original Image" class="img-fluid w-100">
        <span class="cd-image-label text-right" data-type="original" v-html="afterImgDesc"></span>
      </div>
      <div class="cd-resize-img" :style="{width: position}">
        <img :src="beforeImgSrc" alt="Modified Image" class="img-fluid">
        <span class="cd-image-label" data-type="modified" v-html="beforeImgDesc"></span>
      </div>
      <span :style="[{left: position}, handleColor]" class="cd-handle"
          @mousedown="onMouseDown" @touchstart="onMouseDown"></span>
    </figure>
    <div class="text">
      <div class="body" v-html="bodyText"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImageComparisonSlider',
  props: {
    handle: String,
    beforeImgSrc: String,
    afterImgSrc: String,
    beforeImgDesc: String,
    afterImgDesc: String,
    bodyText: String,
  },
  data() {
    return {
      isDragging: false,
      startX: 0,
      position: '50%',
    };
  },
  computed: {
    handleColor() {
      const color = this.handle === 'red'
          ? {backgroundImage: `url(${require('@/assets/04-cnc/cnc-04-compare-handle.png')})`} :
          this.handle === 'pink'
              ? {backgroundImage: `url(${require('@/assets/05-trx-today-tomorrow/compare-handle.png')})`}
              : {backgroundImage: `url(${require('@/assets/the-making-of-trx/home-06-compare-handle.png')})`};

      return color;
    },
  },
  methods: {
    onMouseDown(e) {
      this.isDragging = true;
    },
    onMouseMove(e) {
      if (!this.isDragging) return;
      const pageX = e.touches ? e.touches[0].pageX : e.pageX;
      const sliderWidth = this.$refs.slider.offsetWidth;
      const mouseX = pageX - this.$refs.slider.offsetLeft;

      let newPosition = (mouseX / sliderWidth) * 100;

      if (newPosition < 0) {
        newPosition = 0;
      } else if (newPosition > 100) {
        newPosition = 100;
      }
      this.position = newPosition + '%';

    },
    onMouseUp() {
      this.isDragging = false;
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('touchmove', this.onMouseMove);
    window.addEventListener('touchend', this.onMouseUp);
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('touchmove', this.onMouseMove);
    window.removeEventListener('touchend', this.onMouseUp);
  },
};
</script>

<style scoped>
.cd-image-container {
  position: relative;
  width: 100%;
  /*max-width: 768px;*/
  margin: 0em auto;
}

.cd-image-container img {
  display: block;
  pointer-events: none;
}

.cd-image-label {
  position: absolute;
  font-family: muli, sans-serif, Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  /*ƒmax-width: 300px;*/
  width: 300px;
  top: 0;
  right: 0;
  color: #ffffff;
  padding: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0.3s 0.7s;
  -moz-transition: -moz-transform 0.3s 0.7s, opacity 0.3s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
  text-shadow: 0px 1px 5px black;
}

.aos-animate .cd-image-label {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.cd-image-label.is-hidden {
  /* visibility: hidden;*/
  opacity: 0;
}

.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-resize-img img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: auto;
  max-width: none;
}

.cd-resize-img .cd-image-label {
  right: auto;
  left: 0;
}

.cd-image-container.aos-animate .cd-resize-img {
  width: 50%;
  /* bounce in animation of the modified image */
  -webkit-animation: cd-bounce-in 0.7s;
  -moz-animation: cd-bounce-in 0.7s;
  animation: cd-bounce-in 0.7s;
}

@-webkit-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}

@-moz-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}

@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}

.cd-handle {
  position: absolute;
  height: 84px;
  width: 84px;
  /* center the element */
  /*left: 50%;*/
  top: 50%;
  margin-left: -42px;
  margin-top: -42px;
  border-radius: 50%;
  background: #dc717d no-repeat center center;
  background-size: cover;
  cursor: move;
  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.6), inset 0 1px 0 rgba(255, 255, 255, 0.3);
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0) scale(0);
  -moz-transform: translate3d(0, 0, 0) scale(0);
  -ms-transform: translate3d(0, 0, 0) scale(0);
  -o-transform: translate3d(0, 0, 0) scale(0);
  transform: translate3d(0, 0, 0) scale(0);
  z-index: 999;
}

.cd-handle.draggable {
  /* change background color when element is active */
  background-color: #445b7c;
}

.cd-image-container.aos-animate .cd-handle {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  -moz-transform: translate3d(0, 0, 0) scale(1);
  -ms-transform: translate3d(0, 0, 0) scale(1);
  -o-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0s 0.7s;
  -moz-transition: -moz-transform 0.3s 0.7s, opacity 0s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
}
</style>