<template>
  <div class="d-lg-flex justify-content-center">
    <div v-for="(award, index) in awards" :key="index"
        :style="`background-color: ${award.theme};`"
        :class="currentIndex === index ? 'active' : 'collapse'"
        class="d-flex justify-content-between collapse-item"
        @click="currentIndex !== index ? currentIndex = index : currentIndex = null">
      <div :class="currentIndex === index ? 'flex-lg-column' : 'flex-lg-column-reverse' "
          class="d-flex flex-fill flex-column-reverse justify-content-between position-relative">
        <div :class="currentIndex === index ? 'fade-in' : 'fade-out'" class="content">
          <div v-for="testimonial in award.testimonials">
            <div class="mb-5">
              <h3 class="mb-3" :style="`color: ${award.title_color}`">
                "{{ testimonial.quote }}"
              </h3>
              <div>
                {{ testimonial.description }}
              </div>
            </div>
            <small>
              <div>{{ testimonial.for }}</div>
              <div>{{ testimonial.to }}</div>
            </small>
          </div>
        </div>
        <div class="award-party d-flex align-items-center"
            :style="`color: ${award.title_color};`">
          <div class="award-logo">
            <img :src="award.image" :alt="award.name">
          </div>
          <h4 class="award-name">
            {{ award.name }}
          </h4>
        </div>
      </div>
      <div class="toggle-icon">
        <img v-if="currentIndex === index" src="@/assets/05-trx-today-tomorrow/i-collapse.png"
            alt="Collapse">
        <img v-else src="@/assets/05-trx-today-tomorrow/i-expand.png" alt="Expand">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccordionAwards',
  data: function() {
    return {
      currentIndex: null,
      awards: [
        {
          theme: '#FFF7FA',
          title_color: '#E84B86',
          image: require('@/assets/05-trx-today-tomorrow/award-1.png'),
          name: 'Council of Tall Buildings & Urban Habitat 2021',
          testimonials: [
            {
              quote: 'Awards of Excellence',
              description: 'One of the best tall building above 400m',
              for: 'For Exchange 106',
              from: '',
            },
            {
              quote: 'Awards of Excellence',
              description: 'MEP Engineering Award 2021',
              for: 'For Exchange 106',
              from: 'Through BECA Group, engineering consultant',
            },
          ],
        },
        {
          theme: '#FFE6EF',
          title_color: '#E84B86',
          image: require('@/assets/05-trx-today-tomorrow/award-2.png'),
          name: 'The Edge Malaysia\'s Best Managed & Suistanable Property Awards 2022',
          testimonials: [
            {
              quote: 'Awards of Excellence',
              description: 'One of the best tall building above 400m One of the best tall building above 400m One of the best tall building above 400m',
              for: 'For Exchange 106',
              from: 'Through BECA Group, engineering consultant',
            },
          ],
        },
        {
          theme: '#FF9BC1',
          title_color: '#fff',
          image: require('@/assets/05-trx-today-tomorrow/award-3.png'),
          name: 'Malaysia GBC Leadership in Sustainability Awards 2022',
          testimonials: [
            {
              quote: 'Awards of Excellence',
              description: 'One of the best tall building above 400m',
              for: 'For Exchange 106',
              from: 'Through BECA Group, engineering consultant',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.collapse-item {
  flex: 0 0 20%;
  cursor: pointer;
  transition: .8s all;
  overflow: hidden;
  position: relative;
  padding: 2rem;
}

.collapse-item.active {
  flex: 0 0 60%;
}

.content {
  transition: .8s all;
  margin-bottom: 0;
  margin-top: 3rem;
}

.content.fade-in {
  opacity: 1;
  visibility: visible;
}

.content.fade-out {
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.collapse .content {
  display: none;
}

.content > *:not(last-child) {
  margin-bottom: 3rem;
}

.award-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 3rem;
  height: 3rem;
}

.award-logo > img {
  object-fit: contain;
  width: 100%;
}

.award-name {
  margin-bottom: 0;
  transform-origin: left center;
  transition: .8s all;
  padding-left: 3rem;
  margin-left: 0;
}


@media (min-width: 992px) {
  .collapse-item {
    min-height: 70vh;
  }

  .collapse-item.collapse {
    flex-direction: column-reverse;
  }

  .collapse-item .toggle-icon {
    position: absolute;
    top: 3rem;
    left: calc(100% - 92.5px);
    transition: .8s all;
  }

  .collapse-item.collapse .toggle-icon {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }

  .content {
    position: absolute;
  }

  .award-party {
    padding-top: calc(70vh - 6rem);
    margin-bottom: 0;
  }

  .award-name {
    position: absolute;
    padding-left: 4.25rem;
    margin-left: 2.5rem;
  }

  .collapse .award-name {
    transform: rotate(-90deg);
  }

  .award-logo {
    flex: 0 0 6.5rem;
    height: 6.5rem;
  }

  .award-name,
  .content {
    width: 330px;
  }

  .collapse-item {
    padding: 2rem;
  }

  .collapse .content {
    display: block;
  }
}

@media (min-width: 1200px) {
  .award-name,
  .content {
    width: 420px;
  }
}

@media (min-width: 1400px) {
  .award-name {
    width: calc(645.59px - 6rem);
    padding-left: 5.25rem;
    margin-left: 3rem;
  }

  .award-logo {
    flex: 0 0 7.5rem;
    height: 7.5rem;
  }

  .collapse-item {
    padding: 3rem;
  }

  .award-name,
  .content {
    max-width: calc(70vh - 6rem);
    width: 500px;
  }
}

</style>