
<template>
    <div class="modal-backdrop">
      <div class="modal" v-bind:class="{ 'd-block' : msg === true}" id="myDIV"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        
<p :inner-html.prop="theSelected"></p>
          <button
            type="button"
            class="btn-green"
            @click="close"
            aria-label="Close modal"
          >
            Close me 1!
          </button>

          <!-- <button
            ref="button1"
            type="button"
            class="btn-green"
            aria-label="Close modal"
          >
            Close me Open 2!
          </button> -->

      <!-- <button type="button" class="btn" @click="showModal2">Open Modal!</button> -->

      </div>
    </div>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      msg: Boolean,
      theSelected: String,
    },
    methods: {
      close() {
        this.$emit('closeB');
      },
      // close2() {
      //   this.$emit('close');
      //   this.$emit('clickTwo');
      // },
    },
  };
</script>

