<template>
  <div id="app">

    <!--  Modal  -->
    <button type="button" class="btn" @click="showModal" data-value="first">Open Modal!</button>
    <button type="button" class="btn" @click="showModal" data-value="second">Open Modal!</button>

    <transition name="modal-fade">
      <modal v-if="isModalVisible" @closeA="closeModal" @closeB="closeModal" :msg="isModalVisible" :theSelected="selectedModal"/>
    </transition>

    <div>
      <b-button v-b-modal.modal-multi-1>Open First Modal</b-button>

      <b-modal id="modal-multi-1" size="lg" title="First Modal" ok-only no-stacking>
        <p class="my-2">First Modal</p>
        <b-button v-b-modal.modal-multi-2>Open Second Modal</b-button>
      </b-modal>

      <b-modal id="modal-multi-2" title="Second Modal" ok-only>
        <p class="my-2">Second Modal</p>
        <b-button v-b-modal.modal-multi-3 size="sm">Open Third Modal</b-button>
      </b-modal>

      <b-modal id="modal-multi-3" size="sm" title="Third Modal" ok-only>
        <p class="my-1">Third Modal</p>
      </b-modal>
    </div>

    <!-- ````````SAMPLE: slick slider -->
    <slick ref="slick" :options="slickOptions">
      <div class="slick-item" v-for="(item, index) in slickImage">
        <img :src="item" alt="image" class="img-fluid">
      </div>
    </slick>
    
    <h3>SAMPLE: breadcrumb and active attribute</h3>
    <!--nav aria-label="breadcrumb">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="javascript:void(0)">Second level</a></li>
              <li class="breadcrumb-item active" aria-current="page">Sample</li>
            </ol>
          </div>
        </div>
      </div>
    </nav-->

    <h3>SAMPLE: if string contains word, do split</h3>
    <!-- <iframe width="100%" height="320" :src="sampleVideo.includes('youtu.be') ? 'https://www.youtube.com/watch?v=' + sampleVideo.toString().split('/')[3] : sampleVideo"></iframe> -->

    <!-- <section class="content-row" v-for="(item, index) in samplePublicDetail.field_title"> -->
      <!-- <div class="container"> -->
        <!-- <div class="inner-item"> -->

          <h3>SAMPLE: title</h3>
          <!-- <h2>{{ item }}</h2> -->

          <h3>SAMPLE: different field with same array sequence as title using [index] & inner html injecting</h3>
          <!-- <div class="sample-description" :inner-html.prop="samplePublicDetail.field_description[index]"></div> -->

          <h3>SAMPLE: altering with maths on [index] and within a array name</h3>
          <!-- <div class="inner-gallery"> -->
            <!-- <div class="row"> -->
              <!-- <div v-for="(item, key) in franchisedetail['field_images' + [index + 1]]" v-if="item.url" class="col-md"> -->
                <!-- <img :src="item.url" :alt="item.id" class="img-fluid"> -->
              <!-- </div> -->
            <!-- </div> -->
          <!-- </div> -->

        <!-- </div> -->
      <!-- </div> -->
    <!-- </section> -->

    <h3>SAMPLE: "if or" example & combine text with data in href</h3>
    <!-- <div v-for="(item, index) in samplePublicDetail.field_product"> -->
      <!-- <div v-if="item.title || item.field_main_description" class="text"> -->
        <!-- <a v-if="item.title" class="title" :href="'/product/' + item.topics_id"> -->
          <!-- {{ item.title }} -->
        <!-- </a> -->
      <!-- </div> -->
    <!-- </div> -->

    <inquirynow msg="Inquiry Now"/>

    <!-- test -->
    <!-- <div v-for="(item, index) in dataTokenPara" :key="index"> -->
      <!-- <h3>{{ item.pid }}: </h3> -->
      <!-- <div v-if="item.body" :inner-html.prop="item.body"></div> -->
    <!-- </div> -->

    <div v-for="(item, index) in dataToken" :key="index">
      <h2 :inner-html.prop="item.title"></h2>
      <div v-for="(para, paraindex) in dataTokenParaInner[index]">
        <h3 :inner-html.prop="para.title"></h3>
        <div class="body" :inner-html.prop="para.body"></div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'

// sample imported component
import inquirynow from '@/components/inquirynow.vue'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

import modal from '@/components/sample/modal.vue'
import modal2 from '@/components/sample/modal2.vue'

import slickImage1 from '@/assets/osboot_sample_texture_500x500__01.jpg'
import slickImage2 from '@/assets/osboot_sample_texture_500x500__02.jpg'
import slickImage3 from '@/assets/osboot_sample_texture_500x500__03.jpg'
import slickImage4 from '@/assets/osboot_sample_texture_500x500__04.jpg'

export default {
  name: 'Sample',
  // props: ['sampleId'],
  // created () {
  //   this.getRemoteFranchiseById(this.sampleId);
  // },
  data: function () {
    return {
      // samplePublicdetail: [],
      // sampleVideo: [],
      // samplePrivatedetail: [],
      dataToken: [],
      // dataTokenPara: [],
      dataTokenParaInner: [],
      last: [],
      slickOptions: {
        slidesToShow: 3,
        arrows: false
      },
      slickImage: [
        slickImage1,
        slickImage2,
        slickImage3,
        slickImage4
      ],
      // data: [item].
      isModalVisible: false,
      selectedModal: [],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    // getRemoteSampleById: function (id) {
    //   axios.get(`https:/urlhere/${id}`).then((dataPublicSource) => {
    //     this.samplePublicDetail = dataPublicSource.data.details;
    //     this.sampleVideo = dataPublicSource.data.details.field_video.url;
    //   });
    //   axios.get(`https:/urlhere/${id}`).then((dataPrivateSource) => {
    //     this.samplePrivatedetail = dataPrivateSource.data.details;
    //   });
    // },
    getData: function () {
      axios.get('http://trx-api.okie.my/api/general/chapter/all/section/all').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
        for (let i = 0; i < datasource.length; i++) {
          this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_timeline.replace(/\\/g, '')) );
        };

      });
    },
    showModal: function(e) {
      this.isModalVisible = true;
      // var valueABC = e.target.getAttribute('data-value');
      this.selectedModal = e.target.getAttribute('data-value');
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  components: {
    inquirynow,
    Slick,
    modal,
    modal2,
  },
  metaInfo() {
    return {
      title: 'Sample',
    }
  }
}
</script>