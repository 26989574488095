<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">June 2014</h2>
      <div class="text text-center">
        <div class="body">
          <p>Lendlease is the first to join TRX with a JV agreement signed for the Lifestyle Quarter</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <slick v-if="data_image" ref="slick" :options="slickOptionsP12014" class="milestones-year-slide-items">
              <div class="milestones-year-slide-item position-relative" v-for="(item, index) in data_image" :key="index">

                <div class="milestones-year-slide-img slide-img-only">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index" class="img-fluid">
                </div>

              </div>
            </slick>

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center">AUSTRALIAN COMPANY TO DEVELOP LIFESTYLE QUARTER</h3>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p><strong>Joint media release – Lend Lease and 1MDB</strong></p>
                  <p><strong>KUALA LUMPUR, 25 June 2014:</strong>&nbsp;The Tun Razak Exchange (TRX), Kuala Lumpur’s highly anticipated financial district, has chosen international property and infrastructure group Lend Lease as a major joint venture partner for part of its development.</p>
                  <p>1MDB Real Estate Sdn Bhd (1MDB RE) and Lend Lease have today signed a binding agreement to work exclusively together to finalise the joint venture documentation and to commence work on the TRX Lifestyle Quarter.</p>
                  It is intended that Australian headquartered Lend Lease together with 1MDB RE will develop the Lifestyle Quarter in TRX, a retail-led mixed-use development of over 17 acres comprising a hotel, three residential towers and a stunning new retail mall seamlessly connected to TRX’s multi-layer central park and a short stroll to the dedicated MRT station.</p>
                  <p>The development of the Lifestyle Quarter is expected to attract up to US$1 billion (RM3.2 billion) in foreign direct investments to Malaysia. Lend Lease will be the majority shareholder in the joint venture while 1MDB RE will hold a significant stake.</p>
                  <p>Lend Lease is an international group offering fully integrated services including development, project management, construction, and asset and property management.</p>
                  <p>Lend Lease was chosen from a pool of international and local bidders for the Lifestyle Quarter, following invitation in December 2013 to participate as a strategic development partner in TRX. The six-month international standard process managed by CB Richard Ellis encouraged innovative and value-added submissions, resulting in many leading edge and exciting proposals from global real estate investors and developers that would assist TRX to contribute towards the Government’s efforts to make Kuala Lumpur a global city.</p>
                  <p>“Attracting a global brand like Lend Lease is clear evidence of the potential and international appeal of TRX. We are very excited to move forward; a project of this scale will bring many spill-over benefits to the local economy,” said 1MDB Group CEO, Mohd Hazem Abd Rahman.</p>
                  <p>“Lend Lease’s concept of next generation retail is distinctive, and will be a landmark in Malaysia, integrating the retail and public realms, creating the right lifestyle for our vision of a liveable financial centre,” said Dato Azmar Talib, 1MDB RE CEO.</p>
                  <p>Apart from the compelling concept, Lend Lease also comes with a strong reputation, vast retail experience, and a track record of successful partnerships with governments around the globe. The company is a well-known sustainability and innovation champion, which is reflected in its proposed design for TRX.</p>
                  <p>Steve McCann, Lend Lease Group CEO said: “Lend Lease is delighted to have the opportunity to partner with 1MDB to transform TRX into a world class leading financial hub and an iconic destination globally. With our strong record of creating renowned master-planned urban communities, coupled with our strong financial position and global resources of around 13,700 professionals, we are confident of making this project an outstanding success with our partner.”</p>
                  <p>Globally, Lend Lease has played major roles in international cities such as New York, Chicago, London, Sydney and Melbourne. Significant urban regeneration projects by Lend Lease include Barangaroo South and Darling Harbour Live, both in Sydney, Australia; Elephant and Castle and the International Quarter, both in London, UK and Victoria Harbour in Melbourne, Australia.</p>
                  <p>Operating in Malaysia for over 30 years, Lend Lease has combined its international experience with a strong understanding of local Malaysian practices, regulatory requirements and market needs, leading to more than 100 projects including the iconic Petronas Twin Towers, Pinewood Iskandar Malaysia Studios, Setia City Mall and Platinum Park.</p>
                  <p>CEO for Lend Lease in Asia, Rod Leaver, added: “It is an honour to be presented with the opportunity to collaborate in this nation-defining development, leveraging Lend Lease’s global capabilities in partnering with national and city governments in major cities around the world to redevelop large scale urban regeneration projects.”</p>
                  <p>The TRX Lifestyle Quarter has a forecasted Gross Development Value (GDV) of over RM8 billion. Lend Lease and 1MDB RE have seen keen interest from new-to-market retail tenants and new-to-market international hotel brands.</p>
                  <p>During the selection process, 1MDB RE also saw strong interest from potential tenants for the commercial office towers. On the back of this encouraging market demand, 1MDB RE will concurrently be developing TRX’s first office tower and is expecting to announce several anchors in the near future.</p>
                  <p>TRX is due to name its partner for the development of plots dedicated to local developers soon. The bidding, conducted concurrently, has seen keen response from Malaysia’s leading real estate companies.</p>
                  <p>A 70-acre mixed-use development in the heart of Kuala Lumpur, TRX will encompass a new international financial and business district underpinned by world-class commercial, residential, retail, leisure and cultural offerings. It will also have a dedicated underground MRT station.</p>
                  <p>TRX has a development period of about 15 years, and will be built in demand-driven phases. The initial phase of construction, including the Lifestyle Quarter, is slated to commence in early 2015. TRX aims to be the best international business address in Kuala Lumpur and will bring together leading financial institutions and top global companies in one place, spurring growth in new areas for Malaysia to better compete on the global stage.</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

// import videoMemorandumMOV from '@/assets/ideation/milestone/phase2-2013/Investor_Memorandum_Video_Final.mp4'

import MileP21401 from '@/assets/ideation/milestone/phase2-2014/phase2-2014-01.jpg'
import MileP21402 from '@/assets/ideation/milestone/phase2-2014/phase2-2014-02.jpg'
import MileP21403 from '@/assets/ideation/milestone/phase2-2014/phase2-2014-03.jpg'
import MileP21404 from '@/assets/ideation/milestone/phase2-2014/phase2-2014-04.jpg'

export default {
  name: 'IdeationTimelinePhase22014',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      MileClose: MileClose,
      data_image: [
        // { items: [
          { 
            img: MileP21401,
            title: 'Adrian Smith + Gordon Gill Architecture'
          },
          { 
            img: MileP21402,
            title: 'Atkins'
          },
          { 
            img: MileP21403,
            title: 'BDP'
          },
          { 
            img: MileP21404,
            title: 'Broadway Malyan'
          }
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        // }
      ],
      slickOptionsP12014: {
        slidesToShow: 3,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>