<template>
  <div class="cosmopolitan-content" v-if="dataToken[2]">
    <div :style="styleBg"></div>

    <div class="position-relative">
      <div class="container">
        <div class="row">
          <div class="col-12 py-0">
            <div class="pwwrap pwstyle_02">
              <div class="text text-center">
                <h2 class="modal-title pfcolor_maroon" :inner-html.prop="dataToken[2].title"></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cosmopolitan-para" v-for="(para, paraindex) in dataTokenParaInner[2]" v-bind:class="{'pfbgcolor_pink': paraindex == 1}">
        <div class="cosmopolitan-para-container" v-bind:class="{'container': paraindex % 2 == 0}">
          <div class="para-content">
            <div class="container-fluid" v-if="para.field_title">
              <div class="pwwrap pwstyle_02">
                <h5 class="para-title text-center" :inner-html.prop="para.field_title"></h5>
                <img class="para-title-udl" :src="cosmoUDL" alt="underline">
              </div>
            </div>
            <div class="container-fluid" v-if="para.field_body">
              <div class="pwwrap pwstyle_01">
                <div class="body" :inner-html.prop="para.field_body"></div>
              </div>
            </div>
            <div class="container-fluid" v-if="para.field_image">
              <div class="cosmopolitan-image">
                <div class="container">
                  <div class="row">
                    <div class="col-md-10 offset-md-1" :style="styleBgImage">
                      <div class="para-image">
                        <img :src="apiBasePath + para.field_image" alt="main-image" class="img-fluid">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid" v-if="paraindex == 0">
              <slick v-if="dataSlide.length" ref="slick" :options="slickOptionsCityContrast" class="citycontrast-slide-items ">
                <div class="citycontrast-slide-item position-relative" v-for="(item, index) in dataSlide" :key="index">
                  <div class="citycontrast-slide-img">
                    <img v-if="item.cover_image" :src="apiBasePath + item.cover_image" :alt="'citycontrast-item-' + index" class="img-fluid">
                  </div>
                  <div class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                    <div class="text">
                      <div v-if="item.icon" class="icon"><img :src="apiBasePath + item.icon" alt="citycontrast-icon" class="img-fluid mx-auto"></div>
                      <h3 v-if="item.title" :inner-html.prop="item.title"></h3>
                      <div v-if="item.summary" class="body" :inner-html.prop="item.summary"></div>
                      <a v-if="item.link" class="pabutton_1 inverse" :href="item.link" target="_blank">Discover More</a>
                    </div>
                  </div>
                </div>
              </slick>
            </div>
            <div v-if="para.field_link" class="pwwrap pwstyle_01">
              <div class="para-link text-center">
                <a class="pabutton_1" :href="para.field_link" target="_blank">Read More <img :src="btnNewTabBlack" alt="btn-newtab"></a>
              </div>
            </div>
            <div v-if="para.field_images" class="cosmopolitan-images">
              <div v-if="paraindex == 1">
                <PolaGallery/>
              </div>
              <!-- <div v-else class="para-images">
                <img :src="apiBasePath + para.field_images" alt="main-image" class="img-fluid">
              </div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from "jquery";
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'
import cosmoBg03 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-popup-bg-03.jpg'
import cosmoUDL from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-underline.png'
import btnNewTabBlack from '@/assets/ico-newtab-black.png'
import imgPattern from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-02-image-pattern.png'
import PolaGallery from '@/components/the-making-of-trx/cosmopolitant-03-archi-pola.vue'


export default {
  name: 'cosmo02',
  components: {
    Slick,
    PolaGallery
  },
  data: function () {
    return {
      dataToken: [],
      dataSlide: [],
      dataTokenParaInner: [],
      apiBasePath: 'https://trx-api.okie.my/',
      apiBaseURL: 'https://trx-api.okie.my',
      slickLeft: slickLeft,
      slickRight: slickRight,
      cosmoUDL: cosmoUDL,
      btnNewTabBlack: btnNewTabBlack,
      imgPattern: imgPattern,
      slickOptionsCityContrast: {
        slidesToShow: 3,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
    }
  },
  mounted: function () {
    this.getData();
    
    window.onload = () => {
      var k, image;
      image = document.querySelectorAll(".polaroid-stacked-gallery .image");

      for (k = 0; k < image.length; k++) {
        var rotateDeg = randomRotate(30);
        image[k].style.transform =
          "translate(-50%, -50%) rotate(" + rotateDeg + "deg)";
        image[k].style.webkitTransform =
          "translate(-50%, -50%) rotate(" + rotateDeg + "deg)";
        image[k].style.MozTransform =
          "translate(-50%, -50%) rotate(" + rotateDeg + "deg)";
        console.log(rotateDeg);
      }

      // var cssTransition, images, queue, timeout, touch;
      var cssTransition, imageOffset, imageWidth, images, queue, timeout, touch;
      cssTransition = function () {
        var body, i, len, style, vendor, vendors;
        body = document.body || document.documentElement;
        style = body.style;
        vendors = ["Moz", "Webkit", "O"];
        if (typeof style["transition"] === "string") {
          return true;
        }
        for (i = 0, len = vendors.length; i < len; i++) {
          if (window.CP.shouldStopExecution(1)) {
            break;
          }
          vendor = vendors[i];
          if (typeof style[vendor + "Transition"] === "string") {
            return true;
          }
        }
        window.CP.exitedLoop(1);
        return false;
      };
      queue = false;
      touch = document.documentElement["ontouchstart"] !== void 0;
      images = document.querySelector(".polaroid-stacked-gallery .images");
      imageWidth = images.firstElementChild.offsetWidth;
      imageOffset = images.firstElementChild.offsetLeft;
      timeout = cssTransition() ? [300, 400] : [0, 0];
      images.addEventListener(
        touch ? "touchend" : "click",
        function (event) {
          var direction, lastClassList;
          if (queue) {
            return;
          }
          queue = true;
          if (
            (touch ? event.changedTouches[0].pageX : event.pageX) - imageOffset >
            imageWidth / 2
          ) {
            direction = "slide-right";
          } else {
            direction = "slide-left";
          }
          lastClassList = images.lastElementChild.classList;
          lastClassList.add(direction);
          return setTimeout(function () {
            lastClassList.remove(direction);
            lastClassList.add("back");
            return setTimeout(function () {
              images.insertBefore(
                images.lastElementChild,
                images.firstElementChild
              );
              lastClassList.remove("back");
              return (queue = false);
            }, timeout[0]);
          }, timeout[1]);
        },
        false
      );
    }
  },
  methods: {
    getData: function () {
      axios.get('https://trx-api.okie.my/api/general/chapter/1/section/14').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        this.dataTokenBg = 'https://trx-api.okie.my' + datasource[1].images.split(',')[0];
        for (let i = 0; i < datasource.length; i++) {
          this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_content) );
        };
      });
      axios.get('https://trx-api.okie.my/api/general/chapter/1/section/16').then(response => response.data).then(datasource => {
        this.dataSlide = datasource;
      });
    },
  },
  computed: {
    styleBg() {
      return {
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100%',
        'background-image': 'url(' + cosmoBg03 + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        // 'opacity': '.1',
        // 'filter': 'grayscale(100%)'
      }
    },
    styleBgImage() {
      return {
        'background-image': 'url(' + imgPattern + '), url(' + imgPattern + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'auto, auto',
        'background-position': 'bottom left, top right'
        // 'opacity': '.1',
        // 'filter': 'grayscale(100%)'
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
};
</script>

<style scoped>
.polaroid-stacked-gallery {
  height: 70vh;
  position: relative;
}

.polaroid-stacked-gallery .images {
  /* position: relative; */
  cursor: pointer;
}

.polaroid-stacked-gallery .image {
  position: absolute;
  object-fit: cover;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 300px;
  /* width: 30%; */
  /* margin-top: -110px; */
  /* margin-left: -205px; */
  -moz-box-shadow: 0 2px 5px rgba(30, 30, 30, 0.25);
  -webkit-box-shadow: 0 2px 5px rgba(30, 30, 30, 0.25);
  box-shadow: 0 2px 5px rgba(30, 30, 30, 0.25);
  z-index: 2;
  -moz-transition: -moz-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

/* .image:last-child {
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.images .image:nth-child(4) {
  -moz-transform: rotate(10deg) translateX(50px);
  -ms-transform: rotate(10deg) translateX(50px);
  -webkit-transform: rotate(10deg) translateX(50px);
  transform: rotate(10deg) translateX(50px);
}

.images .image:nth-child(3) {
  -moz-transform: rotate(3deg) translateX(75px);
  -ms-transform: rotate(3deg) translateX(75px);
  -webkit-transform: rotate(3deg) translateX(75px);
  transform: rotate(3deg) translateX(75px);
}

.images .image:nth-child(2) {
  -moz-transform: rotate(-2deg) translateX(-50px);
  -ms-transform: rotate(-2deg) translateX(-50px);
  -webkit-transform: rotate(-2deg) translateX(-50px);
  transform: rotate(-2deg) translateX(-50px);
}

.images .image:first-child {
  -moz-transform: rotate(-8deg) translateX(-75px) translateY(-10px);
  -ms-transform: rotate(-8deg) translateX(-75px) translateY(-10px);
  -webkit-transform: rotate(-8deg) translateX(-75px) translateY(-10px);
  transform: rotate(-8deg) translateX(-75px) translateY(-10px);
} */

/* .image.slide-right {
  -moz-transform: rotate(290deg) translateX(250px) !important;
  -ms-transform: rotate(290deg) translateX(250px) !important;
  -webkit-transform: rotate(290deg) translateX(250px) !important;
  transform: rotate(290deg) translateX(250px) !important;
}

.image.slide-left {
  -moz-transform: rotate(-290deg) translateX(-250px) !important;
  -ms-transform: rotate(-290deg) translateX(-250px) !important;
  -webkit-transform: rotate(-290deg) translateX(-250px) !important;
  transform: rotate(-290deg) translateX(-250px) !important;
} */

.polaroid-stacked-gallery .image.slide-right {
  -moz-transform: translate(-10%, -80%) !important;
  -ms-transform: translate(-10%, -80%) !important;
  -webkit-transform: translate(-10%, -80%) !important;
  transform: translate(-10%, -80%) !important;
}

.polaroid-stacked-gallery .image.slide-left {
  -moz-transform: translate(-80%, -80%) !important;
  -ms-transform: translate(-80%, -80%) !important;
  -webkit-transform: translate(-80%, -80%) !important;
  transform: translate(-80%, -80%) !important;
}

.polaroid-stacked-gallery .image.back {
  z-index: 1;
}

@media (max-width: 991px) {
  .polaroid-stacked-gallery .image {
    width: 80%;
    height: 50%;
  }

  .polaroid-stacked-gallery .image.slide-right,
  .polaroid-stacked-gallery .image.slide-left {
    -moz-transform: translate(-50%, -90%) !important;
    -ms-transform: translate(-50%, -90%) !important;
    -webkit-transform: translate(-50%, -90%) !important;
    transform: translate(-50%, -90%) !important;
  }
}
</style>
