import TrxTodayTomorrow from '@/views/trx-today-tomorrow.vue';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'
import Hello from '@/views/hello.vue'
// import TestTrx from '@/views/test_trx.vue'
import Sample from '@/views/sample.vue'
import Ideation from '@/views/ideation.vue'
import Commercialisation from '@/views/commercialisation.vue'
import ConstructionCollaboration from '@/views/construction-collaboration.vue';
import TigerMap from '@/views/tiger-map.vue'
import TigerMapTest from '@/views/tiger-map-test.vue'
// import Modal from '@/components/ideation/modal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sample',
    name: 'sample',
    component: Sample
  },
  {
    path: '/hello',
    name: 'hello',
    component: Hello
  },
  {
    path: '/ideation',
    name: 'Ideation',
    component: Ideation
  },
  {
    path: '/commercialisation',
    name: 'Commercialisation',
    component: Commercialisation
  },
  {
    path: '/construction-collaboration',
    name: 'Construction & Collaboration',
    component: ConstructionCollaboration,
  },
  {
    path: '/trx-today-tomorrow',
    name: 'TRX Today & Tomorrow',
    component: TrxTodayTomorrow,
  },
  {
    path: '/tigermap',
    name: 'TigerMap',
    component: TigerMap
  },
  {
    name: 'TigerMapTest',
    path: '/tigermaptest',
    component: TigerMapTest
  },
  // for detail page with custom id
  // {
  //   name: 'sample',
  //   path: '/sample/:id',
  //   component: sample,
  //   props: route => {return { sampleId: route.params.id }}
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router