<template>
  <section class="mt-5">
    <div class="row g-0">
      <div class="col-lg-5 col-left">
        <img
          class="food-table"
          :src="foodTable02"
        />
        <img
          class="hand"
          :src="foodHand"
        />
      </div>
      <div class="col-lg-7 col-right">
        <div class="card card-wrapper">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="slide in roSlide" :key="slide.title">
              <div class="row g-md-0">
                <div class="col-lg-7 card-body">
                  <div class="card-title">{{ slide.title }}</div>
                  <div class="card-text">{{ slide.text }}</div>
                  <div class="card-link">
                    <a class="pabutton_1" :href="slide.link" target="_blank">Read More <img :src="btnNewTabBlack" alt="btn-newtab"></a>
                  </div>
                </div>
                <div class="col-lg-5 card-image">
                  <img
                    :src="slide.image"
                    class="img-fluid"
                  />
                </div>
              </div>
            </swiper-slide>
            <div
              class="swiper-button-prev rotate-arrow"
              slot="button-prev"
            >Previous</div>
            <div
              class="swiper-button-next rotate-arrow"
              slot="button-next"
            >Next</div>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import foodTable from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmopolitan-sample-04-food-table.png'
import foodTable02 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmopolitan-sample-04-food-table-02.png'
import foodHand from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmopolitan-sample-04-food-hand.png'
import cover01 from '@/assets/the-making-of-trx/popup04/home-07-cosmopolitan-sample-04-food-01.jpg'
import cover02 from '@/assets/the-making-of-trx/popup04/home-07-cosmopolitan-sample-04-food-02.jpg'
import cover03 from '@/assets/the-making-of-trx/popup04/home-07-cosmopolitan-sample-04-food-03.jpg'
import cover04 from '@/assets/the-making-of-trx/popup04/home-07-cosmopolitan-sample-04-food-04.jpg'
import cover05 from '@/assets/the-making-of-trx/popup04/home-07-cosmopolitan-sample-04-food-05.jpg'
import cover06 from '@/assets/the-making-of-trx/popup04/home-07-cosmopolitan-sample-04-food-06.jpg'
import btnNewTabBlack from '@/assets/ico-newtab-black.png'

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        simulateTouch: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoHeight: true,
      },
      foodTable: foodTable,
      foodTable02: foodTable02,
      foodHand: foodHand,
      btnNewTabBlack: btnNewTabBlack,
      roSlide: [
        {
          title: "Borneo Delights Find a Home in KL",
          text:
            "East Malaysians have long complained about the lack of home " +
            "food options once they set foot in the Peninsular. With the " +
            "increased traffic in the last decade due to economic growth, " +
            "educational opportunities and the availability of low-cost " +
            "air travel, dining options for Sabah and Sarawak cuisine " +
            "have mushroomed in Kuala Lumpur.",
          image: cover01,
          link: "https://trx.my/city/borneo-delights-find-home-kl",
        },
        {
          title: "Merging Business with Leisure - KL's Workday Lunch Hotspots",
          text:
            "In a bustling metropolis like Kuala Lumpur, the workday week " +
            "is a serious and often demanding time. One of the best ways " +
            "to destress daily is by indulging during the lunch break " +
            "with the tasty treats on offer all across this gastronomic " +
            "capital.",
          image: cover02,
          link: "https://trx.my/city/merging-business-leisure-kls-workday-lunch-hotspots",
        },
        {
          title: "Mouth-Watering Regional Aidilfitri Delicacies",
          text:
            "Hari Raya Aidilfitri is a festive time marked by family, fun " +
            "and, crucially for Malaysians, food! With an eclectic mix of " +
            "heritages, our country is blessed with unique traditional " +
            "dishes that make their eagerly awaited appearance every " +
            "year.",
          image: cover03,
          link: "https://trx.my/city/mouth-watering-regional-aidilfitri-delicacies",
        },
        {
          title: "Food Truck Haven",
          text:
            "Malaysians love food, and we keep creating avenues for easy access to food " +
            "anytime of the day. One food trend in KL that is cresting now is the food truck. " +
            "The relatively low capital requirement and endless possibilities for location " +
            "and target group allows young Malaysians to dive into the food truck " +
            "business, bringing with them fresh new twist on food.",
          image: cover04,
          link: "https://trx.my/city/food-truck-haven-0",
        },
        {
          title: "Syncretic Story of Kuih Reflects Multicultural Region",
          text:
            "Kuih are most iconically associated with religious and cultural " +
            "festivals such as Hari Raya Aidilfitri, Chinese New Year and Deepavali, " +
            "but they are also a popular everyday food and the go-to dishes to " +
            "serve to guests all year round. " +
            "The word kuih has Chinese origins, from the Hokkien character 粿, " +
            "pronounced “kway”, while Cantonese speakers also have a similar word. ",
          image: cover05,
          link: "https://trx.my/city/syncretic-story-kuih-reflects-multicultural-region",
        },
        {
          title: "Ramadan Bazaars Around KL",
          text:
            "The air is always festive with spectacular array of food during " +
            "the blessed fasting month of Ramadan in Malaysia. Daily Ramadan " +
            "food bazaars sprout all over the place under colourful parasols " +
            "selling sweet and savoury dishes. For thirty days, endless selections " +
            "of mouth-watering delicacies attract crowds from every race and nationality.",
          image: cover06,
          link: "https://trx.my/city/ramadan-bazaars-around-kl",
        },
      ],
    };
  },
  mounted() {
    var index = 0;
    $(".swiper-button-next.rotate-arrow").click(function () {
      $(".food-table").css({
        transform: "rotate(" + (index + 60) + "deg)",
        transition: "all 1s ease-in-out",
      });
      index += 60;
    });
    $(".swiper-button-prev.rotate-arrow").click(function () {
      $(".food-table").css({
        transform: "rotate(" + (index - 60) + "deg)",
        transition: "all 1s ease-in-out",
      });
      index -= 60;
    });
  },
};
</script>

<style scoped>
.col-left {
  position: relative;
}
.food-table {
  position: absolute;
  right: 10%;
  top: 0%;
  /* width: 100%;
  height: 100%; */
}

.hand {
  position: absolute;
  right: -15%;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1199px) {
  .hand { right: -20%; }
  .food-table { top: 5%; }
}
@media (max-width: 991px) {
  .col-left { display: none; }
}

.col-right {
  padding: 150px 20px 0px 20px;

  @media(max-width: 991px) {
    padding: 0px 40px 0px 40px;
  }
  @media(max-width: 767px) {
    padding: 0;
  }
}

.swiper {
  height: auto;
  width: 100%;
}

.card.card-wrapper {
  padding: 0px 30px 40px 30px;

  @media(max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.card-body {
  margin-top: 80px;
}

.card-text {
  margin-bottom: 30px;
}

.card-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  font-weight: bold;
  color: purple;
}

.card-link a {
  margin-left: 0;
}

/* .card-image-wrapper {
  position: relative;
} */

/* .card-image {
  position: relative;
  overflow: visible;
}

.card-image > img {
  position: absolute;
  top: -100px;
  left: 0px;
  overflow: visible;
} */


.swiper-button-prev {
  /*background-image: url('~@/assets/the-making-of-trx/home-07-cosmopolitan/arrow-left.svg');*/
  left: 2.5rem;
  color: #800080;
}
.swiper-button-next {
  /*background-image: url('~@/assets/the-making-of-trx/home-07-cosmopolitan/arrow-right.svg');*/
  left: 7rem;
  color: #800080;
}

.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  top: 50px;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

@media (max-width: 991px) {
  .g-md-0 {
    flex-direction: column-reverse;
  }
  .card-title {
    font-size: 24px;
  }
  .card-body {
    margin-top: 0;
  }
  .card-image {
    margin-top: 80px;
  }
  .card-image img {
    display: block;
    margin: 0 auto;
  }
}
</style>