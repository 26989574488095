<template>
  <b-modal modal-class="areatip-modal"
      :id="item.nid" title-sr-only hide-header
      hide-footer no-stacking size="default">
    <template #default="{ hide, close }">
      <div class="areatip-close">
        <a class="btn-close" @click="close()">
          <img :src="closeButton" alt="mile-close-modal">
        </a>
      </div>
      <div class="area-tip-content" :style="`background-color:${themeColor}`">
        <div :style="styleBg"></div>
        <div class="container">
          <div class="row">
            <div class="col-12 py-0">
              <div class="text" :style="`color:${textColor}`">
                <h2 class="modal-title" :style="`color:${textColor}`"><img
                    :src="locationIcon"
                    alt="pin">{{ item.title }}</h2>
              </div>
            </div>
            <div class="col-lg-5 col-md-6" v-if="item.body">
              <div class="text" :style="`color:${textColor}`">
                <div class="body" :inner-html.prop="item.body"></div>
              </div>
            </div>
            <div class="col-lg-7 col-md-6" v-if="item.images">
              <swiper class="swiper area-slide-items pswiperstyle_01" ref="mySwiper"
                  :options="SwiperOptionsAreaTip">
                <swiper-slide v-for="(image, index) in item.images.split(',')" :key="index"
                    class="area-slide-item">
                  <div class="area-slide-img">
                    <img v-if="image" :src="apiBasePath + image" :alt="'area-image-' + index"
                        class="img-fluid">
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"><img
                    src="@/assets/arrow-prev-transparent.png"></div>
                <div class="swiper-button-next" slot="button-next"><img
                    src="@/assets/arrow-next-transparent.png"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {SwiperSlide} from 'vue-awesome-swiper';

export default {
  name: 'ModalGallery',
  props: ['item', 'theme'],
  data() {
    return {
      SwiperOptionsAreaTip: {
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      apiBasePath: 'https://trx-api.okie.my/',
    };
  },
  components: {SwiperSlide},
  computed: {
    styleBg() {
      return {
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100%',
        'background-repeat': 'no-repeat',
        'background-image': `url(${this.apiBasePath + this.item?.images.split(',')[0]})`,
        'background-size': 'cover',
        'opacity': '.1',
        'filter': 'grayscale(100%)',
      };
    },
    themeColor() {
      return this.theme === 'orange' ? '#F5DB7C' : '';
    },
    textColor() {
      return this.theme === 'orange' ? '#000' : '';
    },
    closeButton() {
      return this.theme === 'orange' ? require('@/assets/ico-milestones-modal-close-orange.png') : '';
    },
    locationIcon() {
      return this.theme === 'orange' ? require('@/assets/ico-location-orange.png') : '';
    },
  },
};
</script>