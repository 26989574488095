<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">August 2017</h2>
      <div class="text text-center mb-3">
        <div class="body">
          <p>Prudential is relocating to its new HQ, a 27-storey building in TRX</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center mb-3">PRUDENTIAL TO RELOCATE TO NEW HQ IN TRX</h3>
            </div>

            <div class="pwwrap pwstyle_02 mb-3">
              <video controls width="100%">
                <source :src="videoCity">
              </video>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p>Prudential will be relocating its headquarters to a 27-storey building in the upcoming Tun Razak Exchange, Malaysia’s first dedicated international financial district. Slated for opening by 2019, the building will house all of Prudential’s life insurance and asset management businesses in Malaysia under one roof.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

import videoCity from '@/assets/ideation/milestone/phase2-2017-02/TRX_City_Welcome_Prudential_TRX.mp4'

export default {
  name: 'IdeationTimelinePhase2201702',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      videoCity: videoCity,
      MileClose: MileClose,
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>