<template>
  <div>
    <div class="brandvideo position-relative">
      <div class="brandvideo-bg position-relative d-block" data-aos="zoom-out"
          data-aos-easing="ease-out-back">
        <!-- <img :src="styleBgBrandVid" alt="banner-brandvideo" class="img-fluid" width="100%"> -->
        <video playsinline="playsinline" id="brandVideo"/>
        <img :src="overlayImage" alt="banner-brandvideo-overlay" class="img-fluid"
            width="100%">
      </div>
      <div
          class="brandvideo-text position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
        <div class="pwwrap pwstyle_01">
          <div class="text text-center text-white" data-aos="fade-down">
            <h2 class="content-title pfcolor_white">{{ title }}</h2>
            <a class="btn-modal" v-b-modal.brandVideoModal>
              <img :src="require('@/assets/video-play-ico-transparent.png')" alt="icon-brand-video">
              <p>Watch Video</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="brandVideoModal" centered hide-footer no-stacking size="xl">
      <template #modal-header="{ close }">
        <b-button size="default" class="btn-close" @click="close()"></b-button>
      </template>
      <div class="ratio ratio-16x9">
        <iframe v-if="fullYtVid" width="100%" :src=fullYtVid autoplay="0" playsinline="0"></iframe>
        <video v-else autoplay controls class="w-100" id="brandVideo">
          <source :src="fullLocalVid" type="video/mp4">
        </video>
      </div>
    </b-modal>
  </div>
</template>
<script>

export default {
  name: 'PopupVideo',
  props: {
    title: {},
    overlayImage: {},
    previewVid: {},
    fullLocalVid: {},
    fullYtVid: {},
  },
  mounted() {
    this.$nextTick(() => {
      // set playsInline attribute to video #brandVideo
      // Important: to not allow iOS safari to auto play it in it's own popup.
      const brandVideo = document.getElementById('brandVideo');
      brandVideo.playsInline = true;
      brandVideo.autoplay = true;
      brandVideo.muted = true;
      brandVideo.loop = true;
      brandVideo.src = this.previewVid;
    });
  },
};
</script>
