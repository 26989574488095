<template>
  <div class="page page-ideation">

    <!-- <div :style="styleThis"> -->

    <!-- <HelloWorld class="text-center" msg="Welcome to Your Vue.js App"/> -->

    <div class="banner position-relative">
      <div class="banner-image position-relative d-block" data-aos="zoom-out">
        <img :src="styleBanner" alt="banner-ideation" class="img-fluid" width="100%">
      </div>
      <div
          class="banner-content position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
        <div class="pwwrap pwstyle_01">
          <div class="text text-center text-white">
            <!-- <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">Ideation</h1> -->
            <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back"
                data-aos-duration="800">Ideation</h1>
            <img :src="styleUDFooter" alt="underline-footer" class="img-fluid" width="100%"
                data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">
            <div class="body" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"
                data-aos-delay="400" data-aos-duration="800">
              <p>How do you build an international financial centre for a new century that does not
                just serve as an economic centre but will address all modern challenges of
                sustainable development, seamless accessibility, and a balanced lifestyle?</p>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-next position-absolute text-center bottom-0 start-50 translate-middle-x"
          data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
        <a class="banner-scroll">
          <p>Explore TRX</p>
          <!-- <img :src="styleBannerNext" alt="banner-next-arrow"> -->
          <div class="mouse"></div>
        </a>
      </div>
    </div>

    <!-- content -->
    <div class="ideation content-ideation">

      <div class="content-bg-list">
        <!-- <div class="cr-09 content-bg pfbgcolor_white"><div></div></div> -->
        <div class="cr-08 content-bg pfbgcolor_white">
          <div></div>
        </div>
        <div class="cr-07 content-bg pfbgcolor_bluelight">
          <div></div>
        </div>
        <div class="cr-06 content-bg pfbgcolor_bluedarker">
          <div></div>
        </div>
        <div class="cr-05 content-bg">
          <div></div>
        </div>
        <div class="cr-04 content-bg pfbgcolor_white">
          <div></div>
        </div>
        <div class="cr-03 content-bg pfbgcolor_white">
          <div></div>
        </div>
        <div class="cr-02 content-bg" :style="styleIntro"></div>
      </div>

      <section class="content-row" id="cr-01">
        <div class="intro" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p><span class="pfstyle_s64">A</span>fter detailed studies and thoughtful
                    planning, we arrived at a model that can catalyse the economy, offer
                    round-the-clock lifestyle options, while maintaining sustainability standards.
                  </p>
                </div>
                <blockquote>The Heart of KL. The Best Business Address. <br>The Workplace of the
                  Future.
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-02">
        <div class="explore" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text text-center">
                <div class="body">
                  <h2 class="content-title pfcolor_bluedark">Explore TRX</h2>
                  <!-- <p class="pfcolor_grey">Click on the map to discover what’s in the area.</p> -->
                </div>
              </div>
            </div>
            <div class="area-tip" data-aos="zoom-in" data-aos-easing="easeOutBack">
              <div class="tip-wrap position-relative">
                <div class="tip-map">
                  <img :src="styleExplore" alt="area-map" class="img-fluid tip-map-desktop">
                  <img :src="styleExploreMobile" alt="area-map" class="img-fluid tip-map-mobile">
                </div>
                <div class="tip-clickover"></div>
                <div class="tip-overflow"></div>
                <div class="tip-list position-absolute start-0 top-0 bottom-0 end-0">
                  <div class="tip-item" v-for="(item, index, id) in dataTokenArea" :key="index"
                      :id="'tip0' + [index + 1]">
                    <!-- <div v-for="(para, paraindex) in dataTokenParaInner[index]">
                      <h3 :inner-html.prop="para.title"></h3>
                      <div class="body" :inner-html.prop="para.body"></div>
                    </div> -->
                    <!-- {{ 'areaTip0' + item.nid + 'Modal' }} -->
                    <a v-if="item.body || item.images" class="btn-modal"
                        v-b-modal="'areaTip0' + item.nid + 'Modal'">
                      <h5 :inner-html.prop="item.title"></h5>
                    </a>
                    <div v-else>
                      <h5 :inner-html.prop="item.title"></h5>
                    </div>
                  </div>
                </div>
              </div>
              <ol class="tip-guide">
                <li v-for="(item, index, id) in dataTokenArea" :key="index">
                  <a v-if="item.body || item.images" class="btn-modal"
                      v-b-modal="'areaTip0' + item.nid + 'Modal'">
                    <h5 :inner-html.prop="item.title"></h5>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-03">
        <div class="keyfeature pfbgcolor_bluelight">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text text-center" data-aos="fade-down">
                <div class="body">
                  <h2 class="content-title pfcolor_bluedark">Key Features of TRX</h2>
                </div>
              </div>
            </div>
            <div class="tab-wrap">

              <!-- <div class="tab-header"> -->
              <!-- <div class="nav nav-tabs d-flex justify-content-around" id="nav-tab" role="tablist"> -->
              <!-- <button v-for="(item, key) in styleKeyFeaIco"  -->
              <!-- class="nav-link"  -->
              <!-- v-bind:class="{ 'active': key == '0' }" -->
              <!-- :id="'kf0' + [key + 1]"  -->
              <!-- data-bs-toggle="tab"  -->
              <!-- :data-bs-target="'#nav-kf0' + [key + 1]"  -->
              <!-- type="button"  -->
              <!-- role="tab"  -->
              <!-- :aria-controls="'nav-kf0' + [key + 1]"  -->
              <!-- :aria-selected="key == '0' ? 'true' : 'false'"  -->
              <!-- :style="styleKF(key)"> -->
              <!-- <img v-if="styleKeyFeaIco[key]" :src="styleKeyFeaIco[key]" class="img-fluid"> -->
              <!-- </button> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- <div class="tab-content"> -->
              <!-- <div v-for="(item, key) in styleKeyFea"  -->
              <!-- class="tab-pane fade"  -->
              <!-- v-bind:class="{ 'show active': key == '0' }" -->
              <!-- :id="'nav-kf0' + [key + 1]"  -->
              <!-- role="tabpanel"  -->
              <!-- :aria-labelledby="'kf0' + [key + 1]" > -->
              <!-- <div v-for="(item, key) in styleKeyFea"> -->
              <slick v-if="dataToken.length" ref="slick" :options="slickOptions"
                  class="kf-slide-items kf01-images">
                <div class="kf-slide-item kf01-image position-relative"
                    v-for="(keyfeaitem, index, id) in dataToken" :key="index"
                    v-if="Number(keyfeaitem.section_id) === 6">
                  <ItemInfoCardA :title="keyfeaitem.title" :link="keyfeaitem.link"
                      :image-url="apiBasePath + keyfeaitem.cover_image"/>
                </div>
              </slick>
              <!-- </div> -->
              <!-- </div> -->
              <!-- <div class="tab-pane fade show active" id="nav-kf01" role="tabpanel" aria-labelledby="kf01">...</div> -->
              <!-- <div class="tab-pane fade" id="nav-kf02" role="tabpanel" aria-labelledby="kf02">...</div> -->
              <!-- <div class="tab-pane fade" id="nav-kf03" role="tabpanel" aria-labelledby="kf03">...</div> -->
              <!-- <div class="tab-pane fade" id="nav-kf04" role="tabpanel" aria-labelledby="kf04">...</div> -->
              <!-- <div class="tab-pane fade" id="nav-kf05" role="tabpanel" aria-labelledby="kf05">...</div> -->
              <!-- </div> -->

            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-04">
        <PopupVideo :overlay-image="styleBgVidBrandOverlay"
            :preview-vid="styleBgVidBrandVid"
            full-yt-vid="https://www.youtube.com/embed/a_l1a0b30-E"
            title="Asia's Newest International Financial
              District"/>
      </section>

      <section class="content-row pfbgcolor_bluedarker_fade" id="cr-05">
        <!--        <div class="benchmarking text-white" :style="styleBench" data-aos="fade-down">-->
        <div class="benchmarking text-white" data-aos="fade-down">
          <div class="container">
            <div class="row">
              <div class="col-12 py-0">
                <div class="text">
                  <h2 class="content-title pfcolor_white">Benchmarking Top IFCs</h2>
                </div>
              </div>
              <div class="col-lg-4 col-md-5">
                <div class="text">
                  <div class="body">
                    <p><span class="pfstyle_s64">I</span>n 2010, the Malaysian government unveiled
                      the Economic Transformation Programme and part of this initiative was to
                      establish a dedicated financial district.</p>
                    <p>TRX was conceptualised as an ecosystem of financial institutions,
                      multinational companies and ancillary businesses to boost Kuala Lumpur’s
                      position as one of the leading financial hubs in the region.</p>
                    <p>To ensure that we develop a financial district of global standards, we
                      benchmarked against the world’s best international financial centres. </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-7">
                <slick v-if="dataToken.length" ref="slick" :options="slickOptionsBench"
                    class="bench-slide-items">
                  <div class="bench-slide-item position-relative"
                      v-for="(image, index, id) in dataToken" :key="index"
                      v-if="image.section_id == 13">
                    <div v-if="data_benchmarking[1]">
                      <div class="bench-slide-img">
                        <img v-if="image.cover_image" :src="apiBasePath + image.cover_image"
                            :alt="'bench-image-' + index" class="img-fluid">
                      </div>
                      <div
                          class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                        <div class="text">
                          <!-- <div v-if="image.icon" class="icon"><img :src="apiBasePath + image.icon" alt="bench-icon" class="img-fluid mx-auto"></div> -->
                          <h3 v-if="image.title" :inner-html.prop="image.title"></h3>
                          <div v-if="image.summary" class="body"
                              :inner-html.prop="image.summary"></div>
                          <a class="pabutton_1 inverse" :href="image.link" target="_blank">Discover
                            More <img :src="btnNewTab" alt="btn-newtab"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </slick>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-06">
        <div class="location">
          <div class="container">
            <div class="pwwrap pwstyle_01" data-aos="fade-down">
              <div class="text">
                <h2 class="content-title pfcolor_bluedark text-center">Getting the<br>Right Location
                </h2>
                <div class="body">
                  <p><span class="pfstyle_s48">F</span>inding a prime location for an international
                    financial district is of great importance. Imbi was chosen for its strategic
                    location as part of KL’s golden triangle and because, compared to its neighbours
                    it was relatively underdeveloped.</p>
                </div>
              </div>
            </div>
            <div class="pwwrap pwstyle_01" data-aos="fade-down">
              <div class="text">
                <h3 class="content-title pfcolor_bluebright text-center">Gallery of Former TRX
                  Site</h3>
              </div>
            </div>
            <div class="gallery-former">
              <!-- <swiper class="swiper swiper-auto gallery-images" ref="mySwiper" :options="swiperOptionsGallery">
                <swiper-slide v-for="(item, index, id) in dataToken" :key="index" v-if="item.section_id == 12" class="gallery-image">
                  <div class="image-wrapper mx-auto">
                    <img v-if="item.cover_image" :src="apiBasePath + item.cover_image" :alt="'gallery-image-' + index" class="img-fluid">
                  </div>
                </swiper-slide>
              </swiper> -->
              <slick v-if="dataToken.length" ref="slick" :options="slickOptionsGallery"
                  class="gallery-images">
                <div v-for="(item, index, id) in dataToken" :key="index"
                    v-if="item.section_id == 12" class="gallery-slide-item">
                  <div class="gallery-slide-img">
                    <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                        :alt="'gallery-image-' + index" class="img-fluid">
                  </div>
                </div>
              </slick>

            </div>
            <div class="pwwrap pwstyle_01" data-aos="fade-down">
              <div class="text">
                <div class="body">
                  <p>The site is strategically located at the Southern Gateway to Kuala Lumpur at
                    the crossroads of Jalan Tun Razak and Jalan Sultan Ismail. Its proximity to
                    other landmark commercial areas such as KLCC and Bukit Bintang shopping belt, as
                    well as direct links to major highways including one that connects to the
                    airport, makes Imbi the perfect choice.</p>
                  <p>Aside from this, Imbi has a rich culture that makes it distinctively KL. It is
                    a vibrant district with local street food, bustling commerce, and a colourful
                    history.</p>
                </div>
              </div>
            </div>
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <h3 class="content-title pfcolor_bluebright text-center">Good Eats in Imbi</h3>
              </div>
            </div>
            <div class="gallery-imbi">
              <!-- << temporary -->
              <!-- <div class="row"> -->
              <!-- - -->
              <!-- <div v-if="data_gallery_imbi[0]" class="gallery-imbi-image position-relative col-md-4" v-for="(image, index) in data_gallery_imbi[0].items"> -->
              <!-- <img v-if="image.url" :src="image.url" :alt="'gallery-imbi-image-' + index"> -->
              <!-- <div class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0"> -->
              <!-- <div class="text text-center"> -->
              <!-- <div v-if="image.icon" class="icon"><img :src="data_gallery_imbi_icon" alt="gallery-imbi-icon" class="img-fluid"></div> -->
              <!-- <h3 v-if="image.title" :inner-html.prop="image.title"></h3> -->
              <!-- <div v-if="image.desc" class="body">{{ image.desc }}</div> -->
              <!-- <a>Discover More <img :src="btnNewTab" alt="btn-newtab"></a> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- << temporary -->
              <!-- </div> -->
              <!-- - -->

              <div class="container">
                <div class="pwwrap pwstyle_03">
                  <swiper :effect="'cards'" :grabCursor="true" class="swiper gallery-imbi-images"
                      ref="mySwiper" :options="swiperOptionsGalleryImbi">
                    <swiper-slide v-for="(item, index, id) in dataToken" :key="index"
                        v-if="item.section_id == 11">
                      <a :href="item.link" class="gallery-imbi-card" target="_blank">
                        <div class="gallery-imbi-image">
                          <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                              :alt="'gallery-relo-image-' + index" class="img-fluid">
                        </div>
                        <div
                            class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 justify-content-center">
                          <div class="text text-center">
                            <div v-if="item.icon" class="icon"><img :src="data_gallery_imbi_icon"
                                alt="gallery-imbi-icon" class="img-fluid"></div>
                            <h3 v-if="item.title" :inner-html.prop="item.title"></h3>
                            <div v-if="item.summary" class="body">{{ item.summary }}</div>
                            <div v-if="item.link" class="pabutton_1 inverse">Discover More <img
                                :src="btnNewTab" alt="btn-newtab"></div>
                          </div>
                        </div>
                      </a>
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"><img :src="slickLeft"></div>
                    <div class="swiper-button-next" slot="button-next"><img :src="slickRight"></div>
                  </swiper>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section class="content-row" id="cr-07">
        <div class="relocation" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <h2 class="content-title pfcolor_bluedark text-center">Relocation</h2>
                <div class="body">
                  <p><span class="pfstyle_s48">E</span>xisting buildings in the area were relocated
                    and replaced with new modern facilities. One of which was the Pasar Baru Bukit
                    Bintang or Imbi Market, which is now in the Integrated Commercial Complex, Pudu.
                    This was the first of many initiatives that started the rejuvenation of the
                    neighbourhood.</p>
                </div>
              </div>
            </div>
            <div class="gallery-relo">
              <slick v-if="dataToken.length" ref="slick" :options="slickOptionsGalleryRelo"
                  class="gallery-relo-images">
                <div v-for="(item, index, id) in dataToken" :key="index"
                    v-if="item.section_id == 10" class="gallery-relo-slide-item">
                  <div class="gallery-relo-slide-img">
                    <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                        :alt="'gallery-relo-image-' + index" class="img-fluid">
                  </div>
                </div>
              </slick>
              <!-- <swiper class="swiper swiper-auto gallery-relo-images" ref="mySwiper" :options="swiperOptionsGalleryRelo">
                <swiper-slide v-for="(item, index, id) in dataToken" :key="index" v-if="item.section_id == 10">
                  <div class="image-wrapper mx-auto">
                    <img v-if="item.cover_image" :src="apiBasePath + item.cover_image" :alt="'gallery-relo-image-' + index" class="img-fluid">
                  </div>
                </swiper-slide>
              </swiper> -->
            </div>
            <div class="text text-center">
              <a class="pabutton_1" href="https://trx.my/city/city/imbi-market-now-icc-pudu"
                  target="_blank">Discover More <img :src="btnNewTabBlack" alt="btn-newtab"></a>
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-08">
        <div class="milestones pfbgcolor_bluelight" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text text-center">
                <h2 class="content-title pfcolor_bluedark text-center">Timeline of Milestones</h2>
                <div class="body">
                  <p>View the milestones reached throughout the project.</p>
                </div>
              </div>
            </div>
            <div class="milestone-wrap">
              <div class="milestones-timeline">
                <div class="row justify-content-center">
                  <div class="milestone-item col-lg-4 col-md-6"
                      v-for="(item, index, id) in dataToken"
                      v-if="item.section == 'Timeline of Milestones' && item.section_id == 9 && item.nid != 8"
                      :key="id">
                    <div>
                      <div class="milestone-item-wrap position-relative">
                        <div class="milestone-image">
                          <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                              :alt="'milestone-item-' + index" class="img-fluid">
                        </div>
                        <div
                            class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 justify-content-center">
                          <div class="text text-center text-white">
                            <div v-if="item.icon" class="icon"><img :src="apiBasePath + item.icon"
                                alt="gallery-imbi-icon" class="img-fluid"></div>
                            <h5 v-if="item.nid == 10">Phase 1</h5>
                            <h5 v-else-if="item.nid == 9">Phase 2</h5>
                            <!-- <h3 v-if="item.title" :inner-html.prop="item.title"></h3> -->
                            <h3 v-if="item.nid == 10">Conceptualisation<br>&<br>Commencement</h3>
                            <h3 v-else-if="item.nid == 9">Commercialisation<br>&<br>Construction
                            </h3>
                            <a class="btn-modal pabutton_1 inverse"
                                v-b-modal="'milestonesPhase' + item.nid + 'Modal'">Read More</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <div class="content-modal">

      <div class="areatip-modal-wrap" v-for="(item, index, id) in dataToken" :key="index"
          v-if="item.section_id == 8 && ( item.body || item.images)">
        <b-modal modal-class="areatip-modal" id="areaTip06Modal"
            v-if="item.nid == 6 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip01/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip035Modal"
            v-else-if="item.nid == 35 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip02/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip036Modal"
            v-else-if="item.nid == 36 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip03/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip038Modal"
            v-else-if="item.nid == 38 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip04/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip039Modal"
            v-else-if="item.nid == 39 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip05/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip040Modal"
            v-else-if="item.nid == 40 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip06/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip052Modal"
            v-else-if="item.nid == 52 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip07/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip053Modal"
            v-else-if="item.nid == 53 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip08/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip054Modal"
            v-else-if="item.nid == 54 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip09/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip055Modal"
            v-else-if="item.nid == 55 && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip10/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip056Modal"
            v-else-if="item.nid == 56  && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip11/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip057Modal"
            v-else-if="item.nid == 57  && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip12/>
          </template>
        </b-modal>
        <b-modal modal-class="areatip-modal" id="areaTip058Modal"
            v-else-if="item.nid == 58  && ( item.body || item.images)" title-sr-only hide-header
            hide-footer no-stacking size="default">
          <template #default="{ hide, close }">
            <div class="areatip-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <areaTip13/>
          </template>
        </b-modal>
      </div>

      <b-modal modal-class="milestones-modal" id="milestonesPhase10Modal" title-sr-only hide-header
          hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <modalITL1/>
            <div class="container-fluid milestones-next">
              <div class="row justify-content-center">
                <div class="milestone-item col-lg-4 col-md-6" v-for="(item, index, id) in dataToken"
                    :key="index" v-if="item.nid == 9">
                  <a class="btn-modal" v-b-modal="'milestonesPhase9Modal'">
                    <div class="milestone-item-wrap position-relative">
                      <div class="milestone-image">
                        <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                            :alt="'milestone-item-' + index" class="img-fluid">
                      </div>
                      <div
                          class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 justify-content-center">
                        <div class="text text-center text-white">
                          <div v-if="item.icon" class="icon"><img :src="apiBasePath + item.icon"
                              alt="gallery-imbi-icon" class="img-fluid"></div>
                          <h5>Phase 2</h5>
                          <h3>Commercialisation<br>&<br>Construction</h3>
                          <div class="pabutton_1 inverse">Read More</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal" id="milestonesPhase9Modal" title-sr-only hide-header
          hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <modalITL2/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase1inner0Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase10Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase1inner0/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase1inner1Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase10Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase1inner1/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase1inner2Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase10Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase1inner2/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase1inner3Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase10Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase1inner3/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner0Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner0/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner1Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner1/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner2Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner2/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner3Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner3/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner4Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner4/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner5Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner5/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesPhase2inner6Modal"
          title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_bluedarker">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesPhase9Modal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <phase2inner6/>
          </div>
        </template>
      </b-modal>

    </div>

  </div>
</template>

<script>
import slickRight from '@/assets/arrow-next-transparent.png';
import slickLeft from '@/assets/arrow-prev-transparent.png';
// import styleImage from '@/assets/osboot_sample_texture_500x500__01.jpg'
import styleBgFooter from '@/assets/bg-footer.svg';

import MileClose from '@/assets/ico-milestones-modal-close.png';
import btnNewTabBlack from '@/assets/ico-newtab-black.png';
import btnNewTab from '@/assets/ico-newtab.png';
import styleBannerNext from '@/assets/ideation/ideation-01-banner-arrow.png';

import styleBanner from '@/assets/ideation/ideation-01-banner.jpg';
import styleBgIntro2 from '@/assets/ideation/ideation-02-bg-intro-transparent.png';
import styleBgIntro from '@/assets/ideation/ideation-02-bg-intro.jpg';
import styleExploreMobile from '@/assets/ideation/ideation-03-explore-mobile.jpg';
import styleExplore from '@/assets/ideation/ideation-03-explore.jpg';

import styleKeyFea1 from '@/assets/ideation/ideation-04-keyfeature-sample-01.jpg';
import styleKeyFea2 from '@/assets/ideation/ideation-04-keyfeature-sample-02.jpg';
import styleKeyFea3 from '@/assets/ideation/ideation-04-keyfeature-sample-03.jpg';
import styleKeyFea4 from '@/assets/ideation/ideation-04-keyfeature-sample-04.jpg';
import styleKeyFea5 from '@/assets/ideation/ideation-04-keyfeature-sample-05.jpg';
import styleKeyFea6 from '@/assets/ideation/ideation-04-keyfeature-sample-06.jpg';
import styleKeyFeaIcoBlue1
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-01-blue.svg';
import styleKeyFeaIco1
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-01-grey.svg';
import styleKeyFeaIcoBlue2
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-02-blue.svg';
import styleKeyFeaIco2
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-02-grey.svg';
import styleKeyFeaIcoBlue3
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-03-blue.svg';
import styleKeyFeaIco3
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-03-grey.svg';
import styleKeyFeaIcoBlue4
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-04-blue.svg';
import styleKeyFeaIco4
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-04-grey.svg';
import styleKeyFeaIcoBlue5
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-05-blue.svg';
import styleKeyFeaIco5
  from '@/assets/ideation/ideation-04-keyfeature/ideation-04-keyfeature-ico-05-grey.svg';
import styleBgVidBrandOverlay from '@/assets/ideation/ideation-05-bg-brandvideo-overlay.png';
import styleBgVidBrandVid from '@/assets/ideation/ideation-05-bg-brandvideo-video.mp4';

import styleBgBrandVid from '@/assets/ideation/ideation-05-bg-brandvideo.jpg';
import styleBenchIco1 from '@/assets/ideation/ideation-06-benchmarking-ico-01.png';
import styleBenchIco2 from '@/assets/ideation/ideation-06-benchmarking-ico-02.png';
import styleBenchIco3 from '@/assets/ideation/ideation-06-benchmarking-ico-03.png';
import styleBenchIco4 from '@/assets/ideation/ideation-06-benchmarking-ico-04.png';
import styleBench1 from '@/assets/ideation/ideation-06-benchmarking-sample-01.jpg';
import styleBench2 from '@/assets/ideation/ideation-06-benchmarking-sample-02.jpg';
import styleBench3 from '@/assets/ideation/ideation-06-benchmarking-sample-03.jpg';
import styleBench4 from '@/assets/ideation/ideation-06-benchmarking-sample-04.jpg';
import styleBgBench from '@/assets/ideation/ideation-06-bg-benchmarking.png';

import styleGalleryImbiIcon from '@/assets/ideation/ideation-07-gallery-sample-imbi-icon-01.png';
import styleQuoteOpen from '@/assets/quote-open-bluebright.svg';
import styleUDFooter from '@/assets/underline-footer.png';
import styleIcoBrandVid from '@/assets/video-play-ico-transparent.png';
// import style from '@/assets/ideation/asdasd.jpg'
import areaTip01 from '@/components/ideation/area-tip-01.vue';
import areaTip02 from '@/components/ideation/area-tip-02.vue';
import areaTip03 from '@/components/ideation/area-tip-03.vue';
import areaTip04 from '@/components/ideation/area-tip-04.vue';
import areaTip05 from '@/components/ideation/area-tip-05.vue';
import areaTip06 from '@/components/ideation/area-tip-06.vue';
import areaTip07 from '@/components/ideation/area-tip-07.vue';
import areaTip08 from '@/components/ideation/area-tip-08.vue';
import areaTip09 from '@/components/ideation/area-tip-09.vue';
import areaTip10 from '@/components/ideation/area-tip-10.vue';
import areaTip11 from '@/components/ideation/area-tip-11.vue';
import areaTip12 from '@/components/ideation/area-tip-12.vue';
import areaTip13 from '@/components/ideation/area-tip-13.vue';
import phase1inner0 from '@/components/ideation/timeline-phase1-2010.vue';
import phase1inner1 from '@/components/ideation/timeline-phase1-2011.vue';
import phase1inner2 from '@/components/ideation/timeline-phase1-2012.vue';
import phase1inner3 from '@/components/ideation/timeline-phase1-2013.vue';
import modalITL1 from '@/components/ideation/timeline-phase1.vue';
import phase2inner0 from '@/components/ideation/timeline-phase2-2013.vue';
import phase2inner1 from '@/components/ideation/timeline-phase2-2014.vue';
import phase2inner2 from '@/components/ideation/timeline-phase2-2015-01.vue';
import phase2inner3 from '@/components/ideation/timeline-phase2-2015-02.vue';
import phase2inner4 from '@/components/ideation/timeline-phase2-2015-03.vue';
import phase2inner5 from '@/components/ideation/timeline-phase2-2017-01.vue';
import phase2inner6 from '@/components/ideation/timeline-phase2-2017-02.vue';
import modalITL2 from '@/components/ideation/timeline-phase2.vue';
import ItemInfoCardA from '@/components/ItemInfoCardA.vue';
import PopupVideo from '@/components/PopupVideo.vue';
import axios from 'axios';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import SwiperCore, {Autoplay, EffectCards, Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
// import HelloWorld from '@/components/HelloWorld.vue'
import Slick from 'vue-slick';

SwiperCore.use([EffectCards, Autoplay, Navigation]);

// @ is an alias to /src

export default {
  name: 'Ideation',
  components: {
    ItemInfoCardA,
    // HelloWorld
    Slick,
    modalITL1,
    modalITL2,
    Swiper,
    SwiperSlide,
    areaTip01,
    areaTip02,
    areaTip03,
    areaTip04,
    areaTip05,
    areaTip06,
    areaTip07,
    areaTip08,
    areaTip09,
    areaTip10,
    areaTip11,
    areaTip12,
    areaTip13,
    phase1inner0,
    phase1inner1,
    phase1inner2,
    phase1inner3,
    phase2inner0,
    phase2inner1,
    phase2inner2,
    phase2inner3,
    phase2inner4,
    phase2inner5,
    phase2inner6,
    PopupVideo,

  },
  metaInfo() {
    return {
      title: 'Ideation',
    };
  },
  setup() {
    return {
      EffectCards,
    };
  },
  data: function() {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      dataToken: [],
      dataTokenParaInner: [],
      dataTokenArea: [],
      styleQuoteOpen: styleQuoteOpen,
      btnNewTab: btnNewTab,
      btnNewTabBlack: btnNewTabBlack,
      styleBgFooter: styleBgFooter,
      styleUDFooter: styleUDFooter,
      styleBanner: styleBanner,
      styleBannerNext: styleBannerNext,
      // styleBgIntro: styleBgIntro,
      styleBgIntro2: styleBgIntro2,
      styleExplore: styleExplore,
      styleExploreMobile: styleExploreMobile,
      styleKeyFeaIco: [
        styleKeyFeaIco1,
        styleKeyFeaIco2,
        styleKeyFeaIco3,
        styleKeyFeaIco4,
        styleKeyFeaIco5],
      styleKeyFea: [
        {
          items: [
            {
              title: 'Transit-centric Hub',
              url: styleKeyFea1,
              link: 'https://trx.my/city/transit-centric-hub-0',
            },
            {
              title: 'Connecting to a World-Class City',
              url: styleKeyFea2,
              link: 'https://trx.my/city/connecting-world-class-city',
            },
            {
              title: 'The Sustainable Backbone of TRX',
              url: styleKeyFea3,
              link: 'https://trx.my/city/sustainable-backbone-trx-0',
            },
            {
              title: 'Celebrating Local Ecology in A World-Class Financial District',
              url: styleKeyFea4,
              link: 'https://trx.my/city/celebrating-local-ecology-world-class-financial-district',
            },
            {
              title: 'Introducing TRX&apos;s Public Realm',
              url: styleKeyFea5,
              link: 'https://trx.my/city/introducing-trxs-public-realm',
            },
            {
              title: 'Placemaking & the Public Realm',
              url: styleKeyFea6,
              link: 'https://trx.my/city/placemaking-and-public-realm',
            }],
        },
      ],
      styleBgBrandVid: styleBgBrandVid,
      styleBgVidBrandOverlay: styleBgVidBrandOverlay,
      styleBgVidBrandVid: styleBgVidBrandVid,
      styleIcoBrandVid: styleIcoBrandVid,
      data_benchmarking: [
        {bg: styleBgBench},
        {
          items: [
            {
              icon: styleBenchIco1,
              title: 'Canary Wharf',
              url: styleBench1,
              desc: 'Today, Canary Wharf is home to 35 buildings which include London&apos;s blue-chip financial companies, high-end hotels, shops, restaurants and luxury residence. ',
              link: 'https://trx.my/city/canary-wharf-derelict-dockyard-global-business-district-0',
            },
            {
              icon: styleBenchIco2,
              title: 'Marina Bay Sands',
              url: styleBench2,
              desc: 'Over the past 20 years, the Singapore’s Central Business District has grown exponentially with the expansion of Marina Bay.',
              link: 'https://trx.my/city/singapores-marina-bay-cbd-extension-planning-precision-and-functionality',
            },
            {
              icon: styleBenchIco3,
              title: 'Dubai',
              url: styleBench3,
              desc: 'After decades of heavy infrastructure investment, Dubai now has one of the world&apos;s busiest ports, the world&apos;s busiest airport and the world&apos;s leading airline.',
              link: 'https://trx.my/city/difc-connecting-business-east-and-west-0',
            },
            {
              icon: styleBenchIco4,
              title: 'Pudong',
              url: styleBench4,
              desc: 'At the dawn of the 1990s, a Special Economic Zone known as the Pudong New Area was designated in what had long been a sparsely populated backwater.',
              link: 'https://trx.my/city/shanghai-pudong-successful-urban-planning-powers-economic-engine',
            }],
        },
      ],
      data_gallery_imbi_icon: styleGalleryImbiIcon,
      MileClose: MileClose,
      slickRight: slickRight,
      slickLeft: slickLeft,
      slickOptions: {
        slidesToShow: 4,
        arrows: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        // autoplay: true,
        // autoplaySpeed: 0,
        // speed: 4000,
        // cssEase:'linear',
        infinite: true,
        // slidesToScroll: 0.1,
        draggable: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },

          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsBench: {
        slidesToShow: 2,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsGallery: {
        slidesToShow: 3,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      swiperOptionsGallery: {
        simulateTouch: false,
        slidesPerView: 4,
        speed: 3000,
        spaceBetween: 25,
        zoom: true,
        loop: true,
        disableOnInteraction: true,
        // freeMode: true,
        // centerInsufficientSlides: true,
        // centeredSlides: true,
        autoplay: {
          delay: 0,
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        },
      },
      swiperOptionsGalleryImbi: {
        effect: 'cards',
        cardsEffect: {
          slideShadows: false,
          transformEl: null,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // initialSlide : 1,
      },
      slickOptionsGalleryRelo: {
        slidesToShow: 3,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      swiperOptionsGalleryRelo: {
        simulateTouch: false,
        slidesPerView: 4,
        speed: 3000,
        spaceBetween: 25,
        zoom: true,
        loop: true,
        disableOnInteraction: true,
        // freeMode: true,
        // centerInsufficientSlides: true,
        // centeredSlides: true,
        autoplay: {
          delay: 100,
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        },
      },
      isVisibleBV: false,
      isVisibleITL1: false,
      isVisibleITL2: false,
    };
  },
  computed: {
    styleIntro() {
      return {
        'background-image': 'url(' + styleBgIntro + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
    styleBench() {
      return {
        'background-image': 'url(' + styleBgBench + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        'background-position': 'right center',
      };
    },
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
  },
  mounted: function() {
    this.getData();
    // $(window).on('load',function(){AOS.refresh();});
    this.$root.$on('bv::modal::shown', function() {
      $('html').css('overflow', 'hidden');
      // AOS.refresh();
    }).$on('bv::modal::hidden', function() {
      // AOS.init({disable:false});
      $('html').css('overflow', 'auto');
    });
    $('.swiper-auto').mouseenter(function() {
      console.log('stop autoplay');
      this.swiper.autoplay.stop();
    });
    $('.swiper-auto').mouseleave(function() {
      console.log('start autoplay');
      this.swiper.autoplay.start();
    });
  },
  methods: {
    // getData: function () {
    //   axios.get('https://urlhere').then((datasource) => {
    //     this.dataToken = datasource.data.nameOfFirstArray;
    //   });
    // },
    // myFunction: function () {
    //     this.dataSample.title = this.dataSample.title.replace(" ", "-");    
    // }

    // hidePhase9Modal() {
    //   this.$root.$emit('bv::hide::modal', 'milestonesPhase10Modal', '#btnShow')
    // },

    styleKF(key) {
      switch (key) {
        case 0:
          return {
            'background-image': 'url(' + styleKeyFeaIcoBlue1 + ')',
          };
        case 1:
          return {
            'background-image': 'url(' + styleKeyFeaIcoBlue2 + ')',
          };
        case 2:
          return {
            'background-image': 'url(' + styleKeyFeaIcoBlue3 + ')',
          };
        case 3:
          return {
            'background-image': 'url(' + styleKeyFeaIcoBlue4 + ')',
          };
        case 4:
          return {
            'background-image': 'url(' + styleKeyFeaIcoBlue5 + ')',
          };
      }
    },
    getData: function() {
      axios.get('https://trx-api.okie.my/api/general/chapter/2/section/all')
          .then(response => response.data)
          .then(datasource => {
            this.dataToken = datasource;
            // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
            for (let i = 0; i < datasource.length; i++) {
              const content = datasource[i]?.paragraph_timeline?.replace(/\\/g, '');
              if (content && content !== '') {
                this.dataTokenParaInner.push(JSON.parse(content));
              }
            }
          });
      axios.get('https://trx-api.okie.my/api/general/chapter/2/section/8')
          .then(response => response.data)
          .then(datasource => {
            this.dataTokenArea = datasource;
          });
    },
    // showModalBV() {
    // this.isVisibleBV = true;
    // },
    // closeModalBV() {
    // this.isVisibleBV = false;
    // },
    // showModalITL: function (id) {
    // if (id === 1) {
    // this.isVisibleITL1 = true;
    // }
    // if (id === 2) {
    // this.isVisibleITL2 = true;
    // }
    // },
    // openITLSecond() {
    // this.isVisibleITL2 = true;
    // },
    // closeModalITL1() {
    // this.isVisibleITL1 = false;
    // },
  },
  // created: function(){
  //   this.myFunctionOnLoad()
  // }
  // methods: {
  //   debug (event) {
  //     console.log(event.target.name)
  //   }
  // } 
};

</script>

<style lang="scss">
@import '@/assets/styles/ideation.scss';
</style>
