<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">August 2015</h2>
      <div class="text text-center">
        <div class="body">
          <p>Affin Bank buys CBD quarter plot in TRX fronting Jalan Tun Razak and Jalan Sultan Ismail for its new headquarters</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->

          <div class="col-12">
            <div class="pwwrap pwstyle_03">
              <img v-if="data_cover" :src="data_cover" alt="hero" class="img-fluid d-block mx-auto">
            </div>
          </div>

          <div class="col-12">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>Affin Bank’s new head office is part of TRX’s financial quarter, essentially the Central Business District of TRX that occupies the most visible corner of the site, fronting both Jalan Tun Razak and Jalan Sultan Ismail.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/03-comm/comm-03-milestone-modal-bg-01.jpg'

import Mile1503cover from '@/assets/03-comm/milestone/2015-03/Affin-buys-TRX.jpg'

export default {
  name: 'CommTimeline201503',
  components: {
    Slick,
  //   phase1inner1
  },
  data: function () {
      return {
        apiBasePath: 'https://trx-api.okie.my/',
        // dataToken: [],
        // dataTokenParaInner: [],
        btnNewTab: btnNewTab,
        slickLeft: slickLeft,
        slickRight: slickRight,
        data_cover: Mile1503cover,
        MileClose: MileClose,
        slickOptions2013: {
          slidesToShow: 3,
          arrows: true,
          infinite: true,
          nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
          prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
          pauseOnHover: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        },
      };
    },
    computed: {
      styleBgFooterCss() {
        return {
          'background-image': 'url(' + styleBgFooter + ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }
      },
      styleBgMilesModal() {
        return {
          'background-image': 'url(' + MileModalBG + ')',
          'background-repeat': 'no-repeat',
          'background-size': '100% auto',
          'background-position': 'center top',
        }
      },
    },
  }
</script>