<template>
    <div class="modal-backdrop">
      <div class="modal" v-bind:class="{ 'd-block' : msg === true}" id="myDIV"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        

          <button
            type="button"
            class="btn-green"
            @click="close2"
            aria-label="Close modal"
          >
            Close me 2!
          </button>

      </div>
    </div>
</template>

<script>
  export default {
    name: 'Modal2',
    props: {
      msg: String
    },
    methods: {
      close2() {
        this.$emit('closeTwo');
      },
    },
  };
</script>