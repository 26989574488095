<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">June 2014</h2>
      <!-- <div class="text text-center">
        <div class="body">
          <p>Call for investors to submit proposal.</p>
        </div>
      </div> -->
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div claxss="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>Lendlease signs the Heads of Terms agreement with TRX to jointly develop a
                    17-acre lifestyle quarter in the financial district, its biggest plot </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <slick v-if="data_image" ref="slick" :options="slickOptions2013"
                class="milestones-year-slide-items">
              <a class="milestones-year-slide-item position-relative"
                  v-for="(item, index) in data_image" :href="item.img" :key="index" data-pswp-width="1487" data-pswp-height="520">

                <div class="milestones-year-slide-img slide-img-only">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index"
                      class="img-fluid w-100">
                </div>
                <!-- <div class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                  <div class="text">
                    <h4 :inner-html.prop="item.title"></h4>
                  </div>
                </div> -->

              </a>
            </slick>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';
import 'photoswipe/dist/photoswipe.css';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png';
import slickRight from '@/assets/arrow-next-transparent.png';

import styleBgFooter from '@/assets/bg-footer.svg';
import btnNewTab from '@/assets/ico-newtab.png';

import MileClose from '@/assets/ico-milestones-modal-close.png';
import MileModalBG from '@/assets/03-comm/comm-03-milestone-modal-bg-01.jpg';

import Mile1401 from '@/assets/03-comm/milestone/2014-01/1.png';
import Mile1402 from '@/assets/03-comm/milestone/2014-01/2.png';
import Mile1403 from '@/assets/03-comm/milestone/2014-01/3.png';
import Mile1404 from '@/assets/03-comm/milestone/2014-01/4.png';
import Mile1405 from '@/assets/03-comm/milestone/2014-01/5.png';

export default {
  name: 'CommTimeline201401',
  components: {
    Slick,
    //   phase1inner1
  },
  data: function() {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      data_image: [
        {
          img: Mile1401,
          // title: 'Adrian Smith + Gordon Gill Architecture'
        },
        {
          img: Mile1402,
          // title: 'Atkins'
        },
        {
          img: Mile1403,
          // title: 'BDP'
        },
        {
          img: Mile1404,
          // title: 'BDP'
        },
        {
          img: Mile1405,
          // title: 'BDP'
        },
      ],
      MileClose: MileClose,
      slickOptions2013: {
        slidesToShow: 2,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            }
          }
        ],
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      };
    },
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '.milestones-year-slide-items a',
        showHideAnimationType: 'fade',
        pswpModule: PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
};
</script>