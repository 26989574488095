<template>
  <div class="area-tip-content" v-if="dataToken[1]">
    <div :style="styleBg"></div>
    <div class="container">
      <div class="row">
        <div class="col-12 py-0">
          <div class="text">
            <h2 class="modal-title"><img :src="pin" alt="pin">{{ dataToken[1].title }}</h2>
          </div>
        </div>
        <div class="col-lg-5 col-md-6" v-if="dataToken[1].body">
          <div class="text">
            <div class="body" :inner-html.prop="dataToken[1].body"></div>
          </div>
        </div>
        <div class="col-lg-7 col-md-6" v-if="dataToken[1].images">
          <swiper v-if="dataToken[1]" class="swiper area-slide-items pswiperstyle_01" ref="mySwiper" :options="SwiperOptionsAreaTip">
            <swiper-slide v-for="(image, index) in dataToken[1].images.split(',')" :key="index" class="area-slide-item">
              <div class="area-slide-img">
                <img v-if="image" :src="apiBasePath + image" :alt="'area-image-' + index" class="img-fluid">
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"><img :src="slickLeft"></div>
            <div class="swiper-button-next" slot="button-next"><img :src="slickRight"></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from "jquery";
import axios from 'axios'
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'
import pin from '@/assets/ideation/ideation-03-explore-modal-icon-01.png'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: 'ModalTip02',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function () {
    return {
      dataToken: [],
      dataTokenBg: [],
      apiBasePath: 'https://trx-api.okie.my/',
      slickLeft: slickLeft,
      slickRight: slickRight,
      pin: pin,
      SwiperOptionsAreaTip: {
        autoHeight: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      axios.get('https://trx-api.okie.my/api/general/chapter/2/section/8').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        this.dataTokenBg = 'https://trx-api.okie.my' + datasource[1].images.split(',')[0];
      });
    },
  },
  computed: {
    styleBg() {
      return {
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100%',
        'background-image': 'url(' + this.dataTokenBg + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'opacity': '.1',
        'filter': 'grayscale(100%)'
      }
    },
  },
};
</script>
<style>
.area-tip-content {
  background-color: #1C1B52;
}
</style>