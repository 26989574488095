<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">June 2017</h2>
      <div class="text text-center">
        <div class="body">
          <p>HSBC makes RM 1 billion investment for its new Malaysia headquarter office in TRX, as part of their commitment to develop their business in the country</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->

          <div class="col-12">
            <slick v-if="data_image" ref="slick" :options="slickOptions2013" class="milestones-year-slide-items">
              <div class="milestones-year-slide-item position-relative" v-for="(item, index) in data_image" :key="index">

                <div class="milestones-year-slide-img slide-img-only">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index" class="img-fluid">
                </div>
                <!-- <div class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                  <div class="text">
                    <h4 :inner-html.prop="item.title"></h4>
                  </div>
                </div> -->

              </div>
            </slick>
          </div>

          <div class="col-12">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>HSBC is the first international bank to set up a new Malaysia HQ in TRX, raising TRX’s profile as an upcoming international financial district.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/03-comm/comm-03-milestone-modal-bg-01.jpg'

import Mile170101 from '@/assets/03-comm/milestone/2017-01/1.jpg'
import Mile170102 from '@/assets/03-comm/milestone/2017-01/2.jpg'
import Mile170103 from '@/assets/03-comm/milestone/2017-01/3.jpg'
import Mile170104 from '@/assets/03-comm/milestone/2017-01/4.jpg'

export default {
  name: 'CommTimeline201701',
  components: {
    Slick,
  //   phase1inner1
  },
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      data_image: [
        { 
          img: Mile170101,
          // title: 'Adrian Smith + Gordon Gill Architecture'
        },
        { 
          img: Mile170102,
          // title: 'Atkins'
        },
        { 
          img: Mile170103,
          // title: 'BDP'
        },
        { 
          img: Mile170104,
          // title: 'BDP'
        },
      ],
      MileClose: MileClose,
      slickOptions2013: {
        slidesToShow: 3,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
}
</script>