<template>
  
  <!-- <div class="modal fade milestones-modal" id="milestonesPhase1Modal" tabindex="-1" aria-labelledby="milestonesPhase1Label" aria-hidden="true"> -->
    <!-- <div class="modal-dialog modal-fullscreen modal-dialog-scrollable"> -->
      <!-- <div class="modal-content"> -->
        <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
        <div>

          <!-- <div class="milestones-close">
            <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" >
              <img :src="MileClose" alt="mile-close-modal">
            </a>
          </div> -->

          <div class="pwwrap pwstyle_01">
            <h2 class="modal-title text-center">Phase 1: Conceptualisation & Commencement</h2>
          </div>
          
          <div class="milestones-content" :style="styleBgMilesModal">

            <div class="milestones-list">

              <div class="container">
                
                <div class="row" v-for="(item, index, id) in dataToken" :key="index" v-if="index == 0">
                  <div class="col-md-6 milestones-item" v-for="(para, paraindex) in dataTokenParaInner[index]" v-bind:class="{'offset-md-6': paraindex % 2 !== 0}">
                    <div v-if="para.image" class="cover">
                      <img :src="apiBasePath + para.image" :alt="'milestones-phase-2-' + [paraindex + 1]">
                    </div>
                    <div v-if="para.title || para.body" class="text" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50">
                      <div v-if="para.title" class="content-title":inner-html.prop="para.title"></div>
                      <div v-if="para.body" class="body" :inner-html.prop="para.body">
                      </div>
                    </div>
                    <!-- <div v-if="para.link" class="link"> -->
                    <div class="link">
                      <!-- <a :href="para.link" class="text-center pabutton_1 inverse">Discover More <img :src="btnNewTab" alt="btn-newtab"></a> -->
                      <a v-if="paraindex == 0" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesPhase1inner' + paraindex + 'Modal'">View design submissions <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 1" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesPhase1inner' + paraindex + 'Modal'">View design <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 2" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesPhase1inner' + paraindex + 'Modal'">View speech <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 3" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesPhase1inner' + paraindex + 'Modal'">View photos <img :src="btnNewTab" alt="btn-newtab"></a>
                    </div>
                  </div>
                </div>

                <!-- <div class="row"> -->
                  <!-- <div class="col-md-6 milestones-item"> -->
                    <!-- <div class="cover">
                      <img :src="asd" alt="asd">
                    </div> -->
                    <!-- <div class="text" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"> -->
                      <!-- <div class="content-title">2010</div> -->
                      <!-- <div class="body"> -->
                        <!-- <p>Master planners design competition kick-off</p> -->
                      <!-- </div> -->
                    <!-- </div> -->
                    <!-- <div class="link">
                      <a href="" class="text-center pabutton_1 inverse">Discover More <img :src="btnNewTab" alt="btn-newtab"></a>
                    </div> -->
                  <!-- </div> -->

                  <!-- <div class="col-md-6 offset-md-6 milestones-item"> -->
                    <!-- <div class="cover">
                      <img :src="asd" alt="asd">
                    </div> -->
                    <!-- <div class="text" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"> -->
                      <!-- <div class="content-title">August 2011</div> -->
                      <!-- <div class="body"> -->
                        <!-- <p>Appointment of Akitek Jururancang and Machado Silvetti as Master Planners</p> -->
                      <!-- </div> -->
                    <!-- </div> -->
                    <!-- <div class="link"> -->
                      <!-- <a href="https://www.trx.my/press-release/akitek-jururancang-ajm-and-machado-silvetti-and-associates-msa-selected-master" class="text-center pabutton_1 inverse" target="_blank">Discover More <img :src="btnNewTab" alt="btn-newtab"></a> -->
                    <!-- </div> -->
                  <!-- </div> -->

                  <!-- <div class="col-md-6 milestones-item"> -->
                    <!-- <div class="cover" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"> -->
                      <!-- <img :src="MileP101" alt="milestones-image01"> -->
                    <!-- </div> -->
                    <!-- <div class="text" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50" data-aos-delay="300"> -->
                      <!-- <div class="content-title">July 2012</div> -->
                      <!-- <div class="body"> -->
                        <!-- <p>Official launch by YAB Prime Minister of Malaysia</p> -->
                      <!-- </div> -->
                    <!-- </div> -->
                    <!-- <div class="link"> -->
                      <!-- <a href="https://www.trx.my/press-release/speech-yab-dato-sri-mohd-najib-bin-tun-haji-abdul-razak-official-launch-tun-razak" class="text-center pabutton_1 inverse" target="_blank">View Press Release <img :src="btnNewTab" alt="btn-newtab"></a> -->
                    <!-- </div> -->
                  <!-- </div> -->

                  <!-- <div class="col-md-6 offset-md-6 milestones-item"> -->
                    <!-- <div class="cover" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"> -->
                      <!-- <img :src="MileP102" alt="milestones-image02"> -->
                    <!-- </div> -->
                    <!-- <div class="text" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50" data-aos-delay="300"> -->
                      <!-- <div class="content-title">October 2013</div> -->
                      <!-- <div class="body"> -->
                        <!-- <p>Earthworks commence on site</p> -->
                      <!-- </div> -->
                    <!-- </div> -->
                    <!-- <div class="link">
                      <a href="" class="text-center pabutton_1 inverse">Discover More <img :src="btnNewTab" alt="btn-newtab"></a>
                    </div> -->
                  <!-- </div> -->
                
                <!-- </div> -->
              </div>

            </div>

            <!-- <div class="container-fluid milestones-next"> -->
              <!-- <div class="row justify-content-center"> -->
                <!-- <div class="milestone-item col-lg-4 col-md-6" v-for="(item, index) in data_mile[0].items" :key="index" v-if="index === 1"> -->
                  <!-- <div v-if="data_mile[0]"> -->
                    <!-- <div class="milestone-item-wrap position-relative"> -->
                      <!-- <div class="milestone-image"> -->
                        <!-- <img v-if="item.url" :src="item.url" :alt="'milestone-item-' + index" class="img-fluid"> -->
                      <!-- </div> -->
                      <!-- <div class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 justify-content-center"> -->
                        <!-- <div class="text text-center text-white"> -->
                          <!-- <div v-if="item.icon" class="icon"><img :src="item.icon" alt="gallery-imbi-icon" class="img-fluid"></div> -->
                          <!-- <h5>{{ 'Phase ' + [index + 1] }}</h5> -->
                          <!-- <h3 v-if="item.title" :inner-html.prop="item.title"></h3> -->
                          <!-- <a class="pabutton_1 inverse" data-bs-target="#milestonesPhase2Modal" data-bs-toggle="modal" data-bs-dismiss="modal">Read More</a> -->
                        <!-- </div> -->
                      <!-- </div> -->
                    <!-- </div> -->
                  <!-- </div> -->
                <!-- </div> -->


                  <!-- <div class="milestone-item col-lg-4 col-md-6" v-for="(item, index, id) in dataToken" :key="index" v-if="item.section == 'Timeline of Milestones' && item.section_id == 9 && index == 1"> -->
                    <!-- <a class="btn-modal" data-bs-toggle="modal" :data-bs-target="'#milestonesPhase' + [index + 1] + 'Modal'" data-bs-dismiss="modal" @click="close"> -->
                      <!-- <div class="milestone-item-wrap position-relative"> -->
                        <!-- <div class="milestone-image"> -->
                          <!-- <img v-if="item.cover_image" :src="apiBasePath + item.cover_image" :alt="'milestone-item-' + index" class="img-fluid"> -->
                        <!-- </div> -->
                        <!-- <div class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 justify-content-center"> -->
                          <!-- <div class="text text-center text-white"> -->
                            <!-- <div v-if="item.icon" class="icon"><img :src="apiBasePath + item.icon" alt="gallery-imbi-icon" class="img-fluid"></div> -->
                            <!-- <h5>{{ 'Phase ' + [index + 1] }}</h5> -->
                            <!-- <h3 v-if="item.title" :inner-html.prop="item.title"></h3> -->
                            <!-- <div class="pabutton_1 inverse">Read More</div> -->
                          <!-- </div> -->
                        <!-- </div> -->
                      <!-- </div> -->
                    <!-- </a> -->
                  <!-- </div> -->
              <!-- </div> -->
            <!-- </div> -->

          </div>

        </div>
        <!-- <div class="modal-footer justify-content-center">
        </div> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'

import styleBgFooter from '@/assets/bg-footer.svg'
import faviconPath from '@/assets/favicon.png'
import btnNewTab from '@/assets/ico-newtab.png'

import styleMile1 from '@/assets/ideation/ideation-09-milestone-phase-1.jpg'
import styleMile2 from '@/assets/ideation/ideation-09-milestone-phase-2.jpg'
import styleMile3 from '@/assets/ideation/ideation-09-milestone-phase-3.jpg'
import styleMileIco1 from '@/assets/ideation/ideation-09-milestone-ico-phase-1.png'
import styleMileIco2 from '@/assets/ideation/ideation-09-milestone-ico-phase-2.png'
import styleMileIco3 from '@/assets/ideation/ideation-09-milestone-ico-phase-3.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
// import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-02.jpg'
import MileP101 from '@/assets/ideation/milestone/phase1-sample-01.jpg'
import MileP102 from '@/assets/ideation/milestone/phase1-sample-02.jpg'
import MileP202 from '@/assets/ideation/milestone/phase2-sample-02.jpg'
import MileP203 from '@/assets/ideation/milestone/phase2-sample-03.jpg'
import MileP204 from '@/assets/ideation/milestone/phase2-sample-04.jpg'
import MileP206 from '@/assets/ideation/milestone/phase2-sample-06.jpg'
import MileP207 from '@/assets/ideation/milestone/phase2-sample-07.jpg'
import MileGalP201 from '@/assets/ideation/milestone/phase2-gallery-01.png'
import MileGalP202 from '@/assets/ideation/milestone/phase2-gallery-02.png'
import MileGalP203 from '@/assets/ideation/milestone/phase2-gallery-03.png'
import MileGalP204 from '@/assets/ideation/milestone/phase2-gallery-04.png'
import MileGalP205 from '@/assets/ideation/milestone/phase2-gallery-05.png'
import MileGalP206 from '@/assets/ideation/milestone/phase2-gallery-06.png'
import MileGalP207 from '@/assets/ideation/milestone/phase2-gallery-07.png'
import MileGalP208 from '@/assets/ideation/milestone/phase2-gallery-08.png'
import MileGalP209 from '@/assets/ideation/milestone/phase2-gallery-09.png'
import MileGalP210 from '@/assets/ideation/milestone/phase2-gallery-10.png'

// import phase1inner1 from '@/components/ideation/timeline-phase1-2010.vue'


export default {
  name: 'IdeationTimelinePhase1',
  // components: {
    // phase1inner1
  // },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      dataToken: [],
      dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      data_mile: [
        { items: [
          { 
            icon: styleMileIco1,
            title: 'Conceptualisation & Commencement',
            url: styleMile1
          },
          { 
            icon: styleMileIco2,
            title: 'Commercialisation & Construction',
            url: styleMile2
          }]
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        }
      ],
      MileClose: MileClose,
      MileP101: MileP101,
      MileP102: MileP102,
      MileP202: MileP202,
      MileP203: MileP203,
      MileP204: MileP204,
      MileP206: MileP206,
      MileP207: MileP207,
      MileGalP201: MileGalP201,
      MileGalP202: MileGalP202,
      MileGalP203: MileGalP203,
      MileGalP204: MileGalP204,
      MileGalP205: MileGalP205,
      MileGalP206: MileGalP206,
      MileGalP207: MileGalP207,
      MileGalP208: MileGalP208,
      MileGalP209: MileGalP209,
      MileGalP210: MileGalP210,
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  methods: {
    getData: function () {
      axios.get('https://trx-api.okie.my/api/general/chapter/2/section/9').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
        for (let i = 0; i < datasource.length; i++) {
          this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_timeline.replace(/\\/g, '')) );
        };

      });
    },
    showModalITL2() {
      this.$emit('showModalITL2');
    },
    close() {
      this.$emit('openITLSecond');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
