<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">October 2013</h2>
      <div class="text text-center">
        <div class="body">
          <p>Earthworks commence on site</p>
        </div>
      </div>
    </div>
    
    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <slick v-if="data_image" ref="slick" :options="slickOptionsP12010" class="milestones-year-slide-items">
              <div class="milestones-year-slide-item position-relative" v-for="(item, index) in data_image" :key="index">

                <div class="milestones-year-slide-img slide-img-only">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index" class="img-fluid">
                </div>
                <!-- <div class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                  <div class="text">
                    <h4 :inner-html.prop="item.title"></h4>
                  </div>
                </div> -->

              </div>
            </slick>

            <!-- <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center">zzzzzzzzzzzzzz</h3>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

import MileP11301 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-01.jpg'
import MileP11302 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-02.jpg'
import MileP11303 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-03.jpg'
import MileP11304 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-04.jpg'
import MileP11305 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-05.jpg'
import MileP11306 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-06.jpg'
import MileP11307 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-07.jpg'
import MileP11308 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-08.jpg'
import MileP11309 from '@/assets/ideation/milestone/phase1-2013/phase1-2013-09.jpg'

export default {
  name: 'IdeationTimelinePhase12013',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      data_image: [
        // { items: [
          { 
            img: MileP11301,
            title: 'Adrian Smith + Gordon Gill Architecture'
          },
          { 
            img: MileP11302,
            title: 'Atkins'
          },
          { 
            img: MileP11303,
            title: 'BDP'
          },
          { 
            img: MileP11304,
            title: 'Broadway Malyan'
          },
          { 
            img: MileP11305,
            title: 'Cox Richardson Architects & Planners'
          },
          { 
            img: MileP11306,
            title: ''
          },
          { 
            img: MileP11307,
            title: 'Rock Blasting Works Section 1'
          },
          { 
            img: MileP11308,
            title: ''
          },
          { 
            img: MileP11309,
            title: ''
          }
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        // }
      ],
      MileClose: MileClose,
      slickOptionsP12010: {
        slidesToShow: 3,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>