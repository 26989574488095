import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueMeta from 'vue-meta'
import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css';

// bootstrap 5
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js/dist/popper.min.js';
import { BootstrapVue } from 'bootstrap-vue'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// importing AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

// scrollmagic
import VueScrollmagic from 'vue-scrollmagic'
Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100
});

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

global.jQuery = require('jquery');
// require('bootstrap');
Vue.use(BootstrapVue);
window.$ = window.jQuery = jQuery;

import sassStyles from '@/assets/styles/custom.scss';
// import '@/assets/scripts/anime.js';
import '@/assets/scripts/script.js';
import SlickLightbox from '@/assets/scripts/slick-lightbox.js';
import '@/assets/styles/slick-lightbox.css';

library.add(faUser);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueMeta);
Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    AOS.init({
        offset: 50,
    });
  }
}).$mount('#app');

// custom filter: toSlug
String.prototype.toSlug = function(separator = '-') {
    return this
        .toString()
        .normalize('NFD') // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, separator)
}
String.prototype.toSlug2 = function(separator = '-') {
    return this
        .toString()
        .normalize('NFD') // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace('rnrn', '')
        .replace('rn', '')
        .replace('rn', '')
}