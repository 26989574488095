export const isInViewport = (node) => {
  let rect = node.getBoundingClientRect();
  return (
      (rect.height > 0 || rect.width > 0) &&
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
export const commaSeperatedNumber = (val) => {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return val;
};
export const startNumberFlipping = (el, targetNumber) => {
  const duration = 3000; // 3 seconds
  const interval = 20; // 50 milliseconds

  const currentNumber = parseInt(el.innerText);
  const increment = Math.ceil((targetNumber - currentNumber) / (duration / interval));

  let currentValue = currentNumber;
  let iterationCount = 0;

  if (!el.classList.contains('animated') && !el.classList.contains('start-animating')) {
    const flipInterval = setInterval(() => {
      if (currentValue >= targetNumber) {
        currentValue = targetNumber;
      } else {
        currentValue += increment;
      }
      el.innerText = commaSeperatedNumber(Math.round(currentValue));
      el.classList.add('start-animating');
      iterationCount++;

      if (iterationCount >= duration / interval) {
        clearInterval(flipInterval);
        el.innerText = commaSeperatedNumber(Math.round(targetNumber));
        el.classList.remove('start-animating');
        el.classList.add('animated');
      }
    }, interval);

  }
};