<template>
  <div class="row">
    <div class="col-12 col-lg-3">
      <h3 class="content-title pfcolor_magenta">Events Gallery</h3>
      <div v-for="(item, index) in galleries">
        <div v-if="index === currentSlide"
            v-html="item.title"></div>
      </div>
    </div>
    <div class="col-12 col-lg-8 offset-lg-1">
      <div class="position-relative">
        <Swiper ref="elEventSwiper" class="swiper" :options="swiperOptions"
            @slideChange="onSlideChange()">
          <SwiperSlide v-for="event in galleries" :key="event">
            <EventGallery :event="event"/>
          </SwiperSlide>
        </Swiper>
        <div v-show="currentSlide !== 0" class="swiper-prev swiper-arrow">
          <img :src="slickLeft"/>
        </div>
        <div v-show="currentSlide !== galleries.length - 1" class="swiper-next swiper-arrow">
          <img :src="slickRight"/>
        </div>
        <img src="@/assets/05-trx-today-tomorrow/el-kerawang.png" alt="Kerawang"
            class="kerawang-first">
      </div>
    </div>
  </div>
</template>
<script>

import slickRight from '@/assets/05-trx-today-tomorrow/arrow-next-pink.png';
import slickLeft from '@/assets/05-trx-today-tomorrow/arrow-prev-pink.png';
import EventGallery from '@/components/trx-today-tomorrow/EventGallery.vue';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

export default {
  name: 'EventGalleries',
  props: ['galleries'],
  components: {EventGallery, Swiper, SwiperSlide},
  data: function() {
    return {
      slickLeft,
      slickRight,
      currentSlide: 0,
      swiperOptions: {
        autoHeight: false,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
      },
    };
  },
  methods: {
    onSlideChange() {
      this.currentSlide = this.$refs.elEventSwiper.$swiper.activeIndex;
    },
  },
};
</script>

<style scoped>
.swiper {
  position: relative;
}

.swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.swiper-prev {
  left: 0;
}

.swiper-next {
  right: 0;
}

.kerawang-first {
  position: absolute;
  left: -70px;
  bottom: -50px;
  max-width: calc(calc(var(--bs-gutter-x) * .5) + 50%);
}

@media (max-width: 575px) {
  .kerawang-first {
    left: 0;
    bottom: -12px;
    height: calc(100% + 12px);
    -o-object-fit: cover;
    object-fit: cover;
    margin-left: calc(var(--bs-gutter-x) * -.5);
  }
}
</style>