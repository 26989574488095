<template>
  <div>
    <div class="row">
      <div v-for="(item, index) in event.images.split(',').slice(0,4)" :key="index"
          class="col-6 ratio-4x3">
        <a :href="apiBasePath + item" target="_blank" rel="noreferrer"
            @click.prevent="initLightBox(index)">
          <img :src="apiBasePath + item" :alt="event.title" class="img-fluid h-100"
              style="object-fit: cover;"/>
        </a>
      </div>
    </div>
    <div ref="gallery" class="d-none">
      <a v-for="(item, index) in event.images.split(',')" :key="index" :href="apiBasePath + item"
          target="_blank" rel="noreferrer">
      </a>
    </div>
  </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';

export default {
  name: 'EventGallery',
  props: ['event'],
  data: function() {
    return {
      currentIndex: '',
      apiBasePath: 'https://trx-api.okie.my',
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: this.$refs.gallery,
        children: 'a',
        showHideAnimationType: 'fade',
        zoomAnimationDuration: false,
        pswpModule: PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {
    initLightBox(index) {
      this.lightbox.loadAndOpen(index, {
        gallery: this.$refs.gallery,
      });
    },
  },
};
</script>