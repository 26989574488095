<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">May 2015</h2>
      <div class="text text-center mb-3">
        <div class="body">
          <p>Sales & Purchase agreement with Mulia Group for Signature Tower</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <!-- <slick v-if="data_image" ref="slick" :options="slickOptionsP12014" class="milestones-year-slide-items">
              <div class="milestones-year-slide-item position-relative" v-for="(item, index) in data_image" :key="index">
                <div class="milestones-year-slide-img">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index" class="img-fluid">
                </div>
              </div>
            </slick> -->

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center mb-3">MULIA GROUP COMPLETES ONE OF THE LARGEST CONCRETE POURS IN THE WORLD</h3>
            </div>
            
            <div class="pwwrap pwstyle_02 mb-3">
              <video controls width="100%">
                <source :src="videoMulia">
              </video>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p>Tun Razak Exchange has signed Indonesia’s leading property developer Mulia Group on board to develop its Signature Tower. The land transaction is valued at RM665 million. Developed by Mulia Group, the Signature Tower will be the anchor development for the financial district. In April 2016, the developers poured concrete for a massive 20,200m3 mat foundation, or about the size of eight Olympic swimming pools, making it one of the largest concrete pours recorded globally.</p>
                </div>
              </div>
            </div>

            <!-- <div class="pwwrap pwstyle_01 mb-5">
              <a class="pabutton_1 inverse" href="https://www.trx.my/press-release/australian-company-develop-lifestyle-quarter" target="_blank">Discover More <img :src="btnNewTab" alt="btn-newtab"></a>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

import videoMulia from '@/assets/ideation/milestone/phase2-2015-01/Mulia_Video_Highlight_Draft_1.0.mp4'

import MileP2150101 from '@/assets/ideation/milestone/phase2-2015-01/phase2-2015-01-01.jpg'
import MileP2150102 from '@/assets/ideation/milestone/phase2-2015-01/phase2-2015-01-02.jpg'
import MileP2150103 from '@/assets/ideation/milestone/phase2-2015-01/phase2-2015-01-03.jpg'

export default {
  name: 'IdeationTimelinePhase2201501',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      videoMulia: videoMulia,
      MileClose: MileClose,
      data_image: [
        // { items: [
          { 
            img: MileP2150101,
            title: 'Adrian Smith + Gordon Gill Architecture'
          },
          { 
            img: MileP2150102,
            title: 'Atkins'
          },
          { 
            img: MileP2150103,
            title: 'BDP'
          }
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        // }
      ],
      // slickOptionsP12014: {
      //   slidesToShow: 3,
      //   arrows: true,
      //   infinite: true,
      //   nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
      //   prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
      //   pauseOnHover: false,
      //   responsive: [
      //     {
      //       breakpoint: 991,
      //       settings: {
      //         slidesToShow: 1,
      //       }
      //     }
      //   ]
      // },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>