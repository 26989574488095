<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">August 2011</h2>
      <div class="text text-center">
        <div class="body">
          <p>Appointment of Akitek Jururancang and Machado Silvetti as Master Planners</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pwwrap pwstyle_03">
              <img v-if="data_image" :src="data_image[1].img" alt="hero" class="img-fluid">
            </div>
          </div>
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center">AKITEK JURURANCANG (AJM) AND MACHADO SILVETTI AND ASSOCIATES (MSA) SELECTED AS MASTER PLANNERS</h3>
            </div>

            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p><strong>KUALA LUMPUR, 4 August 2011:</strong>&nbsp;1MDB (“1Malaysia Development Berhad”) has selected Akitek Jururancang (Malaysia) Sdn Bhd (“AJM”) and its international partner Machado Silvetti and Associates (“MSA”) as the master planners for the Kuala Lumpur International Financial District (“KLIFD”).</p>
                  <p>The two companies represent the fusion of local and international talents that best translates 1MDB’s vision for the future financial district through their concept and rendering of the master plan.</p>
                  <p>“AJM and MSA share a vision for a highly functioning, interesting, innovative, and aesthetically pleasing urban district that will establish KLIFD as a financial centre of choice,” said Dato’ Azmar Talib, Chief Operating Officer of 1MDB Real Estate Sdn Bhd, a subsidiary of 1MDB.</p>
                  <p>In late 2010, 1MDB organised a design competition for master planners, attracting some of the world’s best.</p>
                  <p>The AJM-MSA partnership is committed to deliver a sustainable and modern KLIFD and implement the highest level of international design and progressive planning tenets, including industry-leading sustainability in buildings and infrastructure, a pedestrian-friendly green public realm, seamless links to public transportation, and guidelines for architecture of high international standards.</p>
                  <p>“We have a real contribution to make to the urbanscape of Kuala Lumpur, in line with the objective of the Greater KL initiative to make Kuala Lumpur a world class city. The increasing emphasis on the greening of the city as manifested in the recent River of Life and upgrading of the Lake Gardens, signals a welcome reception of KLIFD’s unique “Financial Centre in the Park”,” said AJM Managing Director Dato’ Sri Ar. Haji Esa Haji Mohamed.</p>
                  <p>“We firmly believe that the combination of a deeply committed client, a unique gateway site, and a strong Malaysian and international multidisciplinary consultant team will result in a financial district that will take its place amongst the leading districts of the world. We are honored to be part of this groundbreaking project,” said MSA Principal Jorge Silvetti.</p>
                  <p>The Detailed Master Plan is scheduled to be completed in the first quarter of 2012.</p>
                  <p>The 75-acre development in the Imbi area fronting Jalan Tun Razak aims to bring together leading financial institutions and top global companies to create a catalytic pool of world-class players. It will leverage on Malaysia’s existing strength in Islamic finance and play on its strategic location to complement other financial centres within the region.</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-12">
            <div class="pwwrap pwstyle_01 mb-5">
              <div class="row">
                <div class="col-6">
                  <img v-if="data_image" :src="data_image[0].img" alt="hero" class="img-fluid">
                </div>
                <div class="col-6">
                  <img v-if="data_image" :src="data_image[2].img" alt="hero" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
// import Slick from 'vue-slick';
// import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import { Swiper, SwiperSlide, FreeMode } from "vue-awesome-swiper";

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

import MileP11101 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-01.jpg'
import MileP11102 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-02.jpg'
import MileP11103 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-03.jpg'
// import MileP11104 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-04.jpg'
// import MileP11105 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-05.jpg'
// import MileP11106 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-06.jpg'
// import MileP11107 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-07.jpg'
// import MileP11108 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-08.jpg'
// import MileP11109 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-09.jpg'
// import MileP11110 from '@/assets/ideation/milestone/phase1-2011/phase1-2011-10.jpg'

export default {
  name: 'IdeationTimelinePhase12011',
  components: {
    // Slick,
    Swiper,
    SwiperSlide,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      data_image: [
        // { items: [
          { 
            img: MileP11101,
            title: 'Adrian Smith + Gordon Gill Architecture'
          },
          { 
            img: MileP11102,
            title: 'Atkins'
          },
          { 
            img: MileP11103,
            title: 'BDP'
          },
          // { 
          //   img: MileP11104,
          //   title: 'Broadway Malyan'
          // },
          // { 
          //   img: MileP11105,
          //   title: 'Cox Richardson Architects & Planners'
          // },
          // { 
          //   img: MileP11106,
          //   title: 'Foster + Partners'
          // },
          // { 
          //   img: MileP11107,
          //   title: 'Machado and Silvetti Associates, Inc.'
          // },
          // { 
          //   img: MileP11108,
          //   title: 'Nikken Sekkei Ltd'
          // },
          // { 
          //   img: MileP11109,
          //   title: 'Office for Metropolitan Architecture'
          // },
          // { 
          //   img: MileP11110,
          //   title: 'Sasaki Associates Inc.'
          // }
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        // }
      ],
      MileClose: MileClose,
      SwiperOptionsAreaTip: {
        spaceBetween: 30,
        freeMode: {
          enabled: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>