<template>
  <div id="app">

    <header class="navbar shadow-sm">
      <div class="progress">
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
      </div>
      <div class="all-header">
        <div class="container d-flex align-items-center">
          <div class="header-main">
            <div class="burger-wrapper">
              <button @click.prevent="toggleMenu" class="navbar-toggler burger-button"><span
                  class="icon-bar"></span><span class="icon-bar"></span><span
                  class="icon-bar"></span><span class="icon-bar"></span></button>
            </div>
            <div ref="headerMenu" class="header-menu" id="CollapsingNavbar">
              <div id="header_menu">
                <div class="header-menu-inner-wrapper">
                  <div id="nav">
                    <ul class="navbar-nav">
                      <li>
                        <router-link to="/">The Making Of TRX</router-link>
                      </li>
                      <!-- <li><router-link to="/sample">Sample</router-link></li> -->
                      <li>
                        <router-link to="/ideation">Ideation</router-link>
                      </li>
                      <li>
                        <router-link to="/commercialisation">Commercialisation</router-link>
                      </li>
                      <li>
                        <router-link to="/construction-collaboration">Construction & Collaboration
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/trx-today-tomorrow">TRX: Today & Tomorrow</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div @click="toggleMenu" class="menu-overlay"></div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </header>

    <router-view/>

    <Footer />
  </div>
</template>


<script>
import Footer from '@/components/Footer.vue';
import jQuery from 'jquery';
import $ from 'jquery';
import AOS from 'aos';

import styleBgFooter from '@/assets/bg-footer.svg';
import faviconPath from '@/assets/favicon.png';
import btnNewTab from '@/assets/ico-newtab.png';

import styleMile1 from '@/assets/ideation/ideation-09-milestone-phase-1.jpg';
import styleMile2 from '@/assets/ideation/ideation-09-milestone-phase-2.jpg';
import styleMile3 from '@/assets/ideation/ideation-09-milestone-phase-3.jpg';
import styleMileIco1 from '@/assets/ideation/ideation-09-milestone-ico-phase-1.png';
import styleMileIco2 from '@/assets/ideation/ideation-09-milestone-ico-phase-2.png';
import styleMileIco3 from '@/assets/ideation/ideation-09-milestone-ico-phase-3.png';

import MileClose from '@/assets/ico-milestones-modal-close.png';
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg';
import MileP101 from '@/assets/ideation/milestone/phase1-sample-01.jpg';
import MileP102 from '@/assets/ideation/milestone/phase1-sample-02.jpg';
import MileP202 from '@/assets/ideation/milestone/phase2-sample-02.jpg';
import MileP203 from '@/assets/ideation/milestone/phase2-sample-03.jpg';
import MileP204 from '@/assets/ideation/milestone/phase2-sample-04.jpg';
import MileP206 from '@/assets/ideation/milestone/phase2-sample-06.jpg';
import MileP207 from '@/assets/ideation/milestone/phase2-sample-07.jpg';
import MileGalP201 from '@/assets/ideation/milestone/phase2-gallery-01.png';
import MileGalP202 from '@/assets/ideation/milestone/phase2-gallery-02.png';
import MileGalP203 from '@/assets/ideation/milestone/phase2-gallery-03.png';
import MileGalP204 from '@/assets/ideation/milestone/phase2-gallery-04.png';
import MileGalP205 from '@/assets/ideation/milestone/phase2-gallery-05.png';
import MileGalP206 from '@/assets/ideation/milestone/phase2-gallery-06.png';
import MileGalP207 from '@/assets/ideation/milestone/phase2-gallery-07.png';
import MileGalP208 from '@/assets/ideation/milestone/phase2-gallery-08.png';
import MileGalP209 from '@/assets/ideation/milestone/phase2-gallery-09.png';
import MileGalP210 from '@/assets/ideation/milestone/phase2-gallery-10.png';

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Home',
    // all titles will be injected into this template
    titleTemplate: '%s | Tun Razak Exchange',
    link: [
      {rel: 'icon', href: faviconPath},
    ],
  },
  mounted() {
    // jquery here\
    // $('#brandVideoModal, #milestonesPhase1Modal, #milestonesPhase2Modal').function(){AOS.refresh();}
    $(window).on('load', function() {AOS.refresh();});
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      };
    },
  },
  components: {
    Footer,
    // Modal,
  },
  data: function() {
    return {
      isModalVisible: false,
      btnNewTab: btnNewTab,
      data_mile: [
        {
          items: [
            {
              icon: styleMileIco1,
              title: 'Conceptualisation & Commencement',
              url: styleMile1,
            },
            {
              icon: styleMileIco2,
              title: 'Commercialisation & Construction',
              url: styleMile2,
            }],
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        },
      ],
      MileClose: MileClose,
      MileP101: MileP101,
      MileP102: MileP102,
      MileP202: MileP202,
      MileP203: MileP203,
      MileP204: MileP204,
      MileP206: MileP206,
      MileP207: MileP207,
      MileGalP201: MileGalP201,
      MileGalP202: MileGalP202,
      MileGalP203: MileGalP203,
      MileGalP204: MileGalP204,
      MileGalP205: MileGalP205,
      MileGalP206: MileGalP206,
      MileGalP207: MileGalP207,
      MileGalP208: MileGalP208,
      MileGalP209: MileGalP209,
      MileGalP210: MileGalP210,
    };
  },
  methods: {
    toggleMenu() {
      const body = document.body;
      body.classList.toggle('side-menu-active');
      document.documentElement.style.overflowY = body.classList.contains('side-menu-active')
          ? 'hidden'
          : 'auto';
      this.$refs.headerMenu.classList.toggle('active');
    },
    // showModal() {
    //   this.isModalVisible = true;
    // },
    // closeModal() {
    //   this.isModalVisible = false;
    // }
  },
  watch: {
    '$route'() {
      const body = document.body;
      body.classList.remove('side-menu-active');
      document.documentElement.style.overflowY = 'auto';
      this.$refs.headerMenu.classList.remove('active');
    },
  },
};
</script>