<template>
	<div id="app">
	  <div class="viewports">
	    <div class="viewport screen-first">
	      <div class="viewport-fhd" id="char-b-right">
	        <div class="viewport">
	          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.85 305.41">
	            <defs>
	              <mask id="mask2" x="0" y="0" width="100%" height="100%">
	                <rect class="overlay" x="0" y="0" width="100%" height="100%" />

	                <path
	                  style="transform: translateY(0);"
	                  d="
	                      M0.5,299.7l0,-299.19l133.25,0a63.25,63.25 0 0 1 47,19.82a64.41,64.41 0 0 1 19.57,46.87l0,16.34a62.12,62.12 0 0 1 -16.58,46a81.92,81.92 0 0 1 -49.62,20.54a60.1,60.1 0 0 1 49.62,20.19a70.63,70.63 0 0 1 16.58,46.38l0,16.35a64.38,64.38 0 0 1 -19.58,47.22a63.23,63.23 0 0 1 -46.62,19.83l-133.62,-0.35zm66.57,-166.3l33.16,0a33.63,33.63 0 0 0 33.52,-33.4l0,-33a33.4,33.4 0 0 0 -33.28,-33.53l-33.4,0l0,99.93zm0,133.14l33.16,0a31.6,31.6 0 0 0 24,-10a32.54,32.54 0 0 0 9.86,-24l0,-33a29.43,29.43 0 0 0 -33.53,-33.16l-33.49,0l0,100.16z
	                      "
	                />
	              </mask>
	            </defs>
	            <rect
	              id="r2"
	              fill="white"
	              mask="url(#mask2)"
	              x="0"
	              y="-1%"
	              width="1920"
	              height="102%"
	            />
	          </svg>
	        </div>
	      </div>
	      <div class="viewport-fhd" id="char-b-left">
	        <div class="viewport">
	          <svg
	            class="logo"
	            xmlns="http://www.w3.org/2000/svg"
	            viewBox="0 0 203.85 305.41"
	          >
	            <path
	              stroke="white"
	              fill="white"
	              fill-opacity="0"
	              d="
	              M0.5,299.7l0,-299.19l133.25,0a63.25,63.25 0 0 1 47,19.82a64.41,64.41 0 0 1 19.57,46.87l0,16.34a62.12,62.12 0 0 1 -16.58,46a81.92,81.92 0 0 1 -49.62,20.54a60.1,60.1 0 0 1 49.62,20.19a70.63,70.63 0 0 1 16.58,46.38l0,16.35a64.38,64.38 0 0 1 -19.58,47.22a63.23,63.23 0 0 1 -46.62,19.83l-133.62,-0.35zm66.57,-166.3l33.16,0a33.63,33.63 0 0 0 33.52,-33.4l0,-33a33.4,33.4 0 0 0 -33.28,-33.53l-33.4,0l0,99.93zm0,133.14l33.16,0a31.6,31.6 0 0 0 24,-10a32.54,32.54 0 0 0 9.86,-24l0,-33a29.43,29.43 0 0 0 -33.53,-33.16l-33.49,0l0,100.16z
	              "
	            />
	          </svg>
	        </div>
	      </div>
	    </div>
	    <div class="viewport chars-container">
	      <div>
	        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2243 305">
	          <path
	            stroke="none"
	            fill="white"
	            fill-rule="evenodd"
	            d="
	            M0 0 l2243 0,0 305,-2243 0,0 -305z
	            M0.5,299.7l0,-299.19l133.25,0a63.25,63.25 0 0 1 47,19.82a64.41,64.41 0 0 1 19.57,46.87l0,16.34a62.12,62.12 0 0 1 -16.58,46a81.92,81.92 0 0 1 -49.62,20.54a60.1,60.1 0 0 1 49.62,20.19a70.63,70.63 0 0 1 16.58,46.38l0,16.35a64.38,64.38 0 0 1 -19.58,47.22a63.23,63.23 0 0 1 -46.62,19.83l-133.62,-0.35zm66.57,-166.3l33.16,0a33.63,33.63 0 0 0 33.52,-33.4l0,-33a33.4,33.4 0 0 0 -33.28,-33.53l-33.4,0l0,99.93zm0,133.14l33.16,0a31.6,31.6 0 0 0 24,-10a32.54,32.54 0 0 0 9.86,-24l0,-33a29.43,29.43 0 0 0 -33.53,-33.16l-33.49,0l0,100.16z
	            M408.23,304.9l0,-135.2l67.77,0l0,135.2l67.48,0l0,-236.55a65.56,65.56 0 0 0 -19.93,-47.68a64.52,64.52 0 0 0 -47.55,-20.17l-67.77,0a65.92,65.92 0 0 0 -47.68,19.93a65.16,65.16 0 0 0 -20,47.92l0,236.55l67.68,0zm0,-236.55a34,34 0 0 1 33.77,-34.11l0.37,0a33.88,33.88 0 0 1 33.63,34.11l0,67.36l-67.77,0l0,-67.36z
	            M883.32,237.06c-2.69,45.23 -24.45,67.85 -67.36,67.85l-67.73,0a68.21,68.21 0 0 1 -67.72,-67.85l0,-168.7a65.25,65.25 0 0 1 19.68,-47.68a64.42,64.42 0 0 1 48.04,-20.17l67.77,0c42.54,0 64.91,22.62 67.36,67.85l-33.66,0q-4,-34.11 -33.74,-34.11l-33.96,0a33.86,33.86 0 0 0 -33.74,34l0,168.81a33,33 0 0 0 9.9,24.45a32.06,32.06 0 0 0 23.84,10.15l34,0q30.07,0 33.74,-34.11l33.58,-0.49z
	            M1088.71,304.9l0,-118.46l101,118.46l67.73,0l-135.44,-152.2l135.42,-152.2l-58.68,0l-110,124.2l0,-124.2l-68.24,0l0,304.4l68.21,0z
	            M1428.23,304.9l0,-135.2l67.77,0l0,135.2l67.48,0l0,-236.55a65.56,65.56 0 0 0 -19.93,-47.68a64.52,64.52 0 0 0 -47.55,-20.17l-67.77,0a65.92,65.92 0 0 0 -47.68,19.93a65.16,65.16 0 0 0 -20,47.92l0,236.55l67.68,0zm0,-236.55a34,34 0 0 1 33.77,-34.11l0.37,0a33.88,33.88 0 0 1 33.63,34.11l0,67.36l-67.77,0l0,-67.36z
	            M1700.5,304.89l0,-304.39l135.57,0a64.33,64.33 0 0 1 47.43,20.17a65.56,65.56 0 0 1 19.93,47.68l0,33.37a65.54,65.54 0 0 1 -19.93,47.68a64.18,64.18 0 0 1 -47.43,20l-67.72,0l0,135.2l-67.85,0.29zm67.85,-169.18l33.74,0a32.5,32.5 0 0 0 24.44,-10a32,32 0 0 0 10,-23.71l0,-33.65a34,34 0 0 0 -33.83,-34.11l-34.35,0l0,101.47z
	            M2040.5,304.89l0,-304.39l135.57,0a64.33,64.33 0 0 1 47.43,20.17a65.56,65.56 0 0 1 19.93,47.68l0,33.37a65.54,65.54 0 0 1 -19.93,47.68a64.18,64.18 0 0 1 -47.43,20l-67.72,0l0,135.2l-67.85,0.29zm67.85,-169.18l33.74,0a32.5,32.5 0 0 0 24.44,-10a32,32 0 0 0 10,-23.71l0,-33.65a34,34 0 0 0 -33.83,-34.11l-34.35,0l0,101.47z
	          "
	          />
	        </svg>
	      </div>
	    </div>
	    <div class="viewport screen-third">
	      <span class="viewport third-box">
	        <span class="viewport">
	          <video autoplay muted loop>
	            <source
	              src="https://www.w3schools.com/howto/rain.mp4"
	              type="video/mp4"
	            />
	            Your browser does not support HTML5 video.
	          </video>
	        </span>
	        <svg>
	          <defs>
	            <mask id="mask" x="0" y="0" width="100%" height="100%">
	              <rect class="overlay" x="0" y="0" width="100%" height="100%" />
	              <text
	                id="text"
	                x="50%"
	                y="50%"
	                dominant-baseline="middle"
	                text-anchor="middle"
	              >
	                A83
	              </text>
	            </mask>
	          </defs>
	          <rect id="r" x="0" y="0" width="100%" height="100%" />
	        </svg>
	      </span>
	      <div class="viewport first-box">
	        <div class="viewport">
	          <div class="text text-3-1"><span>Back up</span></div>
	          <div class="text text-3-2"><span>Professional</span></div>
	        </div>
	      </div>
	      <div class="viewport second-box">
	        <div class="viewport">
	          <div class="text text-3-1"><span>Back up</span></div>
	          <div class="text text-3-2"><span>Professional</span></div>
	        </div>
	      </div>
	      <div class="squart" id="squart1"><div></div></div>
	      <div class="squart" id="squart2"><div></div></div>
	    </div>
	  </div>
	  <div class="item" id="trigger0">item 0.1</div>
	  <div class="item" id="trigger1">item 1</div>
	  <div class="item">item 1.1</div>
	  <div class="item" id="trigger2">item 2</div>
	  <div class="item" id="trigger3">item 2.2</div>
	  <div class="item">item 3</div>
	  <div class="item">item 3.2</div>
	  <div class="item" id="trigger4">item 4</div>
	  <div class="item">item 4.2</div>
	  <div class="item" id="trigger6">item 5</div>
	  <div class="item">item 5.2</div>
	  <div class="item" id="trigger7">item 6</div>
	  <div class="item">item 6.2</div>
	  <div class="item">item 7</div>
	  <div class="item">item 7.2</div>
	  <div class="item">item 7.3</div>
	  <div class="item">item 7.4</div>
	  <div class="item">item 7.4</div>
	  <div class="item" id="trigger5">
	    item 8
	    <div class="viewport screen-four"></div>
	  </div>
	  <div class="item">item 8.2</div>
	</div>
</template>


<script>
import axios from 'axios';
import AOS from 'aos';
import { TweenMax, TimelineMax, Linear } from 'gsap';

export default {
  name: 'TigerMap',
  // data: function () {
  //   return {
  //     dataToken: [],
  //   };
  // },
  mounted: function () {
  	scenes.map((screne, i) => {
      const scene = this.$scrollmagic
        .scene(screne.scene)
        //.setPin(items[i], { pushFollowers: false })
        .setTween(screne.tweenElement, screne.tween)
        .addIndicators({ name: `Scene ${i}` });
      this.$scrollmagic.addScene(scene);
    });
    const scene = this.$scrollmagic
      .scene({
        triggerElement: "#trigger5"
      })
      .setPin(".screen-four", { pushFollowers: false })
      // .setTween(".four", {
      //   css: {
      //     borderTop: "30px solid white",
      //     backgroundColor: "blue",
      //     scale: 0.7, // the tween durtion can be omitted and defaults to 1
      //   },
      // })
      .addIndicators({ name: "scene7" });
    this.$scrollmagic.addScene(scene);
  },
}

const scenes = [
  {
    scene: {
      triggerElement: "#trigger1",
      triggerHook: 0,
      // offset: 50, // move trigger to center of element
      duration: "50%"
    },
    tweenElement: ".logo path",
    tween: {
      css: {
        "fill-opacity": 1
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger2",
      triggerHook: 0,
      duration: "200%"
    },
    tweenElement: ".chars-container",
    tween: {
      css: {
        transform: "translateX(-1920px)"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger2",
      triggerHook: 0,
      duration: "200%"
    },
    tweenElement: "#char-b-left",
    tween: {
      css: {
        width: "-1920px"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger2",
      triggerHook: 0,
      duration: "200%"
    },
    tweenElement: "#char-b-right",
    tween: {
      css: {
        width: "-1622px"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger3",
      triggerHook: 0,
      duration: 300
    },
    tweenElement: ".screen-third",
    tween: {
      css: {
        left: "0"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger4",
      triggerHook: 0,
      duration: "100%"
    },
    tweenElement: "#squart1",
    tween: {
      css: {
        top: "-50%",
        left: "-50%",
        transform: "scale(8)"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger4",
      triggerHook: 0,
      duration: "100%"
    },
    tweenElement: "#squart2",
    tween: {
      css: {
        top: "150%",
        left: "150%",
        transform: "scale(8)"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger4",
      triggerHook: 0,
      duration: "100%"
    },
    tweenElement: ".screen-third .text",
    tween: {
      css: {
        opacity: 1,
        transform: "translate(0,0) scale(1)"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger6",
      triggerHook: 0,
      duration: "100%"
    },
    tweenElement: ".screen-third>div.viewport",
    tween: {
      css: {
        width: "0vw"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger7",
      triggerHook: 0,
      duration: "300%"
    },
    tweenElement: ".third-box #text",
    tween: {
      css: {
        "font-size": "2000px"
      }
    }
  },
  {
    scene: {
      triggerElement: "#trigger7",
      triggerHook: 0,
      duration: "100%"
    },
    tweenElement: ".third-box>.viewport",
    tween: {
      css: {
        opacity: 1
      }
    }
  }
];
</script>

<style scoped lang="scss">
.viewport{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
}
.viewports {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  overflow: hidden;
}
.viewport-fhd{
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 100vh;
  overflow: hidden;
}
#char-b-left{
  width: 1920px
}
#char-b-right{
  width: 2218px
}
#char-b-right svg{
    width: 120px;
    height: 180px;
    top: 50vh;
    left: 50vw;
    margin: -90px 0 0 -60px;
    position: absolute;
    box-shadow: 0 0 0 100vmax #fff;
}
#char-b-right>.viewport,
#char-b-left>.viewport,
.screen-first{
  background-image: linear-gradient(to top right, #3f51b5, #2196f3)
}
.screen-four{
  background: #009688
}
.logo {
  position: absolute;
  width: 120px;
  height: 180px;
  left: 50vw;
  top: 50vh;
  margin: -90px 0 0 -60px;
  svg{
    height: 180px;
  }
}

.chars-container {
  width: 150vmax;
  width: 1920px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 50;
  transform: translateX(1920px);
  > div {
    display: flex;
    outline: 1px solid red;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    box-shadow: 50vmax 0 0 0 #fff;
    svg {
      height: 180px;
      box-shadow: 0 0 0 100vmax #fff;
    }
  }
}
.screen-third{
  left: 100vw;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  .text-dark{
    span{
      font-size: 120px;
      line-height: 120px;
      color: #000;
      font-weight: bold;
    }
  }
  .first-box>div,
  .first-box{
    top: 0;
    left:0;
  }
  .second-box,
  .first-box{
    height: 50vh;
  }
  .second-box>div,
  .second-box{
    bottom: 0;
    right:0;
    top: auto;
    left: auto;
  }
  >.viewport{
    overflow: hidden;
    >.viewport{

      background-image: linear-gradient(to bottom right, #4050b5, #2196f3);
    }
  }
  .third-box{
    background: #000;
    >.viewport{
      opacity: 0;
      video{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    svg {
      width: 100vw;
      height: 100vh;
      position: relative;
      z-index: 22;
    }
    svg text {
      text-anchor: middle;
    }
    svg #overlay {
      fill: white;
      opacity: 1;
    }
    svg #text {
      font-size: 120px;
      font-weight: bold;
    }
    svg #r {
      fill: white;
      mask: url(#mask);
    }
  }
  .third-box,
  .viewport>div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
  .text{
    span{
      font-size: 80px;
      color: #fff;
    }
    opacity: 0;
    transform: translate(100vw,-40vh) scale(2);
    &.text-3-2{
      transform: translate(-100vw,40vh) scale(2);
    }
    // position: absolute;
  }
}

.squart{
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
  z-index: 12;
}
.squart+.squart{
  margin: 10px 0 0 10px
}
.squart>div{
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  position: absolute;
  left: -40px;
  top: -40px;
  border-radius: 10px;
}
.item {
  margin-bottom: 50vh;
  text-align: center;
  height: 0;
  position: relative;
  z-index: 90;
  color: #fff;
  border-top: 1px solid rgba(255,255,255,.1);
  line-height: 0
}
.overlay {
  fill: white;
  opacity: 1;
}
</style>