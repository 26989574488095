<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">August 2015</h2>
      <div class="text text-center">
        <div class="body">
          <p>Affin Bank acquires land within TRX to build its new HQ</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <slick v-if="data_image" ref="slick" :options="slickOptionsP12015" class="milestones-year-slide-items">
              <div class="milestones-year-slide-item position-relative" v-for="(item, index) in data_image" :key="index">
                <div class="milestones-year-slide-img slide-img-only">
                  <img v-if="item" :src="item.img" :alt="'milestones-year-' + index" class="img-fluid">
                </div>
              </div>
            </slick>

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center">AFFIN BANK SIGNS DEAL FOR NEW HQ IN TUN RAZAK EXCHANGE</h3>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p>Affin Bank’s new head office will be part of TRX’s financial quarter, essentially the Central Business District of TRX that occupies the most visible corner of the site, fronting both Jalan Tun Razak and Jalan Sultan Ismail. The Sale and Purchase agreement was signed following an extensive negotiation that began in 2012, and it covers the development rights to build a commercial tower for a consideration of RM255 million. The development will have a Gross Floor Area (GFA) of 823,439 sq ft, with the price per sq ft GFA of approximately RM310.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

// import videoMemorandumMOV from '@/assets/ideation/milestone/phase2-2013/Investor_Memorandum_Video_Final.mp4'

import MileP2150201 from '@/assets/ideation/milestone/phase2-2015-02/phase2-2015-02-01.jpg'
import MileP2150202 from '@/assets/ideation/milestone/phase2-2015-02/phase2-2015-02-02.jpg'
import MileP2150203 from '@/assets/ideation/milestone/phase2-2015-02/phase2-2015-02-03.jpg'

export default {
  name: 'IdeationTimelinePhase2201501',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      MileClose: MileClose,
      data_image: [
        // { items: [
          { 
            img: MileP2150201,
            title: 'Adrian Smith + Gordon Gill Architecture'
          },
          { 
            img: MileP2150202,
            title: 'Atkins'
          },
          { 
            img: MileP2150203,
            title: 'BDP'
          }
          // { 
          //   icon: styleMileIco3,
          //   title: 'Activation',
          //   url: styleMile3,
          //   link: []
          // }
        // }
      ],
      slickOptionsP12015: {
        slidesToShow: 3,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>