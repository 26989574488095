<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">October 2015</h2>
      <div class="text text-center">
        <div class="body">
          <p>WCT is awarded a major infrastructure contract and purchases a plot of residential land in TRX</p>
          <img src="@/assets/03-comm/milestone/2015-04/WCT-gets-TRX-contract.jpg" alt="hero" class="img-fluid d-block mx-auto">
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <div class="pwwrap pwstyle_02">
              <h3 class="modal-title text-center mb-3">WCT BAGS TRX NEW INFRA CONTRACT;<br>BUYS A PLOT OF TRX LAND</h3>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p><b>KUALA LUMPUR, October 19, 2015:</b> Tun Razak Exchange, Kuala Lumpur’s upcoming international financial district, announced today that it has awarded a major infrastructure package to WCT Berhad, with a contract valued at RM754.8 million.</p>

                  <p>The two-year contract will cover underground road structures, installation of direct buried utilities, at-grade and elevated roadway, as well as associated earthworks and mechanical and electrical works. This follows an earlier award of earthwork contract package in 2013.</p>

                  <p>Separately, WCT Group is also buying a plot of residential land in TRX as part of its strategy to seek opportunities in prime development with strong growth and earning potential. The 1.7-acre land has a plot ratio of more than 10 times, and the purchase was based on RM288 per sq ft of approved gross floor area.</p>

                  <p>The total consideration for the land purchase is RM223 million, or about RM3,100 per sq ft.</p>

                  <p>The infrastructure contract package makes up a significant part of TRX’s comprehensive infrastructure program that will turn the financial district into the best connected address in Kuala Lumpur. It will provide direct links between TRX with major highways such as the SMART tunnel and Maju Expressway, as well as the city’s arterial roads Jalan Tun Razak and Jalan Sultan Ismail.</p>

                  <p>WCT won the bid through a tender process which was executed through a multi-stage evaluation process. The tender process was overseen by a third party independent audit company. Eleven companies were shortlisted following the pre-qualification stage, before WCT was selected.</p>

                  <p>“WCT is a well-known name when it comes to large-scale construction, infrastructure and engineering work, and given their solid track record, we are happy to continue working with them for the TRX project,” said Dato’ Azmar Talib, CEO of 1MDB Real Estate, the master developer for TRX.</p>

                  <p>The proposed residential development to be undertaken by WCT has an estimated GDV of approx. RM1.1 billion, the company said. It comprises a tower block of high-end serviced apartments with complimentary retail components.</p>

                  <p>“The Land is strategically located within TRX. With its excellent location and its planned world-class infrastructure, together with excellent accessibility and connectivity, WCT is confident that the proposed development will be well received by the prospective investors and purchasers,” said Managing Director of WCT Holdings Berhad, Mr Taing Kim Hwa.</p>

                  <p>TRX’s master plan also includes implementing site-wide infrastructure with a sizable public realm and excellent sustainable facilities, such as a wastewater recycling plant that will cut potable water demand by 50%. The financial district is investing close to RM3.8 billion for its infrastructure.</p>

                  <p>Phase 1, scheduled for completion in 2018, will allow tenants of the financial district to enjoy unrivalled connectivity to Greater Kuala Lumpur and beyond. This includes the TRX MRT interchange station on site, direct links to Kuala Lumpur major roads and highways including DBKL’s proposed new tunnel that will be part of Jalan Tun Razak’s improvement, as well as connections to the planned KL-Singapore HSR in Bandar Malaysia via the MRT Line 2.</p>

                  <p>TRX has been awarded Platinum Provisional GBI Certification by Green Building Index Accreditation Panel, the first ever platinum level township certification in Malaysia, and a LEED for Neighborhood Development Plan Gold Level Conditional Approval from US-based Green Building Council, for its sustainability plan.</p>

                  <p>To date, TRX has signed global property and infrastructure group Lendlease to develop the Lifestyle Quarter; Indonesia’s leading property developer Mulia Group to develop the Signature Tower plot; Affin Bank Berhad for an office tower; Lembaga Tabung Haji for a residential plot, and global leader in water management Veolia Water Technologies South East Asia for the water treatment and recycling concessionaire.</p>

                  <p>TRX has also started construction on a commercial office tower for a financial institution anchor tenant.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

// import videoMemorandumMOV from '@/assets/ideation/milestone/phase2-2013/Investor_Memorandum_Video_Final.mp4'

export default {
  name: 'IdeationTimelinePhase22014',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      videoMemorandumMOV: videoMemorandumMOV,
      MileClose: MileClose,
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>