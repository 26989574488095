<template>
  <div class="cosmopolitan-content" v-if="dataToken[3]">
    <div :style="styleBg"></div>

    <div class="position-relative">
      <div class="container">
        <div class="row">
          <div class="col-12 py-0">
            <div class="text text-center">
              <h2 class="modal-title pfcolor_maroon" :inner-html.prop="dataToken[3].title"></h2>
            </div>
          </div>
        </div>
      </div>
      <div class="cosmopolitan-para" v-for="(para, paraindex) in dataTokenParaInner[3]" v-bind:class="{'pfbgcolor_pink': paraindex == 1, 'pfbgcolor_white': paraindex == 2, 'pfbgcolor_greydark': paraindex == 3}">
        <div class="cosmopolitan-para-container container">
          <div class="para-content">

            <div v-if="para.field_title" class="pwwrap pwstyle_02">
              <h5 class="para-title text-center" :inner-html.prop="para.field_title"></h5>
              <img class="para-title-udl" :src="cosmoUDL" alt="underline">
            </div>

            <div v-if="para.field_body" class="pwwrap pwstyle_01">
              <div class="body" :inner-html.prop="para.field_body"></div>
            </div>

            <div v-if="para.field_images && paraindex == 1" class="cosmopolitan-images">
              <div class="container auto">
                <!-- <swiper class="swiper swiper-auto street-slide-items" ref="mySwiper" :options="swiperOptionsStreet">
                  <swiper-slide v-for="(item, index) in para.field_images.split(',')" :key="index" class="street-slide-item">
                    <div class="street-slide-img">
                      <img v-if="item" :src="apiBasePath + item" :alt="'street-item-' + index" class="img-fluid">
                    </div>
                    <div class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                      <div class="text">
                        <h4 :inner-html.prop="para.field_images_1.split(',')[index]"></h4>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper> -->
                <slick v-if="para.field_images && paraindex == 1" ref="slick" :options="slickOptionsStreet" class="street-slide-items">
                  <div class="street-slide-item position-relative" v-for="(item, index) in para.field_images.split(',')" :key="index">
                    <div class="street-slide-img">
                      <img v-if="item" :src="apiBasePath + item" :alt="'street-item-' + index" class="img-fluid">
                    </div>
                    <div class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                      <div class="text">
                        <h4 :inner-html.prop="para.field_images_1.split(',')[index]"></h4>
                      </div>
                    </div>
                  </div>
                </slick>
              </div>
            </div>

            <div v-if="paraindex == 2" class="cosmopolitan-images">
              <div class="container">
                <slick v-if="dataYT.length" ref="slick" :options="slickOptionsCerita" class="cerita-slide-items">
                  <div class="cerita-slide-item position-relative" v-for="(item, index) in dataYT" :key="index">
                    <a :href="item.link" target="_blank" class="svg-hover">
                      <img v-if="item.cover_image" :src="apiBasePath + item.cover_image" :alt="'cerita-item-' + index" class="img-fluid" width="100%">
                      <div class="ytp-button"><ytplay/></div>
                    </a>
                  </div>
                </slick>
              </div>
            </div>

            <div v-if="paraindex == 3" class="cosmopolitan-images">
              <RotateSlider/>
            </div>

            <div v-if="para.field_link">
              <div class="pwwrap pwstyle_01">
                <div class="para-link text-center">
                  <a v-if="paraindex == 0" class="pabutton_1" :href="para.field_link" target="_blank">Read More <img :src="btnNewTabBlack" alt="btn-newtab"></a>
                  <a v-else-if="paraindex == 1" class="pabutton_1 expand" :href="para.field_link" target="_blank">Check out more Street Photography <img :src="btnNewTabBlack" alt="btn-newtab"></a>
                  <a v-else-if="paraindex == 2" class="pabutton_1 expand" :href="para.field_link" target="_blank">Check out the full COKL Playlist <img :src="btnNewTabBlack" alt="btn-newtab"></a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from "jquery";
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'
import cosmoBg04 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-popup-bg-04.jpg'
import cosmoUDL from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-underline.png'
import btnNewTabBlack from '@/assets/ico-newtab-black.png'
import ytplay from '@/components/ico-yt-play.vue'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import SwiperCore, { EffectCards, Autoplay, Navigation } from "swiper";
SwiperCore.use([EffectCards, Autoplay, Navigation]);

import RotateSlider from '@/components/the-making-of-trx/cosmopolitant-04-people-food.vue'

$(".auto .swiper").mouseenter(function () {
  // console.log("stop autoplay");
  this.swiper.autoplay.stop();
});
$(".auto .swiper").mouseleave(function () {
  // console.log("start autoplay");
  this.swiper.autoplay.start();
});

export default {
  name: 'cosmo02',
  components: {
    Slick,
    Swiper,
    SwiperSlide,
    RotateSlider,
    ytplay,
  },
  data: function () {
    return {
      dataToken: [],
      dataYT: [],
      dataTokenParaInner: [],
      apiBasePath: 'https://trx-api.okie.my/',
      apiBaseURL: 'https://trx-api.okie.my',
      slickLeft: slickLeft,
      slickRight: slickRight,
      cosmoUDL: cosmoUDL,
      btnNewTabBlack: btnNewTabBlack,
      // YTplay: YTplay,
      slickOptionsCerita: {
        slidesToShow: 2,
        arrows: true,
        infinite: true,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      //swiperOptionsStreet: {
      //  simulateTouch: false,
      //  slidesPerView: 3,
      //  speed: 3000,
      //  spaceBetween: 25,
      //  zoom: true,
      //  loop: true,
      //  disableOnInteraction: true,
      //  // freeMode: true,
      //  // centerInsufficientSlides: true,
      //  // centeredSlides: true,
      //  autoplay: {
      //    delay: 0,
      //  },
      //  // navigation: {
      //    // nextEl: ".swiper-button-next",
      //    // prevEl: ".swiper-button-prev",
      //  // },
      //  breakpoints: {
      //    992: {
      //      slidesPerView: 2,
      //    },
      //    768: {
      //      slidesPerView: 1,
      //    }
      //  },
      //},
      slickOptionsStreet: {
        slidesToShow: 3,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
    }
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      axios.get('https://trx-api.okie.my/api/general/chapter/1/section/14').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        this.dataTokenBg = 'https://trx-api.okie.my' + datasource[1].images.split(',')[0];
        for (let i = 0; i < datasource.length; i++) {
          this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_content) );
        };
      });
      axios.get('https://trx-api.okie.my/api/general/chapter/1/section/17').then(response => response.data).then(datasource => {
        this.dataYT = datasource;
      });
    },
  },
  computed: {
    styleBg() {
      return {
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100%',
        'background-image': 'url(' + cosmoBg04 + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        // 'opacity': '.1',
        // 'filter': 'grayscale(100%)'
      }
    },
  },
};
</script>