<template>
  <div class="banner position-relative">
    <div class="banner-image position-relative d-block" data-aos="zoom-out">
      <img :src="styleBanner" :alt="title" class="img-fluid"
          width="100%">
    </div>
    <div
        class="banner-content position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
      <div class="pwwrap pwstyle_01">
        <div class="text text-center text-white">
          <h1 v-if="title" class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back"
              data-aos-duration="800">{{ title }}</h1>
          <img src="@/assets/underline-footer.png" alt="underline-footer" class="img-fluid" width="100%"
              data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">
          <div class="body" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"
              data-aos-delay="400" data-aos-duration="800">
            <slot name="description"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-next position-absolute text-center bottom-0 start-50 translate-middle-x"
        data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
      <a class="banner-scroll">
        <p>Explore TRX</p>
        <div class="mouse"></div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: ['title', 'styleBanner'],
}
</script>
