<template>
  <div class="map-wrap">
    <div class="map-border-top"><img src="@/assets/05-trx-today-tomorrow/border-top.png"
        alt="mapBorderTop" class="d-block mx-auto img-fluid"></div>
    <div class="map-content pfbgcolor_magentasoft">
      <div class="row mx-0">
        <div class="col-xl-8 col-lg-7 col-md-6 py-0">
          <div class="map-svg">
            <svg-future-plan-map/>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-6 py-0">
          <div class="map-text">
            <table border="0">
              <tr>
                <th>Areas</th>
                <th>GFA%</th>
              </tr>
              <tr v-for="item in areas" :data-target="item.name" class="map-target">
                <td class="d-table">
                  <div class="d-table-cell">
                    <img :src="item.dot" :alt="item.name" class="me-3">
                    {{ item.name }}
                  </div>
                </td>
                <td>
                  {{ item.gfa }}%
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>100%</td>
              </tr>
            </table>
            <div class="table-cell">
              <img src="@/assets/05-trx-today-tomorrow/rect-dash-orange.png" alt="Site Boundary"
                  class="me-3">
              Site Boundary
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map-border-btm"><img src="@/assets/05-trx-today-tomorrow/border-bottom.png"
        alt="mapBorderBtm" class="d-block mx-auto img-fluid"></div>
  </div>
</template>

<script>
import SvgFuturePlanMap from '@/components/trx-today-tomorrow/svg-future-plan-map.vue';
import $ from 'jquery';

export default {
  components: {SvgFuturePlanMap},
  data: function() {
    return {
      areas: [
        {
          dot: require('@/assets/05-trx-today-tomorrow/dot-dash-blue.png'),
          name: 'Office',
          gfa: '48',
        },
        {
          dot: require('@/assets/05-trx-today-tomorrow/dot-dash-purple.png'),
          name: 'Hotel',
          gfa: '10',
        },
        {
          dot: require('@/assets/05-trx-today-tomorrow/dot-dash-red.png'),
          name: 'Residential',
          gfa: '31',

        },
        {
          dot: require('@/assets/05-trx-today-tomorrow/dot-dash-orange.png'),
          name: 'Cultural',
          gfa: '1',

        },
        {
          dot: require('@/assets/05-trx-today-tomorrow/dot-dash-pink.png'),
          name: 'Mall',
          gfa: '10',

        },
      ],
    };
  },
  mounted: function() {
    $('.map-text .map-target').hover(function() {
      let hoverTarget = $(this).data('target');
      // console.log(hoverTarget)
      $('#' + hoverTarget).toggleClass('active');
      $('.stage-wrap:not(#' + hoverTarget + ')').toggleClass('hovered');
      $('.map-text .map-target').toggleClass('hovered');
    });
  },
};
</script>

<style lang="scss" scoped>
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-lg-max: 1199px;
$screen-md-max: 991px;
$screen-sm-max: 767px;
$screen-xs-max: 575px;

table {
  width: 100%;
}

th, td {
  padding-top: 5px;
  padding-bottom: 5px;
}

th {
  border-bottom: 2px solid #000;
}

tr:last-child td {
  border-top: 2px solid #000;
}

.map-wrap {
  font-size: 14px;
}

.map-border-top,
.map-border-btm {
  position: relative;
  z-index: 0;
}

.map-content {
  position: relative;
  z-index: 1;

  .map-svg {
    svg {
      width: 100%;
      @media (max-width: $screen-sm-max) {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }

  .map-text {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    @media (min-width: $screen-md-min) {
      padding-top: 45px;
      padding-bottom: 45px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: $screen-lg-min) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @media (min-width: $screen-xl-min) {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    table {
      width: 100%;
      color: #000;
      @media (min-width: $screen-md-min) {
        max-width: 390px;
      }

      th {
        font-weight: 700;

        h4 {
          font-size: 14px;
        }
      }

      td {
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;

        .d-inline-block {
          margin-right: 20px;
        }

        &:last-child {
          text-align: right;
        }
      }

      tr {
        &:nth-child(1) {
          border-bottom: 1px solid #fff;
        }

        // &:nth-child(2) { //     td { //         padding-top: 15px; //     } // }

        &:last-child {
          border-bottom: 1px solid #fff;
          // td { //     padding-bottom: 15px; // }
        }

        &:hover {
          &.map-target {
            cursor: pointer;
            // td { //     font-weight: 700; // }

            td:first-child:before {
              content: '';
              position: absolute;
              left: -12px;
              top: 11px;
              width: 0px;
              height: 0px;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-left: 6px solid white;
            }

            td:last-child:before {
              content: '';
              position: absolute;
              right: -12px;
              top: 11px;
              width: 0px;
              height: 0px;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-right: 6px solid white;
            }
          }
        }

        &:not(:hover).hovered {
          img {
            opacity: 0;
          }
        }
      }
    }
  }

  .map-img {
    margin-bottom: -10%;
    @media (min-width: $screen-md-min) {
      margin-bottom: -30%;
    }
  }
}
</style>