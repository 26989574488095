<template>
  <div class="ratio ratio-16x9">
    <iframe width="100%" :src="modalMemoVideo"></iframe>
  </div>
</template>

<script>
import modalMemoVideo from '@/assets/03-comm/Investor_Memorandum_Video_Final.mp4'

  export default {
    name: 'ModalMV',
    data: function () {
      return {
        modalMemoVideo: modalMemoVideo,
      };
    }
    // methods: {
      // close() {
        // this.$emit('closebv');
      // },
      // close2() {
      //   this.$emit('close');
      //   this.$emit('clickTwo');
      // },
    // },
  };
</script>