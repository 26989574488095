<template>
  <footer class="content-row"
      :style="`background-color: ${color}; background-image: url('${image}')`">
    <div class="footer-top position-relative">
      <div class="pwwrap pwstyle_03">
        <div class="footer-text text-center text-white">
          <h2 class="next-page-title pfcolor_white">{{ title }}</h2>
          <img :src="require('@/assets/underline-footer.png')" alt="underline-footer"
              class="img-fluid" width="100%">
          <router-link :to="path" class="pabutton_1 inverse">{{ path_text }}</router-link>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="row m-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="footer-l">
            <h6 class="modal-title">Copyright © {{ currentYear }} TRX City Sdn Bhd. All Rights
              Reserved.</h6>
          </div>
          <div class="footer-r">
            <ul class="navbar-nav d-flex justify-content-end flex-row">
              <li><a href="https://trx.my/terms-condition" class="d-block" target="_blank">Terms &
                Conditions</a></li>
              <li><a href="https://trx.my/privacy-policy" class="d-block" target="_blank">Privacy
                Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import styleBgFooter from '@/assets/bg-footer.svg';
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg';
import router from '@/router/index.js';

export default {
  data: function() {
    return {
      title: '',
      path: '',
      image: '',
      color: '',
      path_text: '',
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    renderFooter() {
      switch (this.$route.path) {
        case '/':
          this.title = 'Ideation';
          this.path = '/ideation';
          this.image = require('@/assets/the-making-of-trx/bg-footer-home.jpg');
          this.color = '#774088';
          this.path_text = 'Next Chapter';
          break;
        case '/ideation':
          this.title = 'Commercialisation';
          this.path = '/construction-collaboration';
          this.image = require('@/assets/bg-footer.svg');
          this.color = '#2B2B79';
          this.path_text = 'Next Chapter';
          break;
        case '/commercialisation':
          this.title = 'Construction & Collaboration';
          this.path = '/construction-collaboration';
          this.image = require('@/assets/03-comm/bg-footer-comm.jpg');
          this.color = '#2B2B79';
          this.path_text = 'Next Chapter';
          break;
        case '/construction-collaboration':
          this.title = 'TRX: Today & Tomorrow';
          this.path = '/trx-today-tomorrow';
          this.image = require('@/assets/04-cnc/malaya_batik.svg');
          this.color = '#FF9800';
          this.path_text = 'Next Chapter';
          break;
        case '/trx-today-tomorrow':
          this.title = 'The Making Of TRX';
          this.path = '/';
          this.image = require('@/assets/05-trx-today-tomorrow/bg-footer.png');
          this.color = '#E84B86';
          this.path_text = 'Return to Home';
          break;
        default:
          this.title = '';
          this.path = '';
          this.image = '';
          this.color = '';
          this.path_text = 'Next Chapter';
      }
    },
  },
  mounted() {
    this.renderFooter();
  },
  watch: {
    $route(to, from) {
      this.renderFooter();
    },
  },
};
</script>

<style scoped>
footer {
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-text h2 {
  margin-bottom: 15px;
}

.footer-text img {
  margin-bottom: 50px;
}

.footer-top {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.footer-bottom {
  background-color: rgba(0, 0, 0, .2);
  width: 100%;
  padding: 15px 30px;
}
</style>