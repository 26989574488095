<template>
  <div ref="gallery" class="position-relative">
    <Swiper class="swiper" :options="swiperOptions" @slideChange="onSlideChange">
      <SwiperSlide v-for="(item, index) in GalleryItems" :key="index"
          class="text-center">
        <a :href="item.img" target="_blank" rel="noreferrer">
          <img :src="item.img"
              :alt="item.alt" class="img-fluid">
        </a>
      </SwiperSlide>
    </Swiper>
    <div class="pwwrap pwstyle_03 mx-auto mt-3 text-end">
      <button @click="openCurrentSlideImage">
        <img :src="require('@/assets/ico-enlarge.png')" alt="Enlarge"/>
      </button>
    </div>
    <div class="swiper-prev swiper-arrow"><img :src="slickLeft">
    </div>
    <div class="swiper-next swiper-arrow"><img :src="slickRight">
    </div>
    <div class="pwwrap pwstyle_01">
      <div v-html="GalleryItems[currentSlide].alt" class="fs-6 text-center"></div>
    </div>
  </div>
</template>
<script>

import slickRight from '@/assets/05-trx-today-tomorrow/arrow-next-pink.png';
import slickLeft from '@/assets/05-trx-today-tomorrow/arrow-prev-pink.png';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

export default {
  name: 'PopupSwiperCardGallery',
  props: ['GalleryItems'],
  components: {Swiper, SwiperSlide},
  data: function() {
    return {
      slickLeft,
      slickRight,
      currentSlide: 0,
      swiperOptions: {
        effect: 'cards',
        cardsEffect: {
          slideShadows: false,
          transformEl: null,
        },
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
        grabCursor: true,
      },
    };
  },
  methods: {
    onSlideChange(swiper) {
      this.currentSlide = swiper.activeIndex;
    },
    openCurrentSlideImage() {
      this.lightbox.loadAndOpen(this.currentSlide, {
        gallery: this.$refs.gallery,
      });
    },
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: this.$refs.gallery,
        children: 'a',
        showHideAnimationType: 'fade',
        zoomAnimationDuration: false,
        pswpModule: PhotoSwipe,
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
};
</script>

<style scoped>
.swiper {
  position: relative;
}

.swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.swiper-prev {
  left: 0;
}

.swiper-next {
  right: 0;
}
</style>

<style>
.pswp__img {
  object-fit: contain !important;
}

.pswp__img--placeholder {
  display: none;
}
</style>