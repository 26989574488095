<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-1">December 2013</h2>
      <div class="text text-center mb-3">
        <div class="body">
          <p>Call for investors to submit proposal</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <div class="col-12"></div>
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center mb-3">INVESTMENT MEMORANDUM FOR STAGE 1 OF TRX DEVELOPMENT</h3>
            </div>

            <div class="pwwrap pwstyle_02 mb-3">
              <video id="videoMemorandum" controls width="100%">
                <source :src="videoMemorandumMOV">
              </video>
            </div>

            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>The Investment Memorandum provides prequalified investors with the opportunity to take part in Stage 1 of the TRX development. In Stage 1, the plots on offer to international investors includes Signature Tower, Prime Grade A Office Tower, Retail Mall, Two 5-star Hotels, and Three Residential Towers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

import videoMemorandumMOV from '@/assets/ideation/milestone/phase2-2013/Investor_Memorandum_Video_Final.mp4'

export default {
  name: 'IdeationTimelinePhase22013',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      videoMemorandumMOV: videoMemorandumMOV,
      MileClose: MileClose,
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>