<template>
  <section>
    <div class="container polaroid-stacked-gallery">
      <!-- <h1>lorem</h1> -->
      <div class="images">
        <img
          class="image img-fluid"
          :src="require(`@/assets/the-making-of-trx/home-07-cosmopolitan/${image.src}`)"
          :alt="image.alt"
          :description="image.description"
          :source="image.source"
          v-for="(image, index) in images"
          :key="index"
        />
      </div>
    </div>
    <div class="container polaroid-alt">
      <div class="pwwrap pwstyle_01">
        <div class="text-alt text-center">
          <h4>Oriental Building</h4>
          <p>The Oriental Building on Jalan Tun Perak was officially launched by the British Resident of Selangor, Mr. T.S. Adams in December 1932. The Straits Times reported the launch naming the 82 feet, five-storey building the “Tallest building in Kuala Lumpur”. The building was home to Radio Malaya until 1968, and had underground parking for bicycles.</p>
          <h6>Source: A.S.M.K. & Co. Singapore</h6>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import jQuery from 'jquery';
import $ from "jquery";

function randomRotate(deg) {
  let randomDeg = Math.ceil(Math.random() * deg);
  return (Math.floor(Math.random() * 2) === 0 ? "-" : "") + randomDeg;
}

export default {
  name: "HelloWorld",
  data() {
    return {
      images: [
        { 
          src: "home-07-cosmopolitan-sample-03-stack-01.jpg", 
          alt: "Lee Rubber",
          source: "Source: The Edge", 
          description: "The Lee Rubber Building at the corner of Jalan Tun H. S. Lee and Jalan Hang Lekir is a four-storey development built by the Lee Rubber Company in 1930. For years, the Lee Rubber Building housed the Popular Bookstore up till its sale in 2015. It is now the new Else Kuala Lumpur boutique hotel."
        },
        { 
          src: "home-07-cosmopolitan-sample-03-stack-02.jpg", 
          alt: "Central Market",
          source: "Source: Central Market", 
          description: "Originally built in 1888 as a wet market to serve the China Town area, the Central Market completed major reconstruction in 1937 where the building took on its Art Deco façade. The building, located on Jalan Tun Tan Cheng Lock and Jalan Hang Kasturi, has been classified as a Heritage Site by the Malaysian Heritage Society."
        },
        { 
          src: "home-07-cosmopolitan-sample-03-stack-03.jpg", 
          alt: "OCBC Building",
          source: "Source: The Edge", 
          description: "The OCBC Building at the junction of Jalan Hang Kasturi and Leboh Pasar near the Central Market, was built in 1937 to house the Oversea-Chinese Banking Corporation Limited’s HQ in Malaysia. The OCBC Building is now known as Urbanscapes House after its HQ moved to another building on Jalan Tun Perak."
        },
        { 
          src: "home-07-cosmopolitan-sample-03-stack-04.jpg", 
          alt: "Oriental Building",
          source: "Source: A.S.M.K. & Co. Singapore", 
          description: "The Oriental Building on Jalan Tun Perak was officially launched by the British Resident of Selangor, Mr. T.S. Adams in December 1932. The Straits Times reported the launch naming the 82 feet, five-storey building the “Tallest building in Kuala Lumpur”. The building was home to Radio Malaya until 1968, and had underground parking for bicycles."
        },
      ],
    };
  },
  mounted() {
    var k, image;
    image = document.querySelectorAll(".polaroid-stacked-gallery .image");

    for (k = 0; k < image.length; k++) {
      var rotateDeg = randomRotate(30);
      image[k].style.transform =
        "translate(-50%, -50%) rotate(" + rotateDeg + "deg)";
      image[k].style.webkitTransform =
        "translate(-50%, -50%) rotate(" + rotateDeg + "deg)";
      image[k].style.MozTransform =
        "translate(-50%, -50%) rotate(" + rotateDeg + "deg)";
      console.log(rotateDeg);
    }

    // var cssTransition, images, queue, timeout, touch;
    var cssTransition, imageOffset, imageWidth, images, queue, timeout, touch;
    cssTransition = function () {
      var body, i, len, style, vendor, vendors;
      body = document.body || document.documentElement;
      style = body.style;
      vendors = ["Moz", "Webkit", "O"];
      if (typeof style["transition"] === "string") {
        return true;
      }
      for (i = 0, len = vendors.length; i < len; i++) {
        if (window.CP.shouldStopExecution(1)) {
          break;
        }
        vendor = vendors[i];
        if (typeof style[vendor + "Transition"] === "string") {
          return true;
        }
      }
      window.CP.exitedLoop(1);
      return false;
    };
    queue = false;
    touch = document.documentElement["ontouchstart"] !== void 0;
    images = document.querySelector(".polaroid-stacked-gallery .images");
    imageWidth = images.firstElementChild.offsetWidth;
    imageOffset = images.firstElementChild.offsetLeft;
    timeout = cssTransition() ? [300, 400] : [0, 0];
    images.addEventListener(
      touch ? "touchend" : "click",
      function (event) {
        var direction, lastClassList;
        if (queue) {
          return;
        }
        queue = true;
        if (
          (touch ? event.changedTouches[0].pageX : event.pageX) - imageOffset >
          imageWidth / 2
        ) {
          direction = "slide-right";
        } else {
          direction = "slide-left";
        }
        lastClassList = images.lastElementChild.classList;
        lastClassList.add(direction);
        return setTimeout(function () {
          lastClassList.remove(direction);
          lastClassList.add("back");
          return setTimeout(function () {
            images.insertBefore(
              images.lastElementChild,
              images.firstElementChild
            );
            lastClassList.remove("back");
            return (queue = false);
          }, timeout[0]);
        }, timeout[1]);
      },
      false
    );
    $('.polaroid-stacked-gallery .images').click(function(){
      // var currentAlt = $(this).find('img:last-child').attr('alt');
      setTimeout(function() {
        var currentAlt = $('.polaroid-stacked-gallery .images').find('img:last-child').attr('alt');
        var currentDesc = $('.polaroid-stacked-gallery .images').find('img:last-child').attr('description');
        var currentSource = $('.polaroid-stacked-gallery .images').find('img:last-child').attr('source');
      $('.polaroid-alt .text-alt h4').text(currentAlt);
        console.log(currentAlt)
      $('.polaroid-alt .text-alt p').text(currentDesc);
      $('.polaroid-alt .text-alt h6').text(currentSource);
      }, 800);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.polaroid-stacked-gallery {
  height: 70vh;
  position: relative;
}

.polaroid-stacked-gallery .images {
  /* position: relative; */
  cursor: pointer;
}

.polaroid-stacked-gallery .image {
  position: absolute;
  object-fit: cover;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 300px;
  /* width: 30%; */
  /* margin-top: -110px; */
  /* margin-left: -205px; */
  -moz-box-shadow: 0 2px 5px rgba(30, 30, 30, 0.25);
  -webkit-box-shadow: 0 2px 5px rgba(30, 30, 30, 0.25);
  box-shadow: 0 2px 5px rgba(30, 30, 30, 0.25);
  z-index: 2;
  -moz-transition: -moz-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

/* .image:last-child {
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.images .image:nth-child(4) {
  -moz-transform: rotate(10deg) translateX(50px);
  -ms-transform: rotate(10deg) translateX(50px);
  -webkit-transform: rotate(10deg) translateX(50px);
  transform: rotate(10deg) translateX(50px);
}

.images .image:nth-child(3) {
  -moz-transform: rotate(3deg) translateX(75px);
  -ms-transform: rotate(3deg) translateX(75px);
  -webkit-transform: rotate(3deg) translateX(75px);
  transform: rotate(3deg) translateX(75px);
}

.images .image:nth-child(2) {
  -moz-transform: rotate(-2deg) translateX(-50px);
  -ms-transform: rotate(-2deg) translateX(-50px);
  -webkit-transform: rotate(-2deg) translateX(-50px);
  transform: rotate(-2deg) translateX(-50px);
}

.images .image:first-child {
  -moz-transform: rotate(-8deg) translateX(-75px) translateY(-10px);
  -ms-transform: rotate(-8deg) translateX(-75px) translateY(-10px);
  -webkit-transform: rotate(-8deg) translateX(-75px) translateY(-10px);
  transform: rotate(-8deg) translateX(-75px) translateY(-10px);
} */

/* .image.slide-right {
  -moz-transform: rotate(290deg) translateX(250px) !important;
  -ms-transform: rotate(290deg) translateX(250px) !important;
  -webkit-transform: rotate(290deg) translateX(250px) !important;
  transform: rotate(290deg) translateX(250px) !important;
}

.image.slide-left {
  -moz-transform: rotate(-290deg) translateX(-250px) !important;
  -ms-transform: rotate(-290deg) translateX(-250px) !important;
  -webkit-transform: rotate(-290deg) translateX(-250px) !important;
  transform: rotate(-290deg) translateX(-250px) !important;
} */

.polaroid-stacked-gallery .image.slide-right {
  -moz-transform: translate(-10%, -80%) !important;
  -ms-transform: translate(-10%, -80%) !important;
  -webkit-transform: translate(-10%, -80%) !important;
  transform: translate(-10%, -80%) !important;
}

.polaroid-stacked-gallery .image.slide-left {
  -moz-transform: translate(-80%, -80%) !important;
  -ms-transform: translate(-80%, -80%) !important;
  -webkit-transform: translate(-80%, -80%) !important;
  transform: translate(-80%, -80%) !important;
}

.polaroid-stacked-gallery .image.back {
  z-index: 1;
}

@media (max-width: 991px) {
  .polaroid-stacked-gallery .image {
    width: 80%;
    height: 50%;
  }

  .polaroid-stacked-gallery .image.slide-right,
  .polaroid-stacked-gallery .image.slide-left {
    -moz-transform: translate(-50%, -90%) !important;
    -ms-transform: translate(-50%, -90%) !important;
    -webkit-transform: translate(-50%, -90%) !important;
    transform: translate(-50%, -90%) !important;
  }
}
</style>
