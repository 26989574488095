<template>
        <div>

          <div class="pwwrap pwstyle_03">
            <h2 class="modal-title text-center">Timeline of<br>Commercialisation Milestones</h2>
          </div>

          <div class="milestones-content" :style="styleBgMilesModal">

            <div class="milestones-list">

              <div class="container">

                <div class="row" v-for="(item, index, id) in dataToken" :key="index" v-if="index == 0">
                  <div class="col-md-6 milestones-item" v-for="(para, paraindex) in dataTokenParaInner[index]" v-bind:class="{'offset-md-6': paraindex % 2 !== 0}">
                    <div v-if="para.image" class="cover">
                      <img :src="apiBasePath + para.image" :alt="'milestones-phase-2-' + [paraindex + 1]">
                    </div>
<!--                    <div v-if="para.title || para.body" class="text" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50">-->
                    <div v-if="para.title || para.body" class="text">
                      <div v-if="para.title" class="content-title":inner-html.prop="para.title"></div>
                      <div v-if="para.body" class="body" :inner-html.prop="para.body">
                      </div>
                    </div>
                    <!-- <div v-if="para.link" class="link"> -->
                    <div class="link">
                      <!-- <a :href="para.link" class="text-center pabutton_1 inverse">Discover More <img :src="btnNewTab" alt="btn-newtab"></a> -->
                      <a v-if="paraindex == 0" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner0Modal'">View excerpts from Investment Memorandum <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 1" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner1Modal'">View excerpts from Lendlease proposal document <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 2" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner2Modal'">View photos <img :src="btnNewTab" alt="btn-newtab"></a>
                      <!-- <a v-if="paraindex == 3" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesPhase1inner' + paraindex + 'Modal'">View photos <img :src="btnNewTab" alt="btn-newtab"></a> -->
                      <a v-if="paraindex == 3" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner3Modal'">View newspaper clipping <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 4" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner4Modal'">View newspaper clipping <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 5" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner5Modal'">View newspaper clipping <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 6" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner6Modal'">View newspaper clipping <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 7" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner7Modal'">View photos <img :src="btnNewTab" alt="btn-newtab"></a>
                      <a v-if="paraindex == 8" class="btn-modal pabutton_1 inverse expand" v-b-modal="'milestonesInner8Modal'">View photos <img :src="btnNewTab" alt="btn-newtab"></a>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>
</template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'

import styleBgFooter from '@/assets/bg-footer.svg'
import faviconPath from '@/assets/favicon.png'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/03-comm/comm-03-milestone-modal-bg-01.jpg'

export default {
  name: 'IdeationTimelinePhase1',
  // components: {
    // phase1inner1
  // },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      dataToken: [],
      dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      MileClose: MileClose,
    };
  },
  computed: {
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  methods: {
    getData: function () {
      axios.get('https://trx-api.okie.my/api/general/chapter/3/section/9').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
        for (let i = 0; i < datasource.length; i++) {
          this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_timeline.replace(/\\/g, '')) );
        };

      });
    },
    showModalITL2() {
      this.$emit('showModalITL2');
    },
    close() {
      this.$emit('openITLSecond');
    },
  },
}
</script>