<template>
  <div class="cosmopolitan-content" v-if="dataToken[0]">

    <div :style="styleBg"></div>
    <div class="position-relative container">
      <div class="row">
        <div class="col-12 py-0">
          <div class="text text-center">
            <h2 class="modal-title pfcolor_maroon" :inner-html.prop="dataToken[0].title"></h2>
          </div>
        </div>
        <div class="col-12 py-0" v-if="dataTokenParaInner[0][0].field_body">
          <div class="para-content">
            <div class="pwwrap pwstyle_02">
              <h5 class="para-title text-center" :inner-html.prop="dataTokenParaInner[0][0].field_title"></h5>
              <img class="para-title-udl" :src="cosmoUDL" alt="underline">
            </div>
            <div class="pwwrap pwstyle_01">
              <div class="body" :inner-html.prop="dataTokenParaInner[0][0].field_body"></div>
            </div>
            <div class="pwwrap pwstyle_01">
              <div class="para-link text-center">
                <a class="pabutton_1" :href="dataTokenParaInner[0][0].field_link" target="_blank">Read More <img :src="btnNewTabBlack" alt="btn-newtab"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 py-0">
          <div class="cosmopolitan-zoom-image">
            <div class="zoom-bg">
              <div><img :src="cosmoZoom01" alt="cosmoZoom01"></div>
              <div><img :src="cosmoZoom02" alt="cosmoZoom02"></div>
              <div><img :src="cosmoZoom03" alt="cosmoZoom03"></div>
            </div>
            <div class="para-images">
              <img :src="apiBasePath + dataTokenParaInner[0][0].field_images" alt="main-image" class="img-fluid">
            </div>
          </div>
        </div>
        <div class="col-12 py-0">
          <div class="pwwrap pwstyle_01">
            <div class="body"><h6 class="me-lg-5 pe-lg-5">The Market Square (Medan Pasar) in the 1950s, sourced from SkyscraperCity via ResearchGate</h6></div>
          </div>
        </div>
      </div>
    </div>
<!-- {{ dataTokenParaInner[0][0].field_images }} -->
  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from "jquery";
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'
import cosmoBg01 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-popup-bg-01.jpg'
import cosmoUDL from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-underline.png'
import btnNewTabBlack from '@/assets/ico-newtab-black.png'
import cosmoZoom01 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-01-popup-01.jpg'
import cosmoZoom02 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-01-popup-02.jpg'
import cosmoZoom03 from '@/assets/the-making-of-trx/home-07-cosmopolitan/home-07-cosmo-01-popup-03.jpg'

export default {
  name: 'cosmo01',
  components: {
    Slick,
  },
  data: function () {
    return {
      dataToken: [],
      dataTokenBg: [],
      dataTokenContentInner: [],
      dataTokenParaInner: [],
      apiBasePath: 'https://trx-api.okie.my/',
      slickLeft: slickLeft,
      slickRight: slickRight,
      cosmoUDL: cosmoUDL,
      btnNewTabBlack: btnNewTabBlack,
      cosmoZoom01: cosmoZoom01,
      cosmoZoom02: cosmoZoom02,
      cosmoZoom03: cosmoZoom03,
    }
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      axios.get('https://trx-api.okie.my/api/general/chapter/1/section/14').then(response => response.data).then(datasource => {
        this.dataToken = datasource;
        this.dataTokenBg = 'https://trx-api.okie.my' + datasource[0].images.split(',')[0];
        this.dataTokenContentInner = datasource[0].paragraph_content.replace(/\\/g, '');
        // for (let e = 0; e < datasource.length; e++) {
        //   this.dataTokenContentInner.push( JSON.parse(datasource[e].paragraph_content.replace(/\\/g, '')) );
        // };
        for (let i = 0; i < datasource.length; i++) {
          this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_content) );
        };
      });
    },
  },
  computed: {
    styleBg() {
      return {
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100%',
        'background-image': 'url(' + cosmoBg01 + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        // 'opacity': '.1',
        // 'filter': 'grayscale(100%)'
      }
    },
  },
};
</script>