<template>
  <a :href="link" target="_blank">
    <div class="image">
      <img :src="imageUrl"
          :alt="title">
    </div>
    <div
        class="position-absolute d-flex align-items-end start-0 top-0 bottom-0 end-0 text-center justify-content-center">
      <div class="text">
        <h4 v-html="title"></h4>
        <div class="read-more">Read More <img :src="require('@/assets/ico-newtab.png')"
            alt="btn-newtab">
        </div>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  name: 'ItemInfoCardA',
  props: {
    title: {},
    link: {},
    imageUrl: {},
  },
};
</script>

<style lang="scss" scoped>
.image {
  position: relative;
  display: block;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(10deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 95%);
    background: -webkit-linear-gradient(10deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 95%);
    background: linear-gradient(10deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 95%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ffffff", GradientType=1);
  }

  img {
    width: 100%;
  }
}

.text {
  padding: 30px;
  color: #fff;

  h4 {
    margin-bottom: 20px;
  }

  .read-more {
    font-size: 16px;
    position: relative;
    display: block;

    img {
      display: inline-block;
      margin-left: 5px;
    }
  }
}
</style>
