<template>
  <div>

    <div class="pwwrap pwstyle_01">
      <h2 class="modal-title text-center mb-3">June 2017</h2>
      <div class="text text-center mb-3">
        <div class="body">
          <p>HSBC invests US$250 million to construct new HQ in TRX</p>
        </div>
      </div>
    </div>

    <div class="milestones-year-content" :style="styleBgMilesModal">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"></div> -->
          <!-- <div class="col-md-8 offset-md-2"></div> -->
          <div class="col-12">

            <div class="pwwrap pwstyle_01">
              <h3 class="modal-title text-center mb-3">HSBC MAKES US$250 MILLION INVESTMENT FOR NEW HQ</h3>
            </div>

            <div class="pwwrap pwstyle_02 mb-3">
              <video controls width="100%">
                <source :src="videoHSBC">
              </video>
            </div>

            <div class="pwwrap pwstyle_01 mb-5">
              <div class="text">
                <div class="body">
                  <p>A US$250 million investment to construct HSBC Malaysia’s future headquarter office in TRX underscores HSBC’s commitment to develop Malaysia as a financial hub in the ASEAN region. HSBC and TRX City signed a sale-and-purchase agreement for the development of HSBC Malaysia’s future HQ at the upcoming international financial district Tun Razak Exchange (TRX), marking the first phase of the land acquisition. The US$250 million investment for the future HSBC Malaysia TRX headquarters with a minimum office space of 568,000 square feet, makes HSBC the first foreign bank to invest in the TRX development.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import jQuery from 'jquery';
import AOS from 'aos';
import axios from 'axios'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'

import styleBgFooter from '@/assets/bg-footer.svg'
import btnNewTab from '@/assets/ico-newtab.png'

import MileClose from '@/assets/ico-milestones-modal-close.png'
import MileModalBG from '@/assets/ideation/ideation-09-milestone-modal-bg-01.jpg'

import videoHSBC from '@/assets/ideation/milestone/phase2-2017-01/TRX_Signing_Ceremony_HSBC_June_2017.mp4'

export default {
  name: 'IdeationTimelinePhase2201701',
  components: {
    Slick,
  //   phase1inner1
  },
  // props: {
    // msg: String
  // }
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      // dataToken: [],
      // dataTokenParaInner: [],
      btnNewTab: btnNewTab,
      slickLeft: slickLeft,
      slickRight: slickRight,
      videoHSBC: videoHSBC,
      MileClose: MileClose,
    };
  },
  computed: {
    styleBgFooterCss() {
      return {
        'background-image': 'url(' + styleBgFooter + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }
    },
    styleBgMilesModal() {
      return {
        'background-image': 'url(' + MileModalBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% auto',
        'background-position': 'center top',
      }
    },
  },
  mounted: function () {
    // this.getData();
    // $(window).on('load',function(){AOS.refresh();});
  },
  // methods: {
  //   close() {
  //     this.$emit('openITLSecond');
  //   },
  // },
}
</script>