<template>
  <div class="page page-home">

    <!-- <div :style="styleThis"> -->

    <!-- <HelloWorld class="text-center" msg="Welcome to Your Vue.js App"/> -->

    <div class="banner position-relative">
      <div class="banner-image position-relative d-block" data-aos="zoom-out">
        <img :src="styleHeroBanner" alt="banner-ideation" class="img-fluid" width="100%">
      </div>
      <div
          class="banner-content position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
        <div class="pwwrap pwstyle_01">
          <div class="text text-center text-white">
            <!-- <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">Ideation</h1> -->
            <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back"
                data-aos-duration="800">The Making of TRX</h1>
            <img :src="styleUDFooter" alt="underline-footer" class="img-fluid" width="100%"
                data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">
            <div class="body" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50"
                data-aos-delay="400" data-aos-duration="800">
              <p>The Making of TRX documents the journey of Tun Razak Exchange, Kuala Lumpur’s new
                CBD, from the early stages of conceptualisation to the current progress.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-next position-absolute text-center bottom-0 start-50 translate-middle-x"
          data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
        <a class="banner-scroll">
          <p>Explore TRX</p>
          <!-- <img :src="styleBannerNext" alt="banner-next-arrow"> -->
          <div class="mouse"></div>
        </a>
      </div>
    </div>

    <!-- content -->
    <div class="home content-home">

      <div class="content-bg-list">
        <!-- <div class="cr-09 content-bg pfbgcolor_white"><div></div></div> -->
        <div class="cr-08 content-bg pfbgcolor_white">
          <div></div>
        </div>
        <div class="cr-07 content-bg pfbgcolor_bluelight">
          <div></div>
        </div>
        <div class="cr-06 content-bg pfbgcolor_purpledarker">
          <div></div>
        </div>
        <div class="cr-05 content-bg pfbgcolor_greydarker">
          <div></div>
        </div>
        <div class="cr-04 content-bg pfbgcolor_beige">
          <div></div>
        </div>
        <div class="cr-03 content-bg pfbgcolor_white">
          <div></div>
        </div>
        <div class="cr-02 content-bg" :style="styleIntro"></div>
      </div>

      <section class="content-row" id="cr-01">
        <div class="intro" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p><span class="pfstyle_s64">T</span>his platform chronicles the challenges, the
                    milestones, and the stories of the people working behind the scene.</p>
                  <p>It serves as a guide and resource centre to understand the role TRX will play
                    in the global economy.</p>
                  <p>Be a witness to this mammoth undertaking as we transform KL’s cityscape with a
                    70-acre integrated development that will be known as Malaysia’s International
                    Financial District.</p>
                  <p>TRX is a city-changing project that will expand the CBD boundaries further
                    Southeast from the existing Golden Triangle, linking up different developments
                    such as KLCC and Bukit Bintang. Its iconic 10-acre public park, extensive
                    network of pedestrian walkways, and sustainable infrastructure will bring life
                    to the area, revealing new sides to discover and showing that KL has more to
                    offer.</p>
                  <p>Set to be Malaysia’s international financial centre and KL’s new lifestyle
                    destination, TRX is the latest evolution of the capital.</p>
                </div>
              </div>
            </div>

            <!-- <div class="pwwrap pwstyle_01">
              <div class="text">
                <h3 class="content-title pfcolor_purple text-center">Highlights</h3>
              </div>
            </div>
            <div class="gallery-highlights">

              <swiper :effect="'cards'" :grabCursor="true" class="swiper gallery-highlights-images" ref="mySwiper" :options="swiperOptionsGalleryHighlights">
                <swiper-slide v-for="(image, index, id) in dataToken" :key="index" v-if="image.section_id == 15">
                  <div class="gallery-highlights-wrap">
                    <div class="gallery-highlights-image">
                      <img v-if="image.cover_image" :src="apiBasePath + image.cover_image" :alt="'gallery-highlights-image-' + index">
                    </div>
                    <div class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
                      <div class="text text-center">
                        <div v-if="image.icon" class="icon"><img :src="apiBasePath + image.icon" alt="gallery-highlights-icon" class="img-fluid"></div>
                        <h3 v-if="image.title" :inner-html.prop="image.title"></h3>
                        <div v-if="image.summary" class="body">{{ image.desc }}</div>
                        <a class="pabutton_1 inverse">Play Video <img :src="btnPlayVideo" alt="btn-newtab"></a>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div class="text text-center">
              <a class="pabutton_1">View Full Video</a>
            </div> -->

          </div>
        </div>
      </section>

      <section class="content-row" id="cr-02">
        <!-- <div class="intro-02" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>TRX is a city-changing project that will expand the CBD boundaries further Southeast from the existing Golden Triangle, linking up different developments such as KLCC and Bukit Bintang. Its iconic 10-acre public park, extensive network of pedestrian walkways, and sustainable infrastructure will bring life to the area, revealing new sides to discover and showing that KL has more to offer.</p>
                  <p>Set to be Malaysia’s international financial centre and KL’s new lifestyle destination, TRX is the latest evolution of the capital.</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </section>

      <section class="content-row" id="cr-03">
        <div class="testimonial" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_02">
              <div class="row">
                <div class="col-md-8">
                  <div class="text">
                    <blockquote class="purple">With the arrival of Tun Razak Exchange in the heart
                      of the Golden Triangle, we move one step closer towards realising this vision;
                      a city that is resilient, innovative, inclusive, low-carbon, efficient,
                      sustainable, and liveable for all.
                    </blockquote>
                    <div class="body">
                      <h6>Datuk Seri Mahadi Che Ngah<br>Former Mayor of Kuala Lumpur</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <img :src="testimonial" alt="testimonial" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- parax -->
      <section class="content-row" id="cr-04">
        <div class="history" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p><span class="pfstyle_s64">K</span>uala Lumpur has not always been the bustling
                    city that it is today. It was once a sleepy village, nestled in the confluence
                    of two rivers, Sungai Gombak and Sungai Klang. A boom in mining activities
                    sparked rapid growth and a rise in population.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="history-images">
            <img :src="historyBg01" alt="historyBg01" width="100%" class="history-image-01"><!-- 
            <img :src="historyBg02" alt="historyBg02" width="100%" class="history-image-02">
            <img :src="historyBg03" alt="historyBg03" width="100%" class="history-image-03"> -->
            <img :src="historyBg02long" alt="historyBg02" width="100%" class="history-image-02">
            <img :src="historyBg03long" alt="historyBg03" width="100%" class="history-image-03"><!-- 
            <img :src="historyBg02high" alt="historyBg02" width="100%" class="history-image-02">
            <img :src="historyBg03high" alt="historyBg03" width="100%" class="history-image-03"> -->
            <p class="p-3 mb-0">A panoramic view of Kuala Lumpur in 1884, sourced from A Vision of
              the Past by G.R. Lambert & Co</p>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-04">
        <div class="compare pfbgcolor_greydarker_fade" data-aos="fade-down">
          <div class="container">
            <ImageComparisonSlider :before-img-src="compare02" :after-img-src="compare01"
                :before-img-desc="compare02Desc" :after-img-desc="compare01Desc"
                :body-text="compareBody"/>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-05">
        <div class="cosmopolitan text-white" :style="styleBench" data-aos="fade-down">
          <div class="container">
            <div class="row">
              <div class="col-12 py-0">
                <div class="text">
                  <h2 class="content-title pfcolor_white">Cosmopolitan KL</h2>
                </div>
              </div>
              <div class="col-lg-4 col-md-5">
                <div class="text">
                  <div class="body">
                    <p><span class="pfstyle_s64">K</span>uala Lumpur is home to a diverse community.
                      It’s a city that excels in various sectors including trade, finance,
                      transportation, manufacturing, I.T. and the creative industries. As the
                      country’s economic and cultural centre, KL has all the makings of a modern
                      cosmopolitan. </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-7">
                <slick v-if="dataToken.length" ref="slick" :options="slickOptionsCosmopolitan"
                    class="cosmopolitan-slide-items">
                  <div class="cosmopolitan-slide-item position-relative"
                      v-for="(item, index) in dataToken" :key="index"
                      v-if="item.section == 'Cosmopolitan KL' && item.section_id == 14">
                    <div class="cosmopolitan-slide-img">
                      <img v-if="item.cover_image" :src="apiBasePath + item.cover_image"
                          :alt="'cosmopolitan-item-' + index" class="img-fluid">
                    </div>
                    <div
                        class="position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0 text-center justify-content-center">
                      <div class="text">
                        <div v-if="item.icon" class="icon"><img :src="apiBasePath + item.icon"
                            alt="cosmopolitan-icon"
                            class="img-fluid mx-auto"></div>
                        <h3 v-if="item.title" :inner-html.prop="item.title"></h3>
                        <div v-if="item.summary" class="body" :inner-html.prop="item.summary"></div>
                        <a class="btn-modal pabutton_1 inverse"
                            v-b-modal="'cosmopolitan' + [item.nid] + 'Modal'">Discover More</a>
                      </div>
                    </div>
                  </div>
                </slick>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="content-modal">
      <b-modal modal-class="cosmopolitan-modal" id="cosmopolitan31Modal" title-sr-only hide-header
          hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="cosmopolitan-close">
            <a class="btn-close" @click="close()">
              <img :src="modalClose" alt="mile-close-modal">
            </a>
          </div>
          <cosmo01/>
          <div class="modal-toggle-links">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <div class="modal-toggle-prev">
                    <a class="btn-modal-toggle" @click="close()">
                      <h5>Back</h5>
                      <h3>Chapter 1: The Making of TRX</h3>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="modal-toggle-next">
                    <a class="btn-modal-toggle" v-b-modal="'cosmopolitan30Modal'">
                      <h5>Next</h5>
                      <h3>Economics</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
      <b-modal modal-class="cosmopolitan-modal" id="cosmopolitan30Modal" title-sr-only hide-header
          hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="cosmopolitan-close">
            <a class="btn-close" @click="close()">
              <img :src="modalClose" alt="mile-close-modal">
            </a>
          </div>
          <cosmo02/>
          <div class="modal-toggle-links pfbgcolor_purplelight position-sticky bottom-0"
              style="transform: translateY(100%)">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <div class="modal-toggle-prev">
                    <a class="btn-modal-toggle text-white" @click="close()">
                      <h5>Back</h5>
                      <h3>Chapter 1: The Making of TRX</h3>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="modal-toggle-next">
                    <a class="btn-modal-toggle text-white" v-b-modal="'cosmopolitan29Modal'">
                      <h5>Next</h5>
                      <h3>Architecture & Sustainability</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
      <b-modal modal-class="cosmopolitan-modal" id="cosmopolitan29Modal" title-sr-only hide-header
          hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="cosmopolitan-close">
            <a class="btn-close" @click="close()">
              <img :src="modalClose" alt="mile-close-modal">
            </a>
          </div>
          <cosmo03/>
          <div class="modal-toggle-links pfbgcolor_pink">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <div class="modal-toggle-prev">
                    <a class="btn-modal-toggle" @click="close()">
                      <h5>Back</h5>
                      <h3>Chapter 1: The Making of TRX</h3>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="modal-toggle-next">
                    <a class="btn-modal-toggle" v-b-modal="'cosmopolitan28Modal'">
                      <h5>Next</h5>
                      <h3>People & Culture</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
      <b-modal modal-class="cosmopolitan-modal" id="cosmopolitan28Modal" title-sr-only hide-header
          hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="cosmopolitan-close">
            <a class="btn-close" @click="close()">
              <img :src="modalClose" alt="mile-close-modal">
            </a>
          </div>
          <cosmo04/>
          <div class="modal-toggle-links pfbgcolor_greydark">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <div class="modal-toggle-prev">
                    <a class="btn-modal-toggle text-white" @click="close()">
                      <h5>Back</h5>
                      <h3>Chapter 1: The Making of TRX</h3>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <!-- <div class="modal-toggle-next"> -->
                  <!-- Chapter 2: The Start of trx -->
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
    </div>

  </div>
</template>

<script>
import slickRight from '@/assets/arrow-next-transparent.png';
import slickLeft from '@/assets/arrow-prev-transparent.png';
import modalClose from '@/assets/ico-home-modal-close.png';
import btnNewTab from '@/assets/ico-newtab.png';
import styleBannerNext from '@/assets/ideation/ideation-01-banner-arrow.png';
import styleBgBench from '@/assets/ideation/ideation-06-bg-benchmarking.png';
import styleBgFooter from '@/assets/the-making-of-trx/bg-footer-home.jpg';
import styleHeroBanner from '@/assets/the-making-of-trx/home-01-banner.jpg';
import styleBgIntro from '@/assets/the-making-of-trx/home-02-bg-intro.jpg';
import btnPlayVideo from '@/assets/the-making-of-trx/home-03-highlights-btn-playvideo.png';
import testimonial from '@/assets/the-making-of-trx/home-04-testimonial.png';
import historyBg01 from '@/assets/the-making-of-trx/home-05-panoramic-layer-01.jpg';
import historyBg02high from '@/assets/the-making-of-trx/home-05-panoramic-layer-02-high.png';
import historyBg02long from '@/assets/the-making-of-trx/home-05-panoramic-layer-02-long.png';
import historyBg02 from '@/assets/the-making-of-trx/home-05-panoramic-layer-02.png';
import historyBg03high from '@/assets/the-making-of-trx/home-05-panoramic-layer-03-high.png';
import historyBg03long from '@/assets/the-making-of-trx/home-05-panoramic-layer-03-long.png';
import historyBg03 from '@/assets/the-making-of-trx/home-05-panoramic-layer-03.png';
import compare01 from '@/assets/the-making-of-trx/home-06-compare-02.jpg';
import compare02 from '@/assets/the-making-of-trx/home-06-compare-01.jpg';

import styleUDFooter from '@/assets/underline-footer.png';
import Footer from '@/components/Footer.vue';

import cosmo01 from '@/components/the-making-of-trx/cosmopolitant-01-history-kl.vue';
import cosmo02 from '@/components/the-making-of-trx/cosmopolitant-02-economics.vue';
import cosmo03 from '@/components/the-making-of-trx/cosmopolitant-03-archi.vue';
import cosmo04 from '@/components/the-making-of-trx/cosmopolitant-04-people.vue';
import ImageComparisonSlider from '@/components/ImageComparisonSlider.vue';
import axios from 'axios';
import {TweenMax} from 'gsap';
import 'slick-carousel/slick/slick.css';
import SwiperCore, {Autoplay, EffectCards, Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
// import HelloWorld from '@/components/HelloWorld.vue'
// @ is an alias to /src
import Slick from 'vue-slick';

SwiperCore.use([EffectCards, Autoplay, Navigation]);

// import styleImage from '@/assets/osboot_sample_texture_500x500__01.jpg'

export default {
  name: 'Home',
  components: {
    Footer,
    ImageComparisonSlider,
    // HelloWorld
    Slick,
    cosmo01,
    cosmo02,
    cosmo03,
    cosmo04,
  },
  metaInfo() {
    return {
      title: 'Home',
    };
  },
  computed: {
    // custom inline style
    // styleThis() {
    //   return {
    //     'background-image': 'url(' + styleImage + ')',
    //     'background-repeat': 'no-repeat',
    //     'background-size': 'cover'
    //   }
    // }
    styleIntro() {
      return {
        'background-image': 'url(' + styleBgIntro + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        'background-position': 'center top',
      };
    },
    styleBench() {
      return {
        'background-image': 'url(' + styleBgBench + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        'background-position': 'right center',
      };
    },
  },
  data: function() {
    return {
      dataToken: [],
      dataTokenParaInner: [],
      apiBasePath: 'https://trx-api.okie.my/',
      btnNewTab: btnNewTab,
      modalClose: modalClose,
      styleBgFooter: styleBgFooter,
      styleUDFooter: styleUDFooter,
      styleHeroBanner: styleHeroBanner,
      styleBannerNext: styleBannerNext,
      styleBgIntro: styleBgIntro,
      btnPlayVideo: btnPlayVideo,
      historyBg01: historyBg01,
      historyBg02: historyBg02,
      historyBg02long: historyBg02long,
      historyBg02high: historyBg02high,
      historyBg03: historyBg03,
      historyBg03long: historyBg03long,
      historyBg03high: historyBg03high,
      testimonial: testimonial,
      compare01: compare01,
      compare02: compare02,
      compare01Desc: 'The skyline of Kuala Lumpur has changed with modern towers rising behind Sultan Abdul Samad Building',
      compare02Desc: 'An early photo of the Sultan Abdul Samad Building sourced from G.R. Lambert & Co via Leiden University Digital Collections',
      compareBody: 'A city of contrasts with a blend of the old and new, Kuala Lumpur continues to experience urban growth and renewal with the arrival of Tun Razak Exchange, the next evolution of the CBD.',
      slickOptionsCosmopolitan: {
        slidesToShow: 2,
        arrows: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      swiperOptionsGalleryHighlights: {
        effect: 'cards',
        cardsEffect: {
          slideShadows: false,
          transformEl: null,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        initialSlide: 1,
      },
    };
  },
  mounted: function() {
    this.getData();
    this.$root.$on('bv::modal::shown', function() {
      $('html').css('overflow', 'hidden');
      // AOS.refresh();
    }).$on('bv::modal::hidden', function() {
      // AOS.init({disable:false});
      $('html').css('overflow', 'auto');
    });
    // const wrapScene = this.$scrollmagic.scene({
    //   triggerElement: '.page-home',
    //   triggerHook: 0.15,
    //   duration: '50%'
    // })
    //   .setTween(
    //     TweenMax.from('.banner', 1, {
    //       css: {
    //         left: '-100%',
    //         overflowY: 'hidden'
    //       }
    //     })
    //   )
    //   .addIndicators()
    // const timeline = new TimelineMax()
    // timeline.add([
    //   TweenMax.to('#cr-01', 1, {
    //     css: {
    //       top: '95%',
    //       marginTop: '-50px',
    //       borderRadius: '50%',
    //       scale: '0.5'
    //     }
    //   }),
    //   TweenMax.fromTo('#cr-02', 1,
    //     {
    //       scale: '0.2'
    //     },
    //     {
    //       scale: '1.8',
    //       borderWidth: 0
    //     }
    //   ),
    //   TweenMax.to('#cr-03', 1, {
    //     css: {
    //       bottom: '95%',
    //       marginBottom: '-50px',
    //       borderRadius: '50%',
    //       scale: '0.5'
    //     }
    //   })
    // ])
    // const shapeScene = this.$scrollmagic.scene({
    //   triggerElement: '#app',
    //   triggerHook: 0.5,
    //   duration: '100%'
    // })
    //   .setTween(timeline)
    //   .addIndicators()
    // // add scenes to window scrollmagic controller
    // this.$scrollmagic.addScene([wrapScene, shapeScene])

    const scene1 = this.$scrollmagic.scene({
      triggerElement: '.banner-content',
      triggerHook: 0,
      duration: '50%',
    }).setTween(
        TweenMax.to('.banner-content .pwwrap', 1, {
          css: {
            marginTop: '-200px', // the tween durtion can be omitted and defaults to 1
          },
        }),
    );
    // .addIndicators({ name: '2 (banner)' });

    const scene2 = this.$scrollmagic.scene({
      triggerElement: '#cr-01',
      triggerHook: 0.5,
      duration: '80%',
    }).setTween(
        TweenMax.fromTo('#cr-01 .text', 1,
            {
              css: {
                marginTop: '-50px', // the tween durtion can be omitted and defaults to 1
              },
            },
            {
              css: {
                marginTop: '50px', // the tween durtion can be omitted and defaults to 1
              },
            }),
    );
    // .addIndicators({ name: '2 (duration: 80%)' });

    const scene3 = this.$scrollmagic.scene({
      triggerElement: '#cr-03 .pwwrap',
      triggerHook: 0.5,
      duration: '30%',
    }).setTween(
        TweenMax.fromTo('#cr-03 .text', 1,
            {
              css: {
                marginTop: '-15px', // the tween durtion can be omitted and defaults to 1
              },
            },
            {
              css: {
                marginTop: '15px', // the tween durtion can be omitted and defaults to 1
              },
            }),
    );
    // .addIndicators({ name: '3 (duration: 80%)' });

    const scene33 = this.$scrollmagic.scene({
      triggerElement: '#cr-03 .pwwrap',
      triggerHook: 0.5,
      duration: '30%',
    }).setTween(
        TweenMax.fromTo('#cr-03 img', 1,
            {
              css: {
                marginTop: '15px', // the tween durtion can be omitted and defaults to 1
              },
            },
            {
              css: {
                marginTop: '-15px', // the tween durtion can be omitted and defaults to 1
              },
            }),
    );
    // .addIndicators({ name: '3 (duration: 80%)' });

    // const scene3 = this.$scrollmagic.scene({
    //   triggerElement: '#cr-02',
    //   triggerHook: 0.5,
    //   duration: '50%'
    // }).setTween(
    //     TweenMax.from('#cr-02', 1, { 
    //       css: { 
    //         marginTop: '-50px' // the tween durtion can be omitted and defaults to 1
    //       }
    //     })
    // )
    // .addIndicators({ name: '2 (duration: 300)' });

    const scene41 = this.$scrollmagic.scene({
      triggerElement: '.history .history-images',
      triggerHook: 0.5,
      duration: '2000',
    }).setTween(
        TweenMax.to('.history-image-01', 1, {
          scale: 1.5,
        }),
    );

    const scene42 = this.$scrollmagic.scene({
      triggerElement: '.history .history-images',
      triggerHook: 0.5,
      duration: '2000',
    }).setTween(
        TweenMax.to('.history-image-02', 1, {
          css: {
            x: '-10%', // the tween durtion can be omitted and defaults to 1
            y: '5%',
            scale: 1.2,
          },
        }),
    );
    // .addIndicators({ name: '2 (grass)' });

    const scene43 = this.$scrollmagic.scene({
      triggerElement: '.history .history-images',
      triggerHook: 0.5,
      duration: '1000',
    }).setTween(
        TweenMax.to('.history-image-03', 2, {
          css: {
            x: '-20%', // the tween durtion can be omitted and defaults to 1
            scale: 1.8,
          },
        }),
    );
    // .addIndicators({ name: '2 (grass)' });

    this.$scrollmagic.addScene([scene1, scene2, scene3, scene33, scene41, scene42, scene43]);
  },
  methods: {
    getData: function() {
      //   axios.get('https://urlhere').then((datasource) => {
      //     this.dataToken = datasource.data.nameOfFirstArray;
      //   });
      axios.get('https://trx-api.okie.my/api/general/chapter/all/section/all')
          .then(response => response.data)
          .then(datasource => {
            this.dataToken = datasource;
            // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
            for (let i = 0; i < datasource.length; i++) {
              // this.dataTokenParaInner.push( JSON.parse(datasource[i].paragraph_timeline.replace(/\\/g, '')) );
            }
            ;

          });
    },
    beforeUpdate() {
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
      }
    },
    updated() {
      this.$nextTick(function() {
        if (this.$refs.slick) {
          this.$refs.slick.create(this.slickOptions);
        }
      });
    },
    // myFunction: function () {
    //     this.dataSample.title = this.dataSample.title.replace(" ", "-");    
    // }
  },
  // created: function(){
  //   this.myFunctionOnLoad()
  // }
  // methods: {
  //   debug (event) {
  //     console.log(event.target.name)
  //   }
  // } 
};
</script>

<style lang="scss">
@import '@/assets/styles/home.scss';
</style>

