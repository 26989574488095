<template>
  <div class="page page-commercialisation">

    <div class="banner position-relative">
      <div class="banner-image position-relative d-block" data-aos="zoom-out">
        <img :src="styleBanner" alt="banner-commercialisation" class="img-fluid" width="100%">
      </div>
      <div class="banner-content position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
        <div class="pwwrap pwstyle_02">
          <div class="text text-center text-white">
            <!-- <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">Ideation</h1> -->
            <h1 class="page-title" data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">Commercialisation</h1>
            <img :src="styleUDFooter" alt="underline-footer" class="img-fluid" width="100%" data-aos="zoom-in" data-aos-easing="ease-out-back" data-aos-duration="800">
            <div class="body" data-aos="fade-up" data-aos-easing="ease" data-aos-offset="50" data-aos-delay="400" data-aos-duration="800">
              <p>Renowned companies from around the world have shown interest and commitment to take part in the development of TRX.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-next position-absolute text-center bottom-0 start-50 translate-middle-x" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
        <a class="banner-scroll">
          <p>Explore TRX</p>
          <!-- <img :src="styleBannerNext" alt="banner-next-arrow"> -->
          <div class="mouse"></div>
        </a>
      </div>
    </div>

    <!-- content -->
    <div class="ideation content-ideation">

      <div class="content-bg-list">
        <!-- <div class="cr-09 content-bg pfbgcolor_white"><div></div></div> -->
        <!-- <div class="cr-08 content-bg pfbgcolor_white"><div></div></div> -->
        <!-- <div class="cr-07 content-bg pfbgcolor_bluelight"><div></div></div> -->
        <div class="cr-06 content-bg pfbgcolor_white"><div></div></div>
        <div class="cr-05 content-bg pfbgcolor_white" :style="styleNewsPaperBG"><div></div></div>
        <div class="cr-04 content-bg pfbgcolor_teallight"><div></div></div>
        <div class="cr-03 content-bg pfbgcolor_white"><div></div></div>
        <div class="cr-02 content-bg" :style="styleIntro"></div>
      </div>

      <section class="content-row" id="cr-01" >
        <div class="intro" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p><span class="pfstyle_s64">T</span>TRX attracted global and regional players such as Lendlease, Mulia Group, HSBC, and Prudential. The first company to come onboard was Australian-based Lendlease, which signed a 60:40 Joint Venture partnership with TRX City for the Lifestyle Quarter.</p>
                  <p>The success behind TRX’s commercialisation is its phasing strategy; applying several business models by Joint Venture partnership opportunities, the sale of development rights, and allocating plots for local investors. With the development divided into stages, TRX has greater flexibility in planning future phases depending on market demand.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-02">
        <div class="map" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01" data-aos="fade-down">
              <div class="text">
                <h3 class="content-title pfcolor_teal text-center">Map of Stage 1 from Investment Memorandum </h3>
              </div>
            </div>
          </div>
          <div class="map-wrap">
            <div class="map-border-top"><img :src="mapBorderTop" class="d-block mx-auto img-fluid" alt="mapBorderTop"></div>
            <div class="map-content pfbgcolor_teal">
              <div class="row mx-0">
                <div class="col-xl-8 col-lg-7 col-md-6 py-0">
                  <div class="map-svg"><svgMap/></div>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-6 py-0">
                  <div class="map-text">
                    <table border="0">
                      <tr>
                        <th><h4>Stage</h4></th>
                        <th><h4>GFA ft²</h4></th>
                        <th><h4>GFA%</h4></th>
                      </tr>
                      <tr><td></td></tr>
                      <tr class="map-target" data-target="Stage01">
                        <td class="d-table"><div class="d-table-cell"><img class="me-3" :src="dotDashed01" alt="dotDashed01"></div>Stage 1</td>
                        <td>10,803,967</td>
                        <td>51%</td>
                      </tr>
                      <tr class="map-target" data-target="Stage02">
                        <td class="d-table"><div class="d-table-cell"><img class="me-3" :src="dotDashed02" alt="dotDashed02"></div>Future Residential</td>
                        <td>1,539,062</td>
                        <td>7%</td>
                      </tr>
                      <tr class="map-target" data-target="Stage03">
                        <td class="d-table"><div class="d-table-cell"><img class="me-3" :src="dotDashed03" alt="dotDashed03"></div>Future Commercial</td>
                        <td>4,308,124</td>
                        <td>20%</td>
                      </tr>
                      <tr class="map-target" data-target="Stage04">
                        <td class="d-table"><div class="d-table-cell"><img class="me-3" :src="dotDashed04" alt="dotDashed04"></div>Future Cultural</td>
                        <td>64,691</td>
                        <td>1%</td>
                      </tr>
                      <tr class="map-target" data-target="Stage05">
                        <td class="d-table"><div class="d-table-cell"><img class="me-3" :src="dotDashed05" alt="dotDashed05"></div>South Site</td>
                        <td>4,097,630</td>
                        <td>19%</td>
                      </tr>
                      <tr class="map-target" data-target="Stage06">
                        <td class="d-table"><div class="d-table-cell"><img class="me-3" :src="dotDashed06" alt="dotDashed06"></div>Municipal Management</td>
                        <td>158,229</td>
                        <td>1%</td>
                      </tr>
                      <tr><td></td></tr>
                    </table>
                  </div>
                  <div class="map-img">
                    <img :src="mapImg01" alt="mapImg01" class="d-block mx-auto img-fluid">
                  </div>
                </div>
              </div>
            </div>
            <div class="map-border-btm">
                <img :src="mapBorderBtm" class="d-block mx-auto img-fluid" alt="mapBorderBtm">
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-03">
        <div class="memo" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>In December 2013, the Investment Memorandum was launched, providing prequalified investors with the chance to take part in Stage 1 of the development. A global tender was run and this attracted local and international brands, bringing in the best capabilities and expertise. </p>
                </div>
              </div>
            </div>
            <div class="memo-content">
              <div class="memovideo position-relative">
                <div class="memo-bg position-relative d-block" data-aos="zoom-out" data-aos-easing="ease-out-back">
                  <!-- <video autoplay muted loop id="memoVideo"> -->
                    <!-- <source :src="styleBgVidBrandVid" type="video/mp4"> -->
                  <!-- </video> -->
                  <img :src="styleBgVidMemoOverlay" alt="banner-memo-overlay" class="img-fluid" width="100%">
                </div>
                <div class="brandvideo-text position-absolute d-flex align-items-center start-0 top-0 bottom-0 end-0">
                  <div class="pwwrap pwstyle_01">
                    <div class="text text-center text-white" data-aos="fade-down">
                      <h2 class="content-title pfcolor_white">Investment Memorandum</h2>
                      <a class="btn-modal" v-b-modal.memoVideoModal>
                        <img :src="styleIcoBrandVid" alt="icon-memo-video">
                        <p>Watch Video</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="memolink"><svgTimeline/></div>
            </div>
          </div>
          <slick ref="slick" :options="slickOptions" class="memo-border">
            <div class="map-border-item">
              <img :src="styleBorderMemo" alt="Border Memo">
            </div>
            <div class="map-border-item">
              <img :src="styleBorderMemo" alt="Border Memo">
            </div>
          </slick>
        </div>
      </section>

      <section class="content-row" id="cr-04">
        <div class="press" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>TRX was successful in attracting and keeping global brands against a challenging backdrop involving its then parent company, the 1MDB Group. TRX navigated through the turmoil thanks to its thorough tender process and best practices that helped safeguard the integrity of its procedures.</p>
                </div>
              </div>
            </div>
            <div class="newspaper text-center">
              <div class="newspaper-image d-inline-block" data-aos="zoom" data-aos-anchor-placement="center-center" data-aos-offset="-50">
                <img :src="styleNewsPaper" alt="comm-04-press-img-01" class="d-block mx-auto img-fluid">
              </div>
            </div>
            <div class="newspaper-link" data-aos="fade-left" data-aos-delay="1000">
              <a href="https://trx.my/news/life-goes-trx" class="pabutton_1 text-center" target="_blank">View News</a>
            </div>
          </div>
        </div>
      </section>

      <section class="content-row" id="cr-05">
        <div class="ending" data-aos="fade-down">
          <div class="container">
            <div class="pwwrap pwstyle_01">
              <div class="text">
                <div class="body">
                  <p>At the same time, the project ownership transferred from 1MDB RE, the real estate arm of 1MDB, to the Minister of Finance Incorporated.</p>
                  <p>The support of the Government accelerated the commercialisation process and helped the project stay on course. Since then, the 1MDB legacy became a thing of the past and under the Minister of Finance Incorporated, TRX City Sdn Bhd was established.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="content-modal">

      <b-modal id="memoVideoModal" centered hide-footer no-stacking size="xl">
        <template #modal-header="{ close }">
          <b-button size="default" class="btn-close" @click="close()"></b-button>
        </template>
        <template>
          <modalMemoVideo/>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal" id="milestonesCommercialisationModal" title-sr-only hide-header hide-footer no-stacking scrollable size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" @click="close()">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <modalCTL1/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner0Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner0/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner1Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner1/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner2Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner2/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner3Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner3/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner4Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner4/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner5Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner5/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner6Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner6/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner7Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner7/>
          </div>
        </template>
      </b-modal>

      <b-modal modal-class="milestones-modal milestonesinner-modal" id="milestonesInner8Modal" title-sr-only hide-header hide-footer no-stacking size="default">
        <template #default="{ hide, close }">
          <div class="modal-body pfbgcolor_green">
            <div class="milestones-close">
              <a class="btn-close" v-b-modal="'milestonesCommercialisationModal'">
                <img :src="MileClose" alt="mile-close-modal">
              </a>
            </div>
            <Inner8/>
          </div>
        </template>
      </b-modal>

    </div>

  </div>
</template>

<script>
import jQuery from 'jquery';
import $ from "jquery";
import axios from 'axios'
import AOS from 'aos'
// import HelloWorld from '@/components/HelloWorld.vue'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import slickLeft from '@/assets/arrow-prev-transparent.png'
import slickRight from '@/assets/arrow-next-transparent.png'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import SwiperCore, { EffectCards, Autoplay, Navigation } from "swiper";
SwiperCore.use([EffectCards, Autoplay, Navigation]);

// @ is an alias to /src

// import styleImage from '@/assets/osboot_sample_texture_500x500__01.jpg'
// import styleBgFooter from '@/assets/bg-footer.svg'
import styleUDFooter from '@/assets/underline-footer.png'
import styleQuoteOpen from '@/assets/quote-open-bluebright.svg'
import btnNewTab from '@/assets/ico-newtab.png'
import btnNewTabBlack from '@/assets/ico-newtab-black.png'

// import styleBanner from '@/assets/03-comm/comm-01-banner.jpg'
import styleBanner from '@/assets/03-comm/comm-01-banner-01.jpg'
import styleBannerNext from '@/assets/ideation/ideation-01-banner-arrow.png'
import styleBgIntro from '@/assets/03-comm/comm-02-bg-intro.jpg'

import MileClose from '@/assets/ico-commercialisation-milestones-modal-close.png'

import mapBorderTop from '@/assets/03-comm/comm-02-border-top-01.png'
import mapBorderBtm from '@/assets/03-comm/comm-02-border-btm-01.png'
import svgMap from '@/components/commercialisation/svg-map.vue'
import dotDashed01 from '@/assets/03-comm/comm-02-dotdashed-stage-01.png'
import dotDashed02 from '@/assets/03-comm/comm-02-dotdashed-stage-02.png'
import dotDashed03 from '@/assets/03-comm/comm-02-dotdashed-stage-03.png'
import dotDashed04 from '@/assets/03-comm/comm-02-dotdashed-stage-04.png'
import dotDashed05 from '@/assets/03-comm/comm-02-dotdashed-stage-05.png'
import dotDashed06 from '@/assets/03-comm/comm-02-dotdashed-stage-06.png'
import mapImg01 from '@/assets/03-comm/comm-02-img-01.png'

import styleIcoBrandVid from '@/assets/video-play-ico-transparent.png'
import styleBgVidMemoOverlay from '@/assets/03-comm/comm-03-milestone-img-01.jpg'
import modalMemoVideo from '@/components/commercialisation/memo-video.vue'
import styleBorderMemo from '@/assets/03-comm/comm-03-milestone-border-bg-01.png'
import svgTimeline from '@/components/commercialisation/svg-timeline.vue'
import modalCTL1 from '@/components/commercialisation/timeline-commercialisation.vue'

import Inner0 from '@/components/commercialisation/timeline-2013.vue'
import Inner1 from '@/components/commercialisation/timeline-2014-01.vue'
import Inner2 from '@/components/commercialisation/timeline-2014-02.vue'
import Inner3 from '@/components/commercialisation/timeline-2015-01.vue'
import Inner4 from '@/components/commercialisation/timeline-2015-02.vue'
import Inner5 from '@/components/commercialisation/timeline-2015-03.vue'
import Inner6 from '@/components/commercialisation/timeline-2015-04.vue'
import Inner7 from '@/components/commercialisation/timeline-2017-01.vue'
import Inner8 from '@/components/commercialisation/timeline-2017-02.vue'

import styleNewsPaper from '@/assets/03-comm/comm-04-press-img-01.jpg'
import NewsPaperBG from '@/assets/03-comm/comm-04-press-bg-01.jpg'

import styleBgFooter from '@/assets/03-comm/bg-footer-comm.jpg'

export default {
  name: 'Commercialisation',
  components: {
    Slick,
    svgMap,
    svgTimeline,
    modalCTL1,
    modalMemoVideo,
    Inner0,
    Inner1,
    Inner2,
    Inner3,
    Inner4,
    Inner5,
    Inner6,
    Inner7,
    Inner8
  },
  metaInfo() {
    return {
      title: 'Commercialisation',
    }
  },
  setup() {
    return {
      EffectCards,
    };
  },
  data: function () {
    return {
      apiBasePath: 'https://trx-api.okie.my/',
      dataToken: [],
      dataTokenParaInner: [],
      dataTokenArea: [],
      styleBgFooter: styleBgFooter,
      styleUDFooter: styleUDFooter,
      styleQuoteOpen: styleQuoteOpen,
      btnNewTab: btnNewTab,
      btnNewTabBlack: btnNewTabBlack,
      styleBanner: styleBanner,
      styleBannerNext: styleBannerNext,
      styleBgIntro: styleBgIntro,
      MileClose: MileClose,
      mapBorderTop: mapBorderTop,
      mapBorderBtm: mapBorderBtm,
      dotDashed01: dotDashed01,
      dotDashed02: dotDashed02,
      dotDashed03: dotDashed03,
      dotDashed04: dotDashed04,
      dotDashed05: dotDashed05,
      dotDashed06: dotDashed06,
      mapImg01: mapImg01,
      styleIcoBrandVid: styleIcoBrandVid,
      styleBgVidMemoOverlay: styleBgVidMemoOverlay,
      styleBorderMemo: styleBorderMemo,
      styleNewsPaper: styleNewsPaper,
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        // nextArrow: '<button type="button" class="slick-next slick-arrow"><img src="' + slickRight + '"></button>',
        // prevArrow: '<button type="button" class="slick-prev slick-arrow"><img src="' + slickLeft + '"></button>',
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        variableWidth: true,
        speed: 20000,
        cssEase:'linear',
        infinite: true,
        slidesToScroll: 0.1,
        draggable: false,
        // responsive: [
          // {
            // breakpoint: 1200,
            // settings: {
              // slidesToShow: 3,
            // }
          // },
          // {
            // breakpoint: 992,
            // settings: {
              // slidesToShow: 2,
            // }
          // },
          // {
            // breakpoint: 575,
            // settings: {
              // slidesToShow: 1,
            // }
          // },
        // ]
      },
    };
  },
  computed: {
    styleIntro() {
      return {
        'background-image': 'url(' + styleBgIntro + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover'
      }
    },
    styleNewsPaperBG() {
      return {
        'background-image': 'url(' + NewsPaperBG + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        'background-position': 'center',
      }
    },
  },
  mounted: function () {
    this.getData();
    $('.map-text .map-target').hover(function(){
      var hoverTarget = $(this).data('target');
      $('#'+hoverTarget).toggleClass('active');
      $('.stage-wrap:not(#'+hoverTarget+')').toggleClass('hovered');
      $('.map-text .map-target').toggleClass('hovered');
    });
    this.$root.$on('bv::modal::shown', function() {
      $('html').css('overflow', 'hidden');
      // AOS.refresh();
    }).$on('bv::modal::hidden', function() {
      // AOS.init({disable:false});
      $('html').css('overflow', 'auto');
    });
  },
  methods: {
    getData: function() {
      axios.get('https://trx-api.okie.my/api/general/chapter/2/section/all')
          .then(response => response.data)
          .then(datasource => {
            this.dataToken = datasource;
            // this.dataTokenPara = JSON.parse( datasource[0].paragraph_timeline.replace(/\\/g, '') );
            for (let i = 0; i < datasource.length; i++) {
              const content = datasource[i].paragraph_timeline.replace(/\\/g, '');
              if (content && content !== '') {
                this.dataTokenParaInner.push(JSON.parse(content));
              }
            }
          });
      axios.get('https://trx-api.okie.my/api/general/chapter/2/section/8')
          .then(response => response.data)
          .then(datasource => {
            this.dataTokenArea = datasource;
          });
    },
  },
}

</script>

<style lang="scss">
@import '@/assets/styles/commercialisation.scss';
</style>